import { Button, Form, FormControl, FormGroup, FormLabel } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { emptyUsersGroup } from "../interfaces/UsersGroup";
import UserSelector from "../elements/UserSelector";
import { useState } from "react";
import { User } from "../interfaces/User";

export interface UsersGroupAddProps {
    submitCallback: CallableFunction;
    closeCallback: CallableFunction;
}

export default function UsersGroupAdd(props: UsersGroupAddProps) {
    const navigate = useNavigate();

    const userId = localStorage.getItem('userId');

    const [usersSelected, setUsersSelected] = useState<any[]>([]);

    const handleClose = () => {
        props.closeCallback();
    }

    const handleSubmit = (event: any) => {
        event.preventDefault();
        let error = false;
        let error_messages: string[] = [];
        
        if (error) {
            alert(error_messages.join('\n'));
        } else {
            const newUsersGroup = Object.assign({});
            newUsersGroup.name = event.target.name.value;
            newUsersGroup.description = event.target.description.value;
            newUsersGroup.userId = userId;
            newUsersGroup.users = usersSelected.map((u: any) => { 
                return {
                    _id: u.id,
                    email: u.email,
                    firstname: u.firstname,
                    lastname: u.lastname
                }; 
            });
            const requestOptionsPost = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + localStorage.getItem('token')
                },
                body: JSON.stringify(newUsersGroup)
            };
            fetch('https://srv.taskgen.eu/v1/users-groups', requestOptionsPost)
                .then(response => {
                    if (response.status !== 200) {
                        navigate('/');
                    }
                    return response.json();
                })
                .then(data => {
                    props.submitCallback(data);                    
                });
        }
    }

    return(
        <div className="overlay">
            <div className="inner">
                <div className="overlay-content">
                    <a className="close" onClick={ handleClose }></a>
                    <div className="usersgroup-add-org-container">
                        <h4>{ 'Add new group' }</h4>
                        <div className="usersgroup-form-container">
                            <Form onSubmit={ handleSubmit }>
                                <FormGroup className="name" controlId="name">
                                    <Form.Label aria-required={ true }>{ 'Name' }</Form.Label>
                                    <Form.Control type="name" required={ true } name="name"
                                        placeholder={ 'Enter a name' } />
                                </FormGroup>
                                <Form.Group className="field field-description">
                                    <FormLabel htmlFor="description">{ 'Description' }</FormLabel>
                                    <FormControl as="textarea" name="description" />
                                </Form.Group>
                                <h5>{ 'Users' }</h5>
                                <p>
                                    { 'You can add users of your organization or any user in taskGen.' }
                                </p>
                                <UserSelector users={ [] } usersCallback={ setUsersSelected } />
                                <div className="actions">
                                    <Button type="submit" variant="primary">{ 'Save' }</Button>
                                </div>
                            </Form>
                        </div>
                    </div>
                </div>  
            </div>
        </div>
    );
}