import { Button, Form, FormGroup } from "react-bootstrap";
import { Help, HelpItem, emptyHelpItem } from "../../interfaces/Help";
import { useState } from "react";
import CustomEditor from "../../elements/CustomEditor";
import { useNavigate } from "react-router-dom";

export interface HelpEditProps {
    help: Help;
    submitCallback: CallableFunction;
    closeCallback: CallableFunction;
}

export default function HelpEdit(props: HelpEditProps) {
    const navigate = useNavigate();
    const [helpItems, setHelpItems] = useState<HelpItem[]>(props.help.items);
    const [showAddHelpItemForm, setShowAddHelpItemForm] = useState(false);
    const [showEditHelpItemForm, setShowEditHelpItemForm] = useState(false);
    const [showDeleteHelpItemForm, setShowDeleteHelpItemForm] = useState(false);
    const [helpItem, setHelpItem] = useState<HelpItem>(emptyHelpItem);
    const [helpItemIndex, setHelpItemIndex] = useState(-1);
    const [teaser, setTeaser] = useState('');
    const [body, setBody] = useState('');
    const [examples, setExamples] = useState('');
    const [references, setReferences] = useState('');


    const handleClose = () => {
        props.closeCallback();
    }

    const handleSubmit = (event: any) => {

        event.preventDefault();
        let error = false;
        let error_messages: string[] = [];
        if (error) {
            alert(error_messages.join('\n'));
        } else {
            const newHelp = Object.assign({}, props.help);
            newHelp.name = event.target.name.value;
            newHelp.items = helpItems.map((hi: HelpItem) => { return hi; });
            newHelp.weight = event.target.weight.value;
            const requestOptionsPost = {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + localStorage.getItem('token')
                },
                body: JSON.stringify(newHelp)
            };
            fetch('https://srv.taskgen.eu/v1/help/' + props.help._id, requestOptionsPost)
                .then(response => {
                    if (response.status !== 200) {
                        navigate('/');
                    }
                    return response.json();
                })
                .then(data => {
                    props.submitCallback(newHelp);                    
                });
        }
    }

    const handleShowEditHelpItemForm = (event, index: number) => {
        event.preventDefault();
        setHelpItemIndex(index);
        const newHelItem = Object.assign({}, helpItems[index]);
        setTeaser(newHelItem.teaser);
        setBody(newHelItem.body);
        setExamples(newHelItem.examples);
        setExamples(newHelItem.references);
        setHelpItem(newHelItem);
        setShowEditHelpItemForm(true);
    }

    const handleShowDeleteHelpItemForm = (event, index: number) => {
        event.preventDefault();
        setHelpItemIndex(index);
        const newHelItem = Object.assign({}, helpItems[index]);
        setHelpItem(newHelItem);
        setShowDeleteHelpItemForm(true);
    }

    const handleShowAddHelpItemForm = () => {
        const newHelItem = Object.assign({}, emptyHelpItem);
        setHelpItem(newHelItem);
        setShowAddHelpItemForm(true);
    }

    const handleHideAddHelpItemForm = () => {
        setShowAddHelpItemForm(false);
    }

    const handleAddHelpItemSubmit = (event: any) => {
        event.preventDefault();
        const newHelpItem = Object.assign({}, emptyHelpItem);
        // setName(event.target.name.value);
        newHelpItem.title = event.target.title.value;
        newHelpItem.teaser = teaser;
        newHelpItem.body = body;
        newHelpItem.examples = examples;
        newHelpItem.references = references;
        const newHelpItems = helpItems.map((hi: HelpItem) => { return(hi); });
        newHelpItems.push(newHelpItem);
        setHelpItems(newHelpItems);
        setShowAddHelpItemForm(false);
    }

    const handleTeaserChange = (data: string) => {
        setTeaser(data);
    }

    const handleBodyChange = (data: string) => {
        setBody(data);
    }

    const handleExamplesChange = (data: string) => {
        setExamples(data);
    }

    const handleReferencesChange = (data: string) => {
        setReferences(data);
    }

    const handleEditHelpItemSubmit = (event: any) => {
        event.preventDefault();
        const newHelpItem = Object.assign({}, helpItem);
        // setName(event.target.name.value);
        newHelpItem.title = event.target.title.value;
        newHelpItem.teaser = teaser;
        newHelpItem.body = body;
        newHelpItem.examples = examples;
        newHelpItem.references = references;
        const newHelpItems = helpItems.map((hi: HelpItem, hiIndex: number) => {
            if (hiIndex == helpItemIndex) {
                return newHelpItem;
            } else {
                return(hi); 
            }
        });
        setHelpItems(newHelpItems);
        setShowEditHelpItemForm(false);
    }

    const handleHideEditHelpItemForm = () => {
        setShowEditHelpItemForm(false);
    }

    const handleDeleteHelpItemSubmit = (event: any) => {
        event.preventDefault();
        const newHelpItems = helpItems.filter((hi: HelpItem, index: number) => index != helpItemIndex);
        setHelpItems(newHelpItems);
        setShowDeleteHelpItemForm(false);
    }

    const handleHideDeleteHelpItemForm = () => {
        setShowDeleteHelpItemForm(false);
    }

    const handleTextAnalyzed = () => {

    }

    const doNothing = () => {
        
    }

    return(
        <div className="overlay">
            <div className="inner">
                <div className="overlay-content">
                    <a className="close" onClick={ handleClose }></a>
                    <div className="admin-edit-help-container">
                    <h4>{ 'Edit help' }</h4>
                        <div className={ 'help-item-form-container' + ((showAddHelpItemForm || showEditHelpItemForm || showDeleteHelpItemForm) ? ' hide' : '') }>
                            <Form onSubmit={ handleSubmit }>
                                <FormGroup className="name" controlId="name">
                                    <Form.Label aria-required={ true }>{ 'Name' }</Form.Label>
                                    <Form.Control type="name" required={ true } defaultValue={ props.help.name }
                                        placeholder={ 'Enter a descriptive name' } />
                                </FormGroup>
                                <div className="help-items">
                                    <strong>{ 'Help items' }</strong>
                                    { helpItems.length == 0 && !showAddHelpItemForm && !showEditHelpItemForm && !showDeleteHelpItemForm &&
                                        <div className="no-items-message">
                                            { 'There are no help items defined. Add your first help item.'}
                                        </div>
                                    }
                                    { helpItems.length > 0 && !showAddHelpItemForm && !showEditHelpItemForm && !showDeleteHelpItemForm &&
                                        <div className="help-items-list">
                                            { helpItems.map((hi: HelpItem, index: number) => {
                                                return(
                                                    <div className="help-item-elem">
                                                        <div className="title">
                                                            { hi.title ? hi.title : 'Help item ' + index }
                                                        </div>
                                                        <div className="help-item-actions">
                                                            <a className="edit" title={ 'Edit user' }
                                                                href="#" onClick={ (event) => handleShowEditHelpItemForm(event, index) }></a>
                                                            <a className="delete" title={ 'Delete user' }
                                                                href="#" onClick={ (event) => handleShowDeleteHelpItemForm(event, index) }></a>
                                                        </div>
                                                    </div> 
                                                );
                                            })}
                                        </div>
                                    }
                                    <FormGroup className="weight" controlId="weight">
                                        <Form.Label>{ 'Weight' }</Form.Label>
                                        <Form.Control type="number" defaultValue={ props.help.weight } />
                                        <Form.Text id="passwordHelpBlock" muted>
                                            { 'Enter the position of this help content relative to other help contents. The higher the value the lower it will be sorted.'}
                                        </Form.Text>
                                    </FormGroup>
                                    <div className="help-item-actions">
                                        <Button variant="secondary" onClick={ handleShowAddHelpItemForm }>
                                            { 'Add help item' }
                                        </Button>
                                    </div>
                                </div>
                                <div className="actions">
                                    <Button type="submit" variant="primary">{ 'Save' }</Button>
                                </div>
                            </Form>
                        </div>
                        { showAddHelpItemForm &&
                            <Form onSubmit={ handleAddHelpItemSubmit } className="add-help-item">
                                <h5>{ 'Add Help Item' }</h5>
                                <FormGroup className="title" controlId="title">
                                    <Form.Label>{ 'Title' }</Form.Label>
                                    <Form.Control type="text" placeholder={ 'Enter the title' } />
                                </FormGroup>
                                <div className="field field-teaser">
                                    <CustomEditor
                                        change={ handleTeaserChange }
                                        changeFormatted={ doNothing }
                                        data={ helpItem.teaser }
                                        formatted={ '' }
                                        id="teaser"
                                        label={ 'Teaser'}
                                        npl={ false }
                                        task_id={ '' }
                                    />
                                </div>
                                <div className="field field-body">
                                    <CustomEditor
                                        change={ handleBodyChange }
                                        changeFormatted={ doNothing }
                                        data={ helpItem.body }
                                        formatted={ '' }
                                        id="body"
                                        label={ 'Body'}
                                        npl={ false }
                                        task_id={ '' }
                                    />
                                </div>
                                <div className="field field-examples">
                                    <CustomEditor
                                        change={ handleExamplesChange }
                                        changeFormatted={ doNothing }
                                        data={ helpItem.examples }
                                        formatted={ '' }
                                        id="examples"
                                        label={ 'Examples'}
                                        npl={ false }
                                        task_id={ '' }
                                    />
                                </div>
                                <div className="field field-references">
                                    <CustomEditor
                                        change={ handleReferencesChange }
                                        changeFormatted={ doNothing }
                                        data={ helpItem.references }
                                        formatted={ '' }
                                        id="references"
                                        label={ 'References'}
                                        npl={ false }
                                        task_id={ '' }
                                    />
                                </div>
                                <div className="actions">
                                    <Button type="submit" variant="primary">{ 'Save' }</Button>
                                    <Button onClick={ handleHideAddHelpItemForm } variant="warning">{ 'Cancel' }</Button>
                                </div>
                            </Form>
                        }
                        { showEditHelpItemForm &&
                            <Form onSubmit={ handleEditHelpItemSubmit } className="edit-help-item">
                                <h5>{ 'Edit Help Item' }</h5>
                                <FormGroup className="title" controlId="title">
                                    <Form.Label>{ 'Title' }</Form.Label>
                                    <Form.Control type="text" defaultValue={ helpItem.title }
                                        placeholder={ 'Enter the title' } />
                                </FormGroup>
                                <div className="field field-teaser">
                                    <CustomEditor
                                        change={ handleTeaserChange }
                                        changeFormatted={ doNothing }
                                        data={ helpItem.teaser }
                                        formatted={ '' }
                                        id="teaser"
                                        label={ 'Teaser'}
                                        npl={ false }
                                        task_id={ '' }
                                    />
                                </div>
                                <div className="field field-body">
                                    <CustomEditor
                                        change={ handleBodyChange }
                                        changeFormatted={ doNothing }
                                        data={ helpItem.body }
                                        formatted={ '' }
                                        id="body"
                                        label={ 'Body'}
                                        npl={ false }
                                        task_id={ '' }
                                    />
                                </div>
                                <div className="field field-examples">
                                    <CustomEditor
                                        change={ handleExamplesChange }
                                        changeFormatted={ doNothing }
                                        data={ helpItem.examples }
                                        formatted={ '' }
                                        id="examples"
                                        label={ 'Examples'}
                                        npl={ false }
                                        task_id={ '' }
                                    />
                                </div>
                                <div className="field field-references">
                                    <CustomEditor
                                        change={ handleReferencesChange }
                                        changeFormatted={ doNothing }
                                        data={ helpItem.references }
                                        formatted={ '' }
                                        id="references"
                                        label={ 'References'}
                                        npl={ false }
                                        task_id={ '' }
                                    />
                                </div>
                                <div className="actions">
                                    <Button type="submit" variant="primary">{ 'Modify' }</Button>
                                    <Button onClick={ handleHideEditHelpItemForm } variant="warning">{ 'Cancel' }</Button>
                                </div>
                            </Form>
                        }
                        { showDeleteHelpItemForm &&
                            <Form onSubmit={ handleDeleteHelpItemSubmit } className="delete-help-item">
                                <h5>{ 'Delete help item' }</h5>
                                <FormGroup className="confirmation-message" controlId="confirmation">
                                    <Form.Text id="confirmationMessage">
                                        Are you sure you want to delete Help Item named <strong>{ helpItem.title }</strong>? This action cannot be undone.'
                                    </Form.Text>
                                </FormGroup>
                                <div className="actions">
                                    <Button type="submit" variant="primary">{ 'Delete' }</Button>
                                    <Button onClick={ handleHideDeleteHelpItemForm } variant="warning">{ 'Cancel' }</Button>
                                </div>
                            </Form>
                        }
                    </div>
                </div>  
            </div>
        </div>
    );
}