import { useState, useEffect } from "react";
import parse from 'html-react-parser';
import { Help, HelpItem } from "../interfaces/Help";
import { registerEventEnd, registerEventStart, replaceVideoShortcodes } from "../../utils/utils";
import { useNavigate } from "react-router-dom";
import { Video } from "../interfaces/Video";

export interface HelpProps {
    id: string;
}

export default function HelpContent(props: HelpProps) {
    const navigate = useNavigate();
    const [eventId, setEventId] = useState('');
    const [help, setHelp] = useState<Help>();
    const [show, setShow] = useState(false);
    const [showBody, setShowBody] = useState<boolean[]>([]);
    const [videos, setVideos] = useState<Video[]>();

    useEffect(() => {
        const requestOptionsGet = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            }
        };
        fetch('https://srv.taskgen.eu/v1/help/' + props.id, requestOptionsGet)
            .then(response => {
                if (response.status !== 200) {
                    navigate('/');
                }
                return response.json();
            })
            .then(data => {
                setHelp(data);
                let newShowBody = data.items.map((i, index) => {
                    return(false);
                });
                setShowBody(newShowBody);
            });
        fetch('https://srv.taskgen.eu/v1/video-tutorials', requestOptionsGet)
            .then(response => {
                if (response.status !== 200) {
                    navigate('/');
                }
                return response.json();
            })
            .then(data => {
                setVideos(data);
            });
    }, []);

    const handleShow = (event: any) => {
        event.preventDefault();
        setShow(true);

        registerEventStart('help tooltip', props.id).then(data => { 
            setEventId(data);
        });
    }

    const handleHide = (event: any) => {
        event.preventDefault();
        registerEventEnd(eventId);
        setShow(false);
    }

    const handleShowBody = (i: number) => (event: any) => {
        event.preventDefault();             
        let newShowBody = showBody.map((e, index) => {
            if (index == i) {
                return !e;
            } else {
                return e;
            }
        });
        setShowBody(newShowBody);
    }

    return (
        <div className="help-container">
            <button className="toggle" onClick={ handleShow }></button>
            <div className={ 'help-box' + (show ? ' show' : '') }>
                <button className="close" onClick={ handleHide }></button>
                <div className="inner">
                    { help && 
                        help.items.map((i: HelpItem, index) => {
                            return(
                                <div className="help-item" 
                                    key={ 'help-item-' + props.id + '-' + index}>
                                    { i.title && <h4>{ i.title }</h4>}
                                    { i.teaser &&
                                        <div className="teaser">
                                            { videos && parse(replaceVideoShortcodes(i.teaser, videos)) }
                                        </div>
                                    }
                                    { !showBody[index] && (i.body || i.examples || i.references) &&
                                        <div className="more-details">
                                            <a onClick={ handleShowBody(index) }>{ 'read more...' }</a>
                                        </div>
                                    }
                                    { showBody[index] && i.body &&
                                        <div className={"body" + (showBody[index] ? ' show-body' : '')}>
                                            { videos && parse(replaceVideoShortcodes(i.body, videos)) }
                                        </div>
                                    }
                                    { showBody[index] && i.examples &&
                                        <div className="examples">
                                            { videos && parse(replaceVideoShortcodes(i.examples, videos)) }
                                        </div>
                                    }
                                    { showBody[index] && i.references &&
                                        <div className="references">
                                            { videos && parse(replaceVideoShortcodes(i.references, videos)) }
                                        </div>
                                    }
                                </div>
                            )
                        })
                    }
                </div>
            </div>
        </div>
    );
}