import { Button, Form, Tab, Tabs } from "react-bootstrap";
import { Task } from "../interfaces/Task";
import { useEffect, useState } from "react";
import { User, emptyUser } from "../interfaces/User";
import parse from 'html-react-parser';
import { useNavigate } from "react-router-dom";
import { obscureEmail } from "../../utils/utils";
import { UsersGroup } from "../interfaces/UsersGroup";

interface ShareTaskProps {
    task: Task,
    shareCallback: CallableFunction;
    closeCallback: CallableFunction;
}


export default function ShareTask(props: ShareTaskProps) {
    const navigate = useNavigate();

    const userId = localStorage.getItem('userId');

    const [user, setUser] = useState<User>(emptyUser);
    const [orgShare, setOrgShare] = useState(false);
    const [usersFound, setUsersFound] = useState<any[]>([]);
    const [usersSelected, setUsersSelected] = useState<any[]>([]);
    const [tabKey, setTabKey] = useState('concrete-users');
    const [groupsFound, setGroupsFound] = useState<any[]>([]);
    const [groupsSelected, setGroupsSelected] = useState<any[]>([]);

    const handleShare = () => {
        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            },
            body: JSON.stringify({
                task: props.task._id,
                users: usersSelected
            })
        };
        fetch('https://srv.taskgen.eu/v1/task-shares', requestOptions)
            .then(response => {
                const requestOptionsGroups = {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + localStorage.getItem('token')
                    },
                    body: JSON.stringify({
                        task: props.task._id,
                        groups: groupsSelected
                    })
                };
                fetch('https://srv.taskgen.eu/v1/task-shares/groups', requestOptionsGroups)
                    .then(response => {
                        props.shareCallback();
                    })
                    .catch(error => {
                        navigate('/');
                    });
            })
            .catch(error => {
                navigate('/');
            });
    }

    const handleClose = () => {
        props.closeCallback();
    }

    const handleShareWithOrgChange = (event: any) => {
        setOrgShare(event.target.checked);
        setUsersFound([]);
    }

    const handleUsersSearch = (event: any) => {
        if (event.target.value.length < 3) {
            setUsersFound([]);
        } else {
            let reqUrl = 'https://srv.taskgen.eu/v1/users/';
            let queryParams: string[] = [];
            if (orgShare) {
               queryParams.push('org=' + user.org._id);
            }
            queryParams.push('search=' + event.target.value);
            const requestOptionsPost = {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + localStorage.getItem('token')
                }
            };
            reqUrl += '?' + queryParams.join('&');
            fetch(reqUrl, requestOptionsPost)
                .then(response => {
                    if (response.status !== 200) {
                        navigate('/');
                    }
                    return response.json();
                })
                .then(data => {
                    const newUsersFound = data.map((u: any) => {
                        let nameParts: string[] = [];
                        if (u.profile !== undefined) {
                            if (u.profile.firstname) {
                                nameParts.push(u.profile.firstname);
                            }
                            if (u.profile.lastname) {
                                nameParts.push(u.profile.lastname);
                            }
                        }
                        return {
                            id: u._id,
                            label: obscureEmail(u.email) + (nameParts.length > 0 ? ' (' + nameParts.join(' ') +  ')' : '')
                        }
                    });
                    setUsersFound(newUsersFound);
                });
        }
    }

    const handleSelectUser = (event: any) => {
        const id = event.target.id.replace('userFound-', '');
        const selectedUser = usersFound.find((u: any) => u.id == id);
        const newUsersSelected = [
            ...usersSelected,
            selectedUser
        ];
        setUsersSelected(newUsersSelected);
    }

    const handleUnselectUser = (event: any) => {
        const id = event.target.id.replace('userShared-', '');
        const newUsersSelected = usersSelected.filter((u: any) => u.id != id);
        setUsersSelected(newUsersSelected);
    }

    const handleSelectGroup = (event: any) => {
        const id = event.target.id.replace('groupFound-', '');
        const selectedGroup = groupsFound.find((g: any) => g._id == id);
        const newGroupsSelected = [
            ...groupsSelected,
            Object.assign({}, {
                id: selectedGroup._id,
                label: selectedGroup.name
            })
        ];
        setGroupsSelected(newGroupsSelected);
    }

    const handleUnselectGroup = (event: any) => {
        const id = event.target.id.replace('groupShared-', '');
        const newGroupsSelected = groupsSelected.filter((u: any) => u.id != id);
        setGroupsSelected(newGroupsSelected);
    }

    useEffect(() => {
        const userId = localStorage.getItem('userId') ?? null;

        const requestOptions = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            }
        };
        fetch('https://srv.taskgen.eu/v1/user/' + userId, requestOptions)
            .then(response => {
                if (response.status !== 200) {
                    navigate('/');
                }
                return response.json();
            })
            .then(data => {
                setUser(data);
            });

        fetch('https://srv.taskgen.eu/v1/task-shares/task/' + props.task._id, requestOptions)
            .then(response => {
                if (response.status !== 200) {
                    navigate('/');
                }
                return response.json();
            })
            .then(data => {
                const newUsersSelected = data.map((u: any) => {
                    let nameParts: string[] = [];
                    if (u.user.profile !== undefined) {
                        if (u.user.profile.firstname) {
                            nameParts.push(u.user.profile.firstname);
                        }
                        if (u.user.profile.lastname) {
                            nameParts.push(u.user.profile.lastname);
                        }
                    }
                    return {
                        id: u.user._id,
                        label: obscureEmail(u.user.email) + (nameParts.length > 0 ? ' (' + nameParts.join(' ') +  ')' : '')
                    }
                });
                setUsersSelected(newUsersSelected);
            });
        fetch('https://srv.taskgen.eu/v1/users-groups/' + userId, requestOptions)
            .then(response => {
                if (response.status !== 200) {
                    navigate('/');
                }
                return response.json();
            })
            .then(data => {
                setGroupsFound(data);
            });
        fetch('https://srv.taskgen.eu/v1/task-shares/task/groups/' + props.task._id, requestOptions)
            .then(response => {
                if (response.status !== 200) {
                    navigate('/');
                }
                return response.json();
            })
            .then(data => {
                setGroupsSelected(data.map((ug:any) => {
                    return Object.assign({}, {
                        id: ug.group._id,
                        label: ug.group.name
                    });
                }));
            });
    }, []);

    return (
        <div className="overlay">
            <div className="inner">
                <div className="overlay-content">
                    <div className="share-task-container">
                        <div className="inner">
                            <h2>{ 'Share task' }</h2>
                            <p>
                                You can share the task <strong>{ props.task.name }</strong> with other people.
                            </p>
                            <Tabs id="share-tabs" defaultActiveKey={'concrete-users'} activeKey={ tabKey } onSelect={(k) => setTabKey(k ?? 'concrete-users')}>
                                <Tab eventKey="concrete-users" title={ 'Share with users' }>
                                    { user.org != null &&
                                        <Form.Check
                                            type="switch"
                                            id="share-with-org"
                                            label={ 'Shared with someone at ' + user.org.name }
                                            onChange={ handleShareWithOrgChange }
                                            defaultChecked = { false }
                                        />
                                    }
                                    <div className="share-options-container">
                                        <div className="search">
                                            <Form.Control type="text" placeholder="Search users..." onChange={ handleUsersSearch } />
                                        </div>
                                        { usersFound.length > 0 && 
                                            <div className="users">
                                                <div className="found">
                                                    <div className="label">
                                                    { parse('Select the users with whom you want to <strong>share the task</strong>:') }
                                                    </div>
                                                    <ul>
                                                        { usersFound.filter((f: any) => usersSelected.findIndex((s: any) => s.id == f.id) === -1).map((u: any) => {
                                                            return <li key={ `userFound-${u.id}` }>
                                                                <span>
                                                                    { u.label } <a className="select-user" onClick={ handleSelectUser } id={ `userFound-${u.id}`}></a>
                                                                </span>
                                                            </li>;
                                                        })}
                                                    </ul>
                                                </div>
                                            </div>
                                        }
                                        { usersSelected.length > 0 &&
                                            <div className="shared">
                                                <div className="label">
                                                    { parse('Task is shared with the following users:') }
                                                </div>
                                                <ul>
                                                    { usersSelected.map((u: any) => {
                                                        return <li key={ `userShared-${u.id}` }>
                                                            <span>
                                                                { u.label } <a className="unselect-user" onClick={ handleUnselectUser } id={ `userShared-${u.id}`}></a>
                                                            </span>
                                                        </li>; 
                                                    })}
                                                </ul>
                                            </div>
                                        }
                                    </div>
                                </Tab>
                                <Tab eventKey="group-users" title={ 'Share with groups' }>
                                    <div className="share-options-container">
                                        { groupsFound.length > 0 && 
                                            <div className="groups">
                                                <div className="found">
                                                    <div className="label">
                                                        { parse('Select the groups you want to <strong>share the task</strong>:') }
                                                    </div>
                                                    <ul>
                                                        { groupsFound.filter((f: UsersGroup) => groupsSelected.findIndex((s: any) => s.id == f._id) === -1).map((ug: any) => {
                                                            return <li key={ `groupFound-${ug._id}` }>
                                                                <span>
                                                                    { ug.name } <a className="select-group" onClick={ handleSelectGroup } id={ `groupFound-${ug._id}`}></a>
                                                                </span>
                                                            </li>;
                                                        })}
                                                    </ul>
                                                </div>
                                            </div>
                                        }
                                        { groupsSelected.length > 0 &&
                                            <div className="shared">
                                                <div className="label">
                                                    { parse('Task is shared with the following groups:') }
                                                </div>
                                                <ul>
                                                    { groupsSelected.map((ug: any) => {
                                                        return <li key={ `userGroup-${ug.id}` }>
                                                            <span>
                                                                { ug.label } <a className="unselect-group" onClick={ handleUnselectGroup } id={ `groupShared-${ug.id}`}></a>
                                                            </span>
                                                        </li>; 
                                                    })}
                                                </ul>
                                            </div>
                                        }
                                    </div>
                                </Tab>
                            </Tabs>
                            <div className="actions">
                                <Button className="btn btn-primary" onClick={ handleShare }>{ 'Share' }</Button>
                                <Button className="btn btn-warning" onClick={ handleClose }>{ 'Cancel' }</Button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}