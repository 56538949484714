import PrimaryHeader from "../header/PrimaryHeader";
import SecondaryHeader from "../header/SecondaryHeader";
import Beginnings from "../blocks/Beginnings";
import IntroMessage from "../blocks/IntroMessage";
import MyRecentTasks from "./MyRecentTasks";
import FaqsBlock from "../blocks/FaqsBlock";
import FeedbackBlock from "../blocks/FeedbackBlock";

interface AppItemProps {
    name: string;
}

export default function Dashboard(props: AppItemProps) {
    return (
        <div className="page">       
            <SecondaryHeader />
            <div className="page-content">
                <PrimaryHeader />
                <div className="page-content-inner">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-xs-12 col-lg-8">
                                <IntroMessage />
                                <MyRecentTasks />
                            </div>
                            <div className="col-xs-12 col-lg-4">
                                <Beginnings />
                                <FaqsBlock />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <FeedbackBlock/>
        </div>);
}