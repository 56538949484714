import { Media } from "./Media"

export const emptyVideo = {
    _id: '',
    title: '',
    description: '',
    media: []
}

export interface Video {
    _id: string,
    title: string,
    description: string,
    media: Media[]
}
