import { Button, Form, FormGroup } from "react-bootstrap";
import { useState } from "react";
import CustomEditor from "../../elements/CustomEditor";
import { emptyFaq } from "../../interfaces/Faq";
import { useNavigate } from "react-router-dom";

export interface FaqAddProps {
    submitCallback: CallableFunction;
    closeCallback: CallableFunction;
}

export default function FaqAdd(props: FaqAddProps) {
    const navigate = useNavigate();
    const [body, setBody] = useState('');
    
    const handleClose = () => {
        props.closeCallback();
    }

    const handleSubmit = (event: any) => {
        event.preventDefault();
        let error = false;
        let error_messages: string[] = [];
        
        if (error) {
            alert(error_messages.join('\n'));
        } else {
            const newFaq = Object.assign({}, emptyFaq);
            newFaq.title = event.target.title.value;
            newFaq.body = body;
            newFaq.weight = event.target.weight.value;
            const requestOptionsPost = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + localStorage.getItem('token')
                },
                body: JSON.stringify(newFaq)
            };
            fetch('https://srv.taskgen.eu/v1/faq', requestOptionsPost)
                .then(response => {
                    if (response.status !== 200) {
                        navigate('/');
                    }
                    return response.json();
                })
                .then(data => {
                    props.submitCallback(data);                    
                });
        }
    }

    const handleBodyChange = (data: string) => {
        setBody(data);
    }

    const handleTextAnalyzed = () => {

    }

    const doNothing = () => {
        
    }

    return(
        <div className="overlay">
            <div className="inner">
                <div className="overlay-content">
                    <a className="close" onClick={ handleClose }></a>
                    <div className="admin-add-faq-container">
                        <h4>{ 'Add new FAQ' }</h4>
                        <div className="faq-form-container">
                            <Form onSubmit={ handleSubmit }>
                                <FormGroup className="title" controlId="title">
                                    <Form.Label aria-required={ true }>{ 'Title' }</Form.Label>
                                    <Form.Control type="text" required={ true } name="title"
                                        placeholder={ 'Enter a title' } />
                                </FormGroup>
                                <div className="field field-body">
                                    <CustomEditor
                                        change={ handleBodyChange }
                                        changeFormatted={ doNothing }
                                        data={ body }
                                        formatted={ '' }
                                        id="body"
                                        label={ 'Body '}
                                        npl={ false }
                                        task_id={ '' }
                                    />
                                </div>
                                <FormGroup className="weight" controlId="weight">
                                    <Form.Label aria-required={ true }>{ 'Weight' }</Form.Label>
                                    <Form.Control type="number" required={ true } name="weight"
                                        defaultValue="0" placeholder={ 'Enter a weigh' } />
                                </FormGroup>
                                <div className="actions">
                                    <Button type="submit" variant="primary">{ 'Save' }</Button>
                                </div>
                            </Form>
                        </div>
                    </div>
                </div>  
            </div>
        </div>
    );
}