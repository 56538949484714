import { CirclePicker } from "react-color";
import { emptyUsageEvent } from "../components/interfaces/UsageEvent";
import { NlpEvent, emptyNlpEvent } from "../components/interfaces/UsageNlp";
import { Video } from "../components/interfaces/Video";

export function makeSafeForCSS(name: string) {
    if (name == undefined) {
        return '';
    } else {
        return  name.replace(/[!\"#$%&'\(\)\*\+,\.\/:;<=>\?\@\[\\\]\^`\{\|\}~]/g, '').toLowerCase();
    }
}

export interface TaskGenColorPickerProps {
    colorChangedCallback: CallableFunction;
}

export function TaskGenColorPicker(props: TaskGenColorPickerProps) {
    const handleOnChangeColor = (color: any) => {
        props.colorChangedCallback(color);
    }

    const colors = [
        '#FFCDD2', '#F8BBD0', '#E1BEE7', '#D1C4E9', '#C5CAE9', '#BBDEFB',
        '#B3E5FC', '#B2EBF2', '#B2DFDB', '#C8E6C9', '#DCEDC8', '#F0F4C3',
        '#FFF9C4', '#FFECB3', '#FFE0B2', '#FFCCBC', '#D7CCC8', '#CFD8DC',
        '#D9D9D9', '#F3F3F3'
    ];

    return (
        <div className="taskgen-color-picker-container">
            <CirclePicker onChange={(color) => { handleOnChangeColor(color.hex); }}
                colors={ colors } />
      </div>
      )
}

export const isLoggedIn = () => {
    return localStorage.getItem('userId');
}

export const replaceOembed = (s: string) => {
    const iframeTemplate = '<div class="ratio ratio-16x9">' +
        '<iframe width="560" height="315" src="https://www.youtube.com/embed/[id]" ' +
        'title="YouTube video player" frameborder="0" ' +
        'allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"' + 
        'allowfullscreen></iframe></div>';
    
    let result = '';
    if (s.length > 0) {
        const start = s.indexOf('<oembed url="');
        if (start !== -1) {
            const end = s.indexOf('</oembed>');
            if (end !== -1) {
                result = s.slice(0, start);
                const restOfString = s.slice(end + 9);
                const oembed = s.slice(start, end + 9);
                const idStart = oembed.indexOf('https://youtu.be/');
                const idEnd = oembed.indexOf('">');
                const id = oembed.slice(idStart + 17, idEnd);
                result += iframeTemplate.replace('[id]', id);
                if (restOfString.length > 0) {
                    result += replaceOembed(s.slice(end + 9));
                }
            }
        } else {
            return s;
        }
    }

    return result;
}

export async function registerEventStart(action: string, reference: string) {
    const newUsageEvent = Object.assign({}, emptyUsageEvent);
    let user = localStorage.getItem('userEmail');
    if (user == undefined || user == null) {
        user = 'unknown';
    }
    newUsageEvent.user = user;
    newUsageEvent.action = action;
    newUsageEvent.reference = reference;
    const requestOptionsPost = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('token')
        },
        body: JSON.stringify(newUsageEvent)
    };
    const res = await fetch('https://srv.taskgen.eu/v1/usage-event', requestOptionsPost)
    const final_data = await res.json()
    return final_data._id;
}

export const registerEventEnd = (id: string) => {
    const requestOptionsPost = {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('token')
        }
    };
    fetch('https://srv.taskgen.eu/v1/usage-event/' + id, requestOptionsPost)
        .then(response => response.json())
        .then(data => {
            
        });
}

export async function registerNlpStart(task_id: string, group: string, subgroup: string, option: string) {
    const newNlpEvent: NlpEvent = Object.assign({}, emptyNlpEvent);
    let user = localStorage.getItem('userEmail');
    if (user == undefined || user == null) {
        user = 'unknown';
    }
    newNlpEvent.user = user;
    newNlpEvent.task_id = task_id;
    newNlpEvent.group = group;
    newNlpEvent.subgroup = subgroup;
    newNlpEvent.option = option;
    const requestOptionsPost = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('token')
        },
        body: JSON.stringify(newNlpEvent)
    };
    const res = await fetch('https://srv.taskgen.eu/v1/usage-nlp', requestOptionsPost)
    const final_data = await res.json()
    return final_data._id;
}

export const obscureEmail = (email) => {
    const [name, domain] = email.split('@');
    return `${name[0]}${new Array(name.length).join('*')}@${domain}`;
};

export function replaceVideoShortcodes(text: string, videos: Video[]) {
    const splitted = text.split('[video=');
    const videoids: string[] = [];
    const videoShortcodes: string[] = [];
    for (let i = 0; i < splitted.length; i++) {
        if (splitted[i].lastIndexOf('"', 0) === 0) {
            const pos = splitted[i].indexOf('"]', 0);
            if (pos > 0) {
                const videoId = splitted[i].substring(1, pos);
                videoids.push(videoId)
                videoShortcodes.push('[video="' + videoId + '"]');
            }
        }
    }
    let newBody = text;
    if (videoids.length) {
        for (let i = 0; i < videoids.length; i++) {
            const indexOfVideo = videos.findIndex((v: Video) => v._id == videoids[i]);
            if (indexOfVideo !== -1) {
                const videoCode = '<div className="video media-item">' +
                    '<video controls>' +
                    '<source src="https://srv.taskgen.eu/' + videos[indexOfVideo].media[0].path + '" />' +
                    '</video>' +
                    '</div>';
                newBody = newBody.replace(videoShortcodes[i], videoCode);
            }
        }
    } 
    return newBody;
}
