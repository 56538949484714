import { useEffect, useState } from 'react';
import { Accordion, Alert, Form, FormCheck } from 'react-bootstrap';
import parse from "html-react-parser";
import { registerNlpStart } from '../../utils/utils';

export interface AnalyzedTextProps {
    task_id: string;
    analyzedText: any;
    saveFormattedTextCallback: CallableFunction;
}

const emptyVerbTensOptions = {
    Present_Simple: false,
    Present_Continuous: false,
    Present_Perfect: false,
    Present_Perfect_Continuous: false,
    Past_Simple: false,
    Past_Continuous: false,
    Past_Perfect: false,
    Past_Perfect_Continuous: false,
    Future_Be_Going_To: false,
    Future_Simple: false,
    Future_Perfect: false,
    Future_Perfect_Continuous: false,
    Present_Simple_Passive: false,
    Present_Continuous_Passive: false,
    Present_Perfect_Passive: false,
    Present_Perfect_Continuous_Passive: false,
    Past_Simple_Passive: false,
    Past_Continuous_Passive: false,
    Past_Perfect_Passive: false,
    Past_Perfect_Continuous_Passive: false,
    Future_Simple_Passive: false,
    Future_Perfect_Passive: false
};

export default function AnalyzedText(props: AnalyzedTextProps) {
    const [allText, setAllText] = useState('');
    const [processingEmpty, setProcessingEmpty] = useState(false);

    const [nlpCategories, setNlpCategories] = useState<any>({});
    const [pragmaticsFilterActive, setPragmaticsFilterActive] = useState(false);
    const [pragmaticsFilterOption, setPragmaticsFilterOption] = useState('all');
    const [pragmaticsColor, setPragmaticColor] = useState('');
    const [pragmaticsSize, setPragmaticSize] = useState('');
    const [pragmaticsBold, setPragmaticBold] = useState(false);
    const [pragmaticsItalic, setPragmaticItalic] = useState(false);
    const [pragmaticsUnderline, setPragmaticUnderline] = useState(false);

    const [phrasalVerbsFilterActive, setPhrasalVerbsFilterActive] = useState(false);
    // const [phrasalVerbsFilterOption, setPhrasalVerbsFilterOption] = useState('all');
    const [phrasalVerbsColor, setPhrasalVerbsColor] = useState('');
    const [phrasalVerbsSize, setPhrasalVerbsSize] = useState('');
    const [phrasalVerbsBold, setPhrasalVerbsBold] = useState(false);
    const [phrasalVerbsItalic, setPhrasalVerbsItalic] = useState(false);
    const [phrasalVerbsUnderline, setPhrasalVerbsUnderline] = useState(false);
    
    const [nounsFilterActive, setNounsFilterActive] = useState(false);
    const [nounsFilterOption, setNounsFilterOption] = useState('all');
    const [nounsColor, setNounsColor] = useState('');
    const [nounsSize, setNounsSize] = useState('');
    const [nounsBold, setNounsBold] = useState(false);
    const [nounsItalic, setNounsItalic] = useState(false);
    const [nounsUnderline, setNounsUnderline] = useState(false);

    const [adjectivesFilterActive, setAdjectivesFilterActive] = useState(false);
    const [adjectivesFilterOption, setAdjectivesFilterOption] = useState('all');
    const [adjectivesColor, setAdjectivesColor] = useState('');
    const [adjectivesSize, setAdjectivesSize] = useState('');
    const [adjectivesBold, setAdjectivesBold] = useState(false);
    const [adjectivesItalic, setAdjectivesItalic] = useState(false);
    const [adjectivesUnderline, setAdjectivesUnderline] = useState(false);

    const [adverbsFilterActive, setAdverbsFilterActive] = useState(false);
    const [adverbsFilterOption, setAdverbsFilterOption] = useState('all');
    const [adverbsColor, setAdverbsColor] = useState('');
    const [adverbsSize, setAdverbsSize] = useState('');
    const [adverbsBold, setAdverbsBold] = useState(false);
    const [adverbsItalic, setAdverbsItalic] = useState(false);
    const [adverbsUnderline, setAdverbsUnderline] = useState(false);

    const [verbsFilterActive, setVerbsFilterActive] = useState(false);
    const [verbsFilterOption, setVerbsFilterOption] = useState('all');
    const [verbsColor, setVerbsColor] = useState('');
    const [verbsSize, setVerbsSize] = useState('');
    const [verbsBold, setVerbsBold] = useState(false);
    const [verbsItalic, setVerbsItalic] = useState(false);
    const [verbsUnderline, setVerbsUnderline] = useState(false);
    const [verbTenseOptions, setVerbTensOptions] = useState(emptyVerbTensOptions);

    const [prepositionsFilterActive, setPrepositionsFilterActive] = useState(false);
    const [prepositionsFilterOption, setPrepositionsFilterOption] = useState('all');
    const [prepositionsColor, setPrepositionsColor] = useState('');
    const [prepositionsSize, setPrepositionsSize] = useState('');
    const [prepositionsBold, setPrepositionsBold] = useState(false);
    const [prepositionsItalic, setPrepositionsItalic] = useState(false);
    const [prepositionsUnderline, setPrepositionsUnderline] = useState(false);

    const [subConjunctionsFilterActive, setSubConjunctionsFilterActive] = useState(false);
    const [subConjunctionsFilterOption, setSubConjunctionsFilterOption] = useState('all');
    const [subConjunctionsColor, setSubConjunctionsColor] = useState('');
    const [subConjunctionsSize, setSubConjunctionsSize] = useState('');
    const [subConjunctionsBold, setSubConjunctionsBold] = useState(false);
    const [subConjunctionsItalic, setSubConjunctionsItalic] = useState(false);
    const [subConjunctionsUnderline, setSubConjunctionsUnderline] = useState(false);

    const [determinersFilterActive, setDeterminersFilterActive] = useState(false);
    const [determinersFilterOption, setDeterminersFilterOption] = useState('all');
    const [determinersColor, setDeterminersColor] = useState('');
    const [determinersSize, setDeterminersSize] = useState('');
    const [determinersBold, setDeterminersBold] = useState(false);
    const [determinersItalic, setDeterminersItalic] = useState(false);
    const [determinersUnderline, setDeterminersUnderline] = useState(false);

    const [pronounsFilterActive, setPronounsFilterActive] = useState(false);
    const [pronounsFilterOption, setPronounsFilterOption] = useState('all');
    const [pronounsColor, setPronounsColor] = useState('');
    const [pronounsSize, setPronounsSize] = useState('');
    const [pronounsBold, setPronounsBold] = useState(false);
    const [pronounsItalic, setPronounsItalic] = useState(false);
    const [pronounsUnderline, setPronounsUnderline] = useState(false);

    const [conjunctionsFilterActive, setConjunctionsFilterActive] = useState(false);
    const [conjunctionsFilterOption, setConjunctionsFilterOption] = useState('all');
    const [conjunctionsColor, setConjunctionsColor] = useState('');
    const [conjunctionsSize, setConjunctionsSize] = useState('');
    const [conjunctionsBold, setConjunctionsBold] = useState(false);
    const [conjunctionsItalic, setConjunctionsItalic] = useState(false);
    const [conjunctionsUnderline, setConjunctionsUnderline] = useState(false);

    const handleChangePragmaticExpressions = (event: any) => {
        setPragmaticsFilterActive(event.target.checked);
        setPragmaticsFilterOption('all');
        setPragmaticColor('');
        setPragmaticSize('');
        setPragmaticBold(false);
        setPragmaticItalic(false);
        setPragmaticUnderline(false);
        setPhrasalVerbsFilterActive(false);
        // setPhrasalVerbsFilterOption('');
        setPhrasalVerbsColor('');
        setPhrasalVerbsSize('');
        setPhrasalVerbsBold(false);
        setPhrasalVerbsItalic(false);
        setPhrasalVerbsUnderline(false);
        setNounsFilterActive(false);
        setNounsColor('');
        setNounsSize('');
        setNounsBold(false);
        setNounsItalic(false);
        setNounsUnderline(false);
        setAdjectivesFilterActive(false);
        setAdjectivesColor('');
        setAdjectivesSize('');
        setAdjectivesBold(false);
        setAdjectivesItalic(false);
        setAdjectivesUnderline(false);
        setAdverbsFilterActive(false);
        setAdverbsColor('');
        setAdverbsSize('');
        setAdverbsBold(false);
        setAdverbsItalic(false);
        setAdverbsUnderline(false);
        setVerbsFilterActive(false);
        setVerbsColor('');
        setVerbsSize('');
        setVerbsBold(false);
        setVerbsItalic(false);
        setVerbsUnderline(false);
        setPrepositionsFilterActive(false);
        setPrepositionsColor('');
        setPrepositionsSize('');
        setPrepositionsBold(false);
        setPrepositionsItalic(false);
        setPrepositionsUnderline(false);
        setSubConjunctionsFilterActive(false);
        setSubConjunctionsColor('');
        setSubConjunctionsSize('');
        setSubConjunctionsBold(false);
        setSubConjunctionsItalic(false);
        setSubConjunctionsUnderline(false);
        setDeterminersFilterActive(false);
        setDeterminersColor('');
        setDeterminersSize('');
        setDeterminersBold(false);
        setDeterminersItalic(false);
        setDeterminersUnderline(false);
        setPronounsFilterActive(false);
        setPronounsColor('');
        setPronounsSize('');
        setPronounsBold(false);
        setPronounsItalic(false);
        setPronounsUnderline(false);
        setConjunctionsFilterActive(false);
        setConjunctionsColor('');
        setConjunctionsSize('');
        setConjunctionsBold(false);
        setConjunctionsItalic(false);
        setConjunctionsUnderline(false);
        formatText(props.analyzedText.main,
            event.target.checked, 'all', '', '', false, false, false,
            false, '', '', false, false, false,
            '', '', '', false, false, false,
            '', '', '', false, false, false,
            '', '', '', false, false, false,
            '', emptyVerbTensOptions, '', '', false, false, false,
            '', '', '', false, false, false,
            '', '', '', false, false, false,
            '', '', '', false, false, false,
            '', '', '', false, false, false,
            '', '', '', false, false, false);
    }

    const handlePragmaticsFilterOptionChange = (event) => {
        setPragmaticsFilterOption(event.target.value);
        registerNlpStart(props.task_id, 'Word Categories', 'Pragmatics', event.target.value);
        formatText(props.analyzedText.main,
            pragmaticsFilterActive, event.target.value, pragmaticsColor, pragmaticsSize, pragmaticsBold, pragmaticsItalic, pragmaticsUnderline,
            phrasalVerbsFilterActive, phrasalVerbsColor, phrasalVerbsSize, phrasalVerbsBold, phrasalVerbsItalic, phrasalVerbsUnderline,
            nounsFilterOption, nounsColor, nounsSize, nounsBold, nounsItalic, nounsUnderline,
            adjectivesFilterOption, adjectivesColor, adjectivesSize, adjectivesBold, adjectivesItalic, adjectivesUnderline,
            adverbsFilterOption, adverbsColor, adverbsSize, adverbsBold, adverbsItalic, adverbsUnderline,
            verbsFilterOption, verbTenseOptions, verbsColor, verbsSize, verbsBold, verbsItalic, verbsUnderline,
            prepositionsFilterOption, prepositionsColor, prepositionsSize, prepositionsBold, prepositionsItalic, prepositionsUnderline,
            subConjunctionsFilterOption, subConjunctionsColor, subConjunctionsSize, subConjunctionsBold, subConjunctionsItalic, subConjunctionsUnderline,
            determinersFilterOption, determinersColor, determinersSize, determinersBold, determinersItalic, determinersUnderline,
            pronounsFilterOption, pronounsColor, pronounsSize, pronounsBold, pronounsItalic, pronounsUnderline,
            conjunctionsFilterOption, conjunctionsColor, conjunctionsSize, conjunctionsBold, conjunctionsItalic, conjunctionsUnderline);
    }

    const handleChangePhrasalVerbs = (event: any) => {
        registerNlpStart(props.task_id, 'Word Categories', 'PhrasalVerbs', '');
        setPragmaticsFilterActive(false);
        setPragmaticsFilterOption('');
        setPragmaticColor('');
        setPragmaticSize('');
        setPragmaticBold(false);
        setPragmaticItalic(false);
        setPragmaticUnderline(false);
        setPhrasalVerbsFilterActive(event.target.checked);
        //setPhrasalVerbsFilterOption('all');
        setPhrasalVerbsColor('');
        setPhrasalVerbsSize('');
        setPhrasalVerbsBold(false);
        setPhrasalVerbsItalic(false);
        setPhrasalVerbsUnderline(false);
        setNounsFilterActive(false);
        setNounsColor('');
        setNounsSize('');
        setNounsBold(false);
        setNounsItalic(false);
        setNounsUnderline(false);
        setAdjectivesFilterActive(false);
        setAdjectivesColor('');
        setAdjectivesSize('');
        setAdjectivesBold(false);
        setAdjectivesItalic(false);
        setAdjectivesUnderline(false);
        setAdverbsFilterActive(false);
        setAdverbsColor('');
        setAdverbsSize('');
        setAdverbsBold(false);
        setAdverbsItalic(false);
        setAdverbsUnderline(false);
        setVerbsFilterActive(false);
        setVerbsColor('');
        setVerbsSize('');
        setVerbsBold(false);
        setVerbsItalic(false);
        setVerbsUnderline(false);
        setPrepositionsFilterActive(false);
        setPrepositionsColor('');
        setPrepositionsSize('');
        setPrepositionsBold(false);
        setPrepositionsItalic(false);
        setPrepositionsUnderline(false);
        setSubConjunctionsFilterActive(false);
        setSubConjunctionsColor('');
        setSubConjunctionsSize('');
        setSubConjunctionsBold(false);
        setSubConjunctionsItalic(false);
        setSubConjunctionsUnderline(false);
        setDeterminersFilterActive(false);
        setDeterminersColor('');
        setDeterminersSize('');
        setDeterminersBold(false);
        setDeterminersItalic(false);
        setDeterminersUnderline(false);
        setPronounsFilterActive(false);
        setPronounsColor('');
        setPronounsSize('');
        setPronounsBold(false);
        setPronounsItalic(false);
        setPronounsUnderline(false);
        setConjunctionsFilterActive(false);
        setConjunctionsColor('');
        setConjunctionsSize('');
        setConjunctionsBold(false);
        setConjunctionsItalic(false);
        setConjunctionsUnderline(false);
        formatText(props.analyzedText.main,
            false, '', '', '', false, false, false,
            event.target.checked, '', '', false, false, false,
            '', '', '', false, false, false,
            '', '', '', false, false, false,
            '', '', '', false, false, false,
            '', emptyVerbTensOptions, '', '', false, false, false,
            '', '', '', false, false, false,
            '', '', '', false, false, false,
            '', '', '', false, false, false,
            '', '', '', false, false, false,
            '', '', '', false, false, false);
    }

    const handleNounsFilterChange = (event) => {
        setPragmaticsFilterActive(false);
        setPragmaticsFilterOption('all');
        setPragmaticColor('');
        setPragmaticSize('');
        setPragmaticBold(false);
        setPragmaticItalic(false);
        setPragmaticUnderline(false);
        setPhrasalVerbsFilterActive(false);
        // setPhrasalVerbsFilterOption('all');
        setPhrasalVerbsColor('');
        setPhrasalVerbsSize('');
        setPhrasalVerbsBold(false);
        setPhrasalVerbsItalic(false);
        setPhrasalVerbsUnderline(false);
        setNounsColor('');
        setNounsSize('');
        setNounsBold(false);
        setNounsItalic(false);
        setNounsUnderline(false);
        setNounsFilterActive(event.target.checked);
        formatText(props.analyzedText.main,
            false, '', '', '', false, false, false,
            false, '', '', false, false, false,
            'all', '', '', false, false, false,
            adjectivesFilterOption, adjectivesColor, adjectivesSize, adjectivesBold, adjectivesItalic, adjectivesUnderline,
            adverbsFilterOption, adverbsColor, adverbsSize, adverbsBold, adverbsItalic, adverbsUnderline,
            verbsFilterOption, verbTenseOptions, verbsColor, verbsSize, verbsBold, verbsItalic, verbsUnderline,
            prepositionsFilterOption, prepositionsColor, prepositionsSize, prepositionsBold, prepositionsItalic, prepositionsUnderline,
            subConjunctionsFilterOption, subConjunctionsColor, subConjunctionsSize, subConjunctionsBold, subConjunctionsItalic, subConjunctionsUnderline,
            determinersFilterOption, determinersColor, determinersSize, determinersBold, determinersItalic, determinersUnderline,
            pronounsFilterOption, pronounsColor, pronounsSize, pronounsBold, pronounsItalic, pronounsUnderline,
            conjunctionsFilterOption, conjunctionsColor, conjunctionsSize, conjunctionsBold, conjunctionsItalic, conjunctionsUnderline);
    }

    const handleNounsFilterOptionChange = (event) => {
        setNounsFilterOption(event.target.value);
        registerNlpStart(props.task_id, 'Word Categories', 'Nouns', event.target.value);
        formatText(props.analyzedText.main,
            pragmaticsFilterActive, pragmaticsFilterOption, pragmaticsColor, pragmaticsSize, pragmaticsBold, pragmaticsItalic, pragmaticsUnderline,
            phrasalVerbsFilterActive, phrasalVerbsColor, phrasalVerbsSize, phrasalVerbsBold, phrasalVerbsItalic, phrasalVerbsUnderline,
            event.target.value, nounsColor, nounsSize, nounsBold, nounsItalic, nounsUnderline,
            adjectivesFilterOption, adjectivesColor, adjectivesSize, adjectivesBold, adjectivesItalic, adjectivesUnderline,
            adverbsFilterOption, adverbsColor, adverbsSize, adverbsBold, adverbsItalic, adverbsUnderline,
            verbsFilterOption, verbTenseOptions, verbsColor, verbsSize, verbsBold, verbsItalic, verbsUnderline,
            prepositionsFilterOption, prepositionsColor, prepositionsSize, prepositionsBold, prepositionsItalic, prepositionsUnderline,
            subConjunctionsFilterOption, subConjunctionsColor, subConjunctionsSize, subConjunctionsBold, subConjunctionsItalic, subConjunctionsUnderline,
            determinersFilterOption, determinersColor, determinersSize, determinersBold, determinersItalic, determinersUnderline,
            pronounsFilterOption, pronounsColor, pronounsSize, pronounsBold, pronounsItalic, pronounsUnderline,
            conjunctionsFilterOption, conjunctionsColor, conjunctionsSize, conjunctionsBold, conjunctionsItalic, conjunctionsUnderline);
    }

    const handleAdjectivesFilterChange = (event) => {
        setPragmaticsFilterActive(false);
        setPragmaticsFilterOption('all');
        setPragmaticColor('');
        setPragmaticSize('');
        setPragmaticBold(false);
        setPragmaticItalic(false);
        setPragmaticUnderline(false);
        setPhrasalVerbsFilterActive(false);
        //setPhrasalVerbsFilterOption('all');
        setPhrasalVerbsColor('');
        setPhrasalVerbsSize('');
        setPhrasalVerbsBold(false);
        setPhrasalVerbsItalic(false);
        setPhrasalVerbsUnderline(false);
        setAdjectivesColor('');
        setAdjectivesSize('');
        setAdjectivesBold(false);
        setAdjectivesItalic(false);
        setAdjectivesUnderline(false);
        setAdjectivesFilterActive(event.target.checked);
        formatText(props.analyzedText.main,
            false, '', '', '', false, false, false,
            false, '', '', false, false, false,
            nounsFilterOption, nounsColor, nounsSize, nounsBold, nounsItalic, nounsUnderline,
            'all', '', '', false, false, false,
            adverbsFilterOption, adverbsColor, adverbsSize, adverbsBold, adverbsItalic, adverbsUnderline,
            verbsFilterOption, verbTenseOptions, verbsColor, verbsSize, verbsBold, verbsItalic, verbsUnderline,
            prepositionsFilterOption, prepositionsColor, prepositionsSize, prepositionsBold, prepositionsItalic, prepositionsUnderline,
            subConjunctionsFilterOption, subConjunctionsColor, subConjunctionsSize, subConjunctionsBold, subConjunctionsItalic, subConjunctionsUnderline,
            determinersFilterOption, determinersColor, determinersSize, determinersBold, determinersItalic, determinersUnderline,
            pronounsFilterOption, pronounsColor, pronounsSize, pronounsBold, pronounsItalic, pronounsUnderline,
            conjunctionsFilterOption, conjunctionsColor, conjunctionsSize, conjunctionsBold, conjunctionsItalic, conjunctionsUnderline);
    }

    const handleAdjectivesFilterOptionChange = (event) => {
       setAdjectivesFilterOption(event.target.value);
       registerNlpStart(props.task_id, 'Word Categories', 'Adjectives', event.target.value);
       formatText(props.analyzedText.main,
        pragmaticsFilterActive, pragmaticsFilterOption, pragmaticsColor, pragmaticsSize, pragmaticsBold, pragmaticsItalic, pragmaticsUnderline,
        phrasalVerbsFilterActive, phrasalVerbsColor, phrasalVerbsSize, phrasalVerbsBold, phrasalVerbsItalic, phrasalVerbsUnderline,
        nounsFilterOption, nounsColor, nounsSize, nounsBold, nounsItalic, nounsUnderline,
        event.target.value, adjectivesColor, adjectivesSize, adjectivesBold, adjectivesItalic, adjectivesUnderline,
        adverbsFilterOption, adverbsColor, adverbsSize, adverbsBold, adverbsItalic, adverbsUnderline,
        verbsFilterOption, verbTenseOptions, verbsColor, verbsSize, verbsBold, verbsItalic, verbsUnderline,
        prepositionsFilterOption, prepositionsColor, prepositionsSize, prepositionsBold, prepositionsItalic, prepositionsUnderline,
        subConjunctionsFilterOption, subConjunctionsColor, subConjunctionsSize, subConjunctionsBold, subConjunctionsItalic, subConjunctionsUnderline,
        determinersFilterOption, determinersColor, determinersSize, determinersBold, determinersItalic, determinersUnderline,
        pronounsFilterOption, pronounsColor, pronounsSize, pronounsBold, pronounsItalic, pronounsUnderline,
        conjunctionsFilterOption, conjunctionsColor, conjunctionsSize, conjunctionsBold, conjunctionsItalic, conjunctionsUnderline);
    }

    const handleAdverbsFilterChange = (event) => {
        setPragmaticsFilterActive(false);
        setPragmaticsFilterOption('all');
        setPragmaticColor('');
        setPragmaticSize('');
        setPragmaticBold(false);
        setPragmaticItalic(false);
        setPragmaticUnderline(false);
        setPhrasalVerbsFilterActive(false);
        //setPhrasalVerbsFilterOption('all');
        setPhrasalVerbsColor('');
        setPhrasalVerbsSize('');
        setPhrasalVerbsBold(false);
        setPhrasalVerbsItalic(false);
        setPhrasalVerbsUnderline(false);
        setAdverbsColor('');
        setAdverbsSize('');
        setAdverbsBold(false);
        setAdverbsItalic(false);
        setAdverbsUnderline(false);
        setAdverbsFilterActive(event.target.checked);
        formatText(props.analyzedText.main,
            false, '', '', '', false, false, false,
            false, '', '', false, false, false,
            nounsFilterOption, nounsColor, nounsSize, nounsBold, nounsItalic, nounsUnderline,
            adjectivesFilterOption, adjectivesColor, adjectivesSize, adjectivesBold, adjectivesItalic, adjectivesUnderline,
            'all', '', '', false, false, false,
            verbsFilterOption, verbTenseOptions, verbsColor, verbsSize, verbsBold, verbsItalic, verbsUnderline,
            prepositionsFilterOption, prepositionsColor, prepositionsSize, prepositionsBold, prepositionsItalic, prepositionsUnderline,
            subConjunctionsFilterOption, subConjunctionsColor, subConjunctionsSize, subConjunctionsBold, subConjunctionsItalic, subConjunctionsUnderline,
            determinersFilterOption, determinersColor, determinersSize, determinersBold, determinersItalic, determinersUnderline,
            pronounsFilterOption, pronounsColor, pronounsSize, pronounsBold, pronounsItalic, pronounsUnderline,
            conjunctionsFilterOption, conjunctionsColor, conjunctionsSize, conjunctionsBold, conjunctionsItalic, conjunctionsUnderline);
    }

    const handleAdverbsFilterOptionChange = (event) => {
       setAdverbsFilterOption(event.target.value);
       registerNlpStart(props.task_id, 'Word Categories', 'Adverbs', event.target.value);
       formatText(props.analyzedText.main,
        pragmaticsFilterActive, pragmaticsFilterOption, pragmaticsColor, pragmaticsSize, pragmaticsBold, pragmaticsItalic, pragmaticsUnderline,
        phrasalVerbsFilterActive, phrasalVerbsColor, phrasalVerbsSize, phrasalVerbsBold, phrasalVerbsItalic, phrasalVerbsUnderline,
        nounsFilterOption, nounsColor, nounsSize, nounsBold, nounsItalic, nounsUnderline,
        adjectivesFilterOption, adjectivesColor, adjectivesSize, adjectivesBold, adjectivesItalic, adjectivesUnderline,
        event.target.value, adverbsColor, adverbsSize, adverbsBold, adverbsItalic, adverbsUnderline,
        verbsFilterOption, verbTenseOptions, verbsColor, verbsSize, verbsBold, verbsItalic, verbsUnderline,
        prepositionsFilterOption, prepositionsColor, prepositionsSize, prepositionsBold, prepositionsItalic, prepositionsUnderline,
        subConjunctionsFilterOption, subConjunctionsColor, subConjunctionsSize, subConjunctionsBold, subConjunctionsItalic, subConjunctionsUnderline,
        determinersFilterOption, determinersColor, determinersSize, determinersBold, determinersItalic, determinersUnderline,
        pronounsFilterOption, pronounsColor, pronounsSize, pronounsBold, pronounsItalic, pronounsUnderline,
        conjunctionsFilterOption, conjunctionsColor, conjunctionsSize, conjunctionsBold, conjunctionsItalic, conjunctionsUnderline);
    }

    const handleVerbsFilterChange = (event) => {
        setPragmaticsFilterActive(false);
        setPragmaticsFilterOption('all');
        setPragmaticColor('');
        setPragmaticSize('');
        setPragmaticBold(false);
        setPragmaticItalic(false);
        setPragmaticUnderline(false);
        setPhrasalVerbsFilterActive(false);
        //setPhrasalVerbsFilterOption('all');
        setPhrasalVerbsColor('');
        setPhrasalVerbsSize('');
        setPhrasalVerbsBold(false);
        setPhrasalVerbsItalic(false);
        setPhrasalVerbsUnderline(false);
        setVerbsFilterActive(event.target.checked);
        setVerbsFilterOption('all');
        setVerbTensOptions(emptyVerbTensOptions);
        setVerbsColor('');
        setVerbsSize('');
        setVerbsBold(false);
        setVerbsItalic(false);
        setVerbsUnderline(false);
        formatText(props.analyzedText.main,
            false, '', '', '', false, false, false,
            false, '', '', false, false, false,
            nounsFilterOption, nounsColor, nounsSize, nounsBold, nounsItalic, nounsUnderline,
            adjectivesFilterOption, adjectivesColor, adjectivesSize, adjectivesBold, adjectivesItalic, adjectivesUnderline,
            adverbsFilterOption, adverbsColor, adverbsSize, adverbsBold, adverbsItalic, adverbsUnderline,
            'all', emptyVerbTensOptions, '', '', false, false, false,
            prepositionsFilterOption, prepositionsColor, prepositionsSize, prepositionsBold, prepositionsItalic, prepositionsUnderline,
            subConjunctionsFilterOption, subConjunctionsColor, subConjunctionsSize, subConjunctionsBold, subConjunctionsItalic, subConjunctionsUnderline,
            determinersFilterOption, determinersColor, determinersSize, determinersBold, determinersItalic, determinersUnderline,
            pronounsFilterOption, pronounsColor, pronounsSize, pronounsBold, pronounsItalic, pronounsUnderline,
            conjunctionsFilterOption, conjunctionsColor, conjunctionsSize, conjunctionsBold, conjunctionsItalic, conjunctionsUnderline);
    }
    
    const handleVerbsFilterOptionChange = (event) => {
        setVerbsFilterOption(event.target.value);
        setVerbTensOptions(emptyVerbTensOptions);
        registerNlpStart(props.task_id, 'Word Categories', 'Verbs', event.target.value);
        formatText(props.analyzedText.main,
            pragmaticsFilterActive, pragmaticsFilterOption, pragmaticsColor, pragmaticsSize, pragmaticsBold, pragmaticsItalic, pragmaticsUnderline,
            phrasalVerbsFilterActive, phrasalVerbsColor, phrasalVerbsSize, phrasalVerbsBold, phrasalVerbsItalic, phrasalVerbsUnderline,
            nounsFilterOption, nounsColor, nounsSize, nounsBold, nounsItalic, nounsUnderline,
            adjectivesFilterOption, adjectivesColor, adjectivesSize, adjectivesBold, adjectivesItalic, adjectivesUnderline,
            adverbsFilterOption, adverbsColor, adverbsSize, adverbsBold, adverbsItalic, adverbsUnderline,
            event.target.value, emptyVerbTensOptions, verbsColor, verbsSize, verbsBold, verbsItalic, verbsUnderline,
            prepositionsFilterOption, prepositionsColor, prepositionsSize, prepositionsBold, prepositionsItalic, prepositionsUnderline,
            subConjunctionsFilterOption, subConjunctionsColor, subConjunctionsSize, subConjunctionsBold, subConjunctionsItalic, subConjunctionsUnderline,
            determinersFilterOption, determinersColor, determinersSize, determinersBold, determinersItalic, determinersUnderline,
            pronounsFilterOption, pronounsColor, pronounsSize, pronounsBold, pronounsItalic, pronounsUnderline,
            conjunctionsFilterOption, conjunctionsColor, conjunctionsSize, conjunctionsBold, conjunctionsItalic, conjunctionsUnderline);
     }

     const handleVerbsTensOptionChange = (event: any) => {
         const newVerbTenseOptions = Object.assign({}, verbTenseOptions);
         switch (event.target.id) {
             case 'verbs-tense-Present_Simple':
                 newVerbTenseOptions.Present_Simple = event.target.checked;
                 break;
             case 'verbs-tense-Present_Continuous':
                 newVerbTenseOptions.Present_Continuous = event.target.checked;
                 break;
             case 'verbs-tense-Present_Perfect':
                 newVerbTenseOptions.Present_Perfect = event.target.checked;
                 break;
             case 'verbs-tense-Present_Perfect_Continuous':
                 newVerbTenseOptions.Present_Perfect_Continuous = event.target.checked;
                 break;
             case 'verbs-tense-Past_Simple':
                 newVerbTenseOptions.Past_Simple = event.target.checked;
                 break;
             case 'verbs-tense-Past_Continuous':
                 newVerbTenseOptions.Past_Continuous = event.target.checked;
                 break;
             case 'verbs-tense-Past_Perfect':
                 newVerbTenseOptions.Past_Perfect = event.target.checked;
                 break;
             case 'verbs-tense-Past_Perfect_Continuous':
                 newVerbTenseOptions.Past_Perfect_Continuous = event.target.checked;
                 break;
             case 'verbs-tense-Future_Be_Going_To':
                 newVerbTenseOptions.Future_Be_Going_To = event.target.checked;
                 break;
             case 'verbs-tense-Future_Simple':
                 newVerbTenseOptions.Future_Simple = event.target.checked;
                 break;
             case 'verbs-tense-Future_Perfect':
                 newVerbTenseOptions.Future_Perfect = event.target.checked;
                 break;
             case 'verbs-tense-Future_Perfect_Continuous':
                 newVerbTenseOptions.Future_Perfect_Continuous = event.target.checked;
                 break;
             case 'verbs-tense-Present_Simple_Passive':
                 newVerbTenseOptions.Present_Simple_Passive = event.target.checked;
                 break;
             case 'verbs-tense-Present_Continuous_Passive':
                 newVerbTenseOptions.Present_Continuous_Passive = event.target.checked;
                 break;
             case 'verbs-tense-Present_Perfect_Passive':
                 newVerbTenseOptions.Present_Perfect_Passive = event.target.checked;
                 break;
             case 'verbs-tense-Present_Perfect_Continuous_Passive':
                 newVerbTenseOptions.Present_Perfect_Continuous_Passive = event.target.checked;
                 break;
             case 'verbs-tense-Past_Simple_Passive':
                 newVerbTenseOptions.Past_Simple_Passive = event.target.checked;
                 break;
             case 'verbs-tense-Past_Continuous_Passive':
                 newVerbTenseOptions.Past_Continuous_Passive = event.target.checked;
                 break;
             case 'verbs-tense-Past_Perfect_Passive':
                 newVerbTenseOptions.Past_Perfect_Passive = event.target.checked;
                 break;
             case 'verbs-tense-Past_Perfect_Continuous_Passive':
                 newVerbTenseOptions.Past_Perfect_Continuous_Passive = event.target.checked;
                 break;
             case 'verbs-tense-Future_Simple_Passive':
                 newVerbTenseOptions.Future_Simple_Passive = event.target.checked;
                 break;
             case 'verbs-tense-Future_Perfect_Passive':
                 newVerbTenseOptions.Future_Perfect_Passive = event.target.checked;
                 break;
         }
         setVerbTensOptions(newVerbTenseOptions);
         formatText(props.analyzedText.main,
            pragmaticsFilterActive, pragmaticsFilterOption, pragmaticsColor, pragmaticsSize, pragmaticsBold, pragmaticsItalic, pragmaticsUnderline,
            phrasalVerbsFilterActive, phrasalVerbsColor, phrasalVerbsSize, phrasalVerbsBold, phrasalVerbsItalic, phrasalVerbsUnderline,
            nounsFilterOption, nounsColor, nounsSize, nounsBold, nounsItalic, nounsUnderline,
            adjectivesFilterOption, adjectivesColor, adjectivesSize, adjectivesBold, adjectivesItalic, adjectivesUnderline,
            adverbsFilterOption, adverbsColor, adverbsSize, adverbsBold, adverbsItalic, adverbsUnderline,
            verbsFilterOption, newVerbTenseOptions, verbsColor, verbsSize, verbsBold, verbsItalic, verbsUnderline,
            prepositionsFilterOption, prepositionsColor, prepositionsSize, prepositionsBold, prepositionsItalic, prepositionsUnderline,
            subConjunctionsFilterOption, subConjunctionsColor, subConjunctionsSize, subConjunctionsBold, subConjunctionsItalic, subConjunctionsUnderline,
            determinersFilterOption, determinersColor, determinersSize, determinersBold, determinersItalic, determinersUnderline,
            pronounsFilterOption, pronounsColor, pronounsSize, pronounsBold, pronounsItalic, pronounsUnderline,
            conjunctionsFilterOption, conjunctionsColor, conjunctionsSize, conjunctionsBold, conjunctionsItalic, conjunctionsUnderline);
    }

    const handlePrepositionsFilterChange = (event) => {
        setPragmaticsFilterActive(false);
        setPragmaticsFilterOption('all');
        setPragmaticColor('');
        setPragmaticSize('');
        setPragmaticBold(false);
        setPragmaticItalic(false);
        setPragmaticUnderline(false);
        setPhrasalVerbsFilterActive(false);
        //setPhrasalVerbsFilterOption('all');
        setPhrasalVerbsColor('');
        setPhrasalVerbsSize('');
        setPhrasalVerbsBold(false);
        setPhrasalVerbsItalic(false);
        setPhrasalVerbsUnderline(false);
        setPrepositionsColor('');
        setPrepositionsSize('');
        setPrepositionsBold(false);
        setPrepositionsItalic(false);
        setPrepositionsUnderline(false);
        setPrepositionsFilterActive(event.target.checked);
        formatText(props.analyzedText.main,
            false, '', '', '', false, false, false,
            false, '', '', false, false, false,
            nounsFilterOption, nounsColor, nounsSize, nounsBold, nounsItalic, nounsUnderline,
            adjectivesFilterOption, adjectivesColor, adjectivesSize, adjectivesBold, adjectivesItalic, adjectivesUnderline,
            adverbsFilterOption, adverbsColor, adverbsSize, adverbsBold, adverbsItalic, adverbsUnderline,
            verbsFilterOption, verbTenseOptions, verbsColor, verbsSize, verbsBold, verbsItalic, verbsUnderline,
            'all', '', '', false, false, false,
            subConjunctionsFilterOption, subConjunctionsColor, subConjunctionsSize, subConjunctionsBold, subConjunctionsItalic, subConjunctionsUnderline,
            determinersFilterOption, determinersColor, determinersSize, determinersBold, determinersItalic, determinersUnderline,
            pronounsFilterOption, pronounsColor, pronounsSize, pronounsBold, pronounsItalic, pronounsUnderline,
            conjunctionsFilterOption, conjunctionsColor, conjunctionsSize, conjunctionsBold, conjunctionsItalic, conjunctionsUnderline);
    }
    
    const handlePrepositionsFilterOptionChange = (event) => {
        setPrepositionsFilterOption(event.target.value);
        registerNlpStart(props.task_id, 'Word Categories', 'Preposition', event.target.value);
        formatText(props.analyzedText.main,
            pragmaticsFilterActive, pragmaticsFilterOption, pragmaticsColor, pragmaticsSize, pragmaticsBold, pragmaticsItalic, pragmaticsUnderline,
            phrasalVerbsFilterActive, phrasalVerbsColor, phrasalVerbsSize, phrasalVerbsBold, phrasalVerbsItalic, phrasalVerbsUnderline,
            nounsFilterOption, nounsColor, nounsSize, nounsBold, nounsItalic, nounsUnderline,
            adjectivesFilterOption, adjectivesColor, adjectivesSize, adjectivesBold, adjectivesItalic, adjectivesUnderline,
            adverbsFilterOption, adverbsColor, adverbsSize, adverbsBold, adverbsItalic, adverbsUnderline,
            verbsFilterOption, verbTenseOptions, verbsColor, verbsSize, verbsBold, verbsItalic, verbsUnderline,
            event.target.value, prepositionsColor, prepositionsSize, prepositionsBold, prepositionsItalic, prepositionsUnderline,
            subConjunctionsFilterOption, subConjunctionsColor, subConjunctionsSize, subConjunctionsBold, subConjunctionsItalic, subConjunctionsUnderline,
            determinersFilterOption, determinersColor, determinersSize, determinersBold, determinersItalic, determinersUnderline,
            pronounsFilterOption, pronounsColor, pronounsSize, pronounsBold, pronounsItalic, pronounsUnderline,
            conjunctionsFilterOption, conjunctionsColor, conjunctionsSize, conjunctionsBold, conjunctionsItalic, conjunctionsUnderline);
    }

    const handleSubConjunctionsFilterChange = (event) => {
        setPragmaticsFilterActive(false);
        setPragmaticsFilterOption('all');
        setPragmaticColor('');
        setPragmaticSize('');
        setPragmaticBold(false);
        setPragmaticItalic(false);
        setPragmaticUnderline(false);
        setPhrasalVerbsFilterActive(false);
        //setPhrasalVerbsFilterOption('all');
        setPhrasalVerbsColor('');
        setPhrasalVerbsSize('');
        setPhrasalVerbsBold(false);
        setPhrasalVerbsItalic(false);
        setPhrasalVerbsUnderline(false);
        setSubConjunctionsColor('');
        setSubConjunctionsSize('');
        setSubConjunctionsBold(false);
        setSubConjunctionsItalic(false);
        setSubConjunctionsUnderline(false);
        setSubConjunctionsFilterActive(event.target.checked);
        formatText(props.analyzedText.main,
            false, '', '', '', false, false, false,
            false, '', '', false, false, false,
            nounsFilterOption, nounsColor, nounsSize, nounsBold, nounsItalic, nounsUnderline,
            adjectivesFilterOption, adjectivesColor, adjectivesSize, adjectivesBold, adjectivesItalic, adjectivesUnderline,
            adverbsFilterOption, adverbsColor, adverbsSize, adverbsBold, adverbsItalic, adverbsUnderline,
            verbsFilterOption, verbTenseOptions, verbsColor, verbsSize, verbsBold, verbsItalic, verbsUnderline,
            prepositionsFilterOption, prepositionsColor, prepositionsSize, prepositionsBold, prepositionsItalic, prepositionsUnderline,
            'all', '', '', false, false, false,
            determinersFilterOption, determinersColor, determinersSize, determinersBold, determinersItalic, determinersUnderline,
            pronounsFilterOption, pronounsColor, pronounsSize, pronounsBold, pronounsItalic, pronounsUnderline,
            conjunctionsFilterOption, conjunctionsColor, conjunctionsSize, conjunctionsBold, conjunctionsItalic, conjunctionsUnderline);
    }
    
    const handleSubConjunctionsFilterOptionChange = (event) => {
        setPrepositionsFilterOption(event.target.value);
        registerNlpStart(props.task_id, 'Word Categories', 'SubConjunction', event.target.value);
        formatText(props.analyzedText.main,
            pragmaticsFilterActive, pragmaticsFilterOption, pragmaticsColor, pragmaticsSize, pragmaticsBold, pragmaticsItalic, pragmaticsUnderline,
            phrasalVerbsFilterActive, phrasalVerbsColor, phrasalVerbsSize, phrasalVerbsBold, phrasalVerbsItalic, phrasalVerbsUnderline,
            nounsFilterOption, nounsColor, nounsSize, nounsBold, nounsItalic, nounsUnderline,
            adjectivesFilterOption, adjectivesColor, adjectivesSize, adjectivesBold, adjectivesItalic, adjectivesUnderline,
            adverbsFilterOption, adverbsColor, adverbsSize, adverbsBold, adverbsItalic, adverbsUnderline,
            verbsFilterOption, verbTenseOptions, verbsColor, verbsSize, verbsBold, verbsItalic, verbsUnderline,
            prepositionsFilterOption, prepositionsColor, prepositionsSize, prepositionsBold, prepositionsItalic, prepositionsUnderline,
            event.target.value, prepositionsColor, prepositionsSize, prepositionsBold, prepositionsItalic, prepositionsUnderline,
            determinersFilterOption, determinersColor, determinersSize, determinersBold, determinersItalic, determinersUnderline,
            pronounsFilterOption, pronounsColor, pronounsSize, pronounsBold, pronounsItalic, pronounsUnderline,
            conjunctionsFilterOption, conjunctionsColor, conjunctionsSize, conjunctionsBold, conjunctionsItalic, conjunctionsUnderline);
    }

    const handleDeterminersFilterChange = (event) => {
        setPragmaticsFilterActive(false);
        setPragmaticsFilterOption('all');
        setPragmaticColor('');
        setPragmaticSize('');
        setPragmaticBold(false);
        setPragmaticItalic(false);
        setPragmaticUnderline(false);
        setPhrasalVerbsFilterActive(false);
        //setPhrasalVerbsFilterOption('all');
        setPhrasalVerbsColor('');
        setPhrasalVerbsSize('');
        setPhrasalVerbsBold(false);
        setPhrasalVerbsItalic(false);
        setPhrasalVerbsUnderline(false);
        setDeterminersColor('');
        setDeterminersSize('');
        setDeterminersBold(false);
        setDeterminersItalic(false);
        setDeterminersUnderline(false);
        setDeterminersFilterActive(false);
        setSubConjunctionsColor('');
        setSubConjunctionsSize('');
        setSubConjunctionsBold(false);
        setSubConjunctionsItalic(false);
        setSubConjunctionsUnderline(false);
        setSubConjunctionsFilterActive(false);
        formatText(props.analyzedText.main,
            false, '', '', '', false, false, false,
            false, '', '', false, false, false,
            nounsFilterOption, nounsColor, nounsSize, nounsBold, nounsItalic, nounsUnderline,
            adjectivesFilterOption, adjectivesColor, adjectivesSize, adjectivesBold, adjectivesItalic, adjectivesUnderline,
            adverbsFilterOption, adverbsColor, adverbsSize, adverbsBold, adverbsItalic, adverbsUnderline,
            verbsFilterOption, verbTenseOptions, verbsColor, verbsSize, verbsBold, verbsItalic, verbsUnderline,
            prepositionsFilterOption, prepositionsColor, prepositionsSize, prepositionsBold, prepositionsItalic, prepositionsUnderline,
            subConjunctionsFilterOption, subConjunctionsColor, subConjunctionsSize, subConjunctionsBold, subConjunctionsItalic, subConjunctionsUnderline,
            'all', '', '', false, false, false,
            pronounsFilterOption, pronounsColor, pronounsSize, pronounsBold, pronounsItalic, pronounsUnderline,
            conjunctionsFilterOption, conjunctionsColor, conjunctionsSize, conjunctionsBold, conjunctionsItalic, conjunctionsUnderline);
    }
    
    const handleDeterminersFilterOptionChange = (event) => {
        setDeterminersFilterOption(event.target.value);
        registerNlpStart(props.task_id, 'Word Categories', 'Determiners', event.target.value);
        formatText(props.analyzedText.main,
         pragmaticsFilterActive, pragmaticsFilterOption, pragmaticsColor, pragmaticsSize, pragmaticsBold, pragmaticsItalic, pragmaticsUnderline,
         phrasalVerbsFilterActive, phrasalVerbsColor, phrasalVerbsSize, phrasalVerbsBold, phrasalVerbsItalic, phrasalVerbsUnderline,
         nounsFilterOption, nounsColor, nounsSize, nounsBold, nounsItalic, nounsUnderline,
         adjectivesFilterOption, adjectivesColor, adjectivesSize, adjectivesBold, adjectivesItalic, adjectivesUnderline,
         adverbsFilterOption, adverbsColor, adverbsSize, adverbsBold, adverbsItalic, adverbsUnderline,
         verbsFilterOption, verbTenseOptions, verbsColor, verbsSize, verbsBold, verbsItalic, verbsUnderline,
         prepositionsFilterOption, prepositionsColor, prepositionsSize, prepositionsBold, prepositionsItalic, prepositionsUnderline,
         subConjunctionsFilterOption, subConjunctionsColor, subConjunctionsSize, subConjunctionsBold, subConjunctionsItalic, subConjunctionsUnderline,
         event.target.value, determinersColor, determinersSize, determinersBold, determinersItalic, determinersUnderline,
         pronounsFilterOption, pronounsColor, pronounsSize, pronounsBold, pronounsItalic, pronounsUnderline,
         conjunctionsFilterOption, conjunctionsColor, conjunctionsSize, conjunctionsBold, conjunctionsItalic, conjunctionsUnderline);
    }

    const handlePronounsFilterChange = (event) => {
        setPragmaticsFilterActive(false);
        setPragmaticsFilterOption('all');
        setPragmaticColor('');
        setPragmaticSize('');
        setPragmaticBold(false);
        setPragmaticItalic(false);
        setPragmaticUnderline(false);
        setPhrasalVerbsFilterActive(false);
        //setPhrasalVerbsFilterOption('all');
        setPhrasalVerbsColor('');
        setPhrasalVerbsSize('');
        setPhrasalVerbsBold(false);
        setPhrasalVerbsItalic(false);
        setPhrasalVerbsUnderline(false);
        setPronounsColor('');
        setPronounsSize('');
        setPronounsBold(false);
        setPronounsItalic(false);
        setPronounsUnderline(false);
        setPronounsFilterActive(event.target.checked);
        formatText(props.analyzedText.main,
            false, '', '', '', false, false, false,
            false, '', '', false, false, false,
            nounsFilterOption, nounsColor, nounsSize, nounsBold, nounsItalic, nounsUnderline,
            adjectivesFilterOption, adjectivesColor, adjectivesSize, adjectivesBold, adjectivesItalic, adjectivesUnderline,
            adverbsFilterOption, adverbsColor, adverbsSize, adverbsBold, adverbsItalic, adverbsUnderline,
            verbsFilterOption, verbTenseOptions, verbsColor, verbsSize, verbsBold, verbsItalic, verbsUnderline,
            prepositionsFilterOption, prepositionsColor, prepositionsSize, prepositionsBold, prepositionsItalic, prepositionsUnderline,
            subConjunctionsFilterOption, subConjunctionsColor, subConjunctionsSize, subConjunctionsBold, subConjunctionsItalic, subConjunctionsUnderline,
            determinersFilterOption, determinersColor, determinersSize, determinersBold, determinersItalic, determinersUnderline,
            'all', '', '', false, false, false,
            conjunctionsFilterOption, conjunctionsColor, conjunctionsSize, conjunctionsBold, conjunctionsItalic, conjunctionsUnderline);
    }
    
    const handlePronounsFilterOptionChange = (event) => {
        setPronounsFilterOption(event.target.value);
        registerNlpStart(props.task_id, 'Word Categories', 'Pronouns', event.target.value);
        formatText(props.analyzedText.main,
            pragmaticsFilterActive, pragmaticsFilterOption, pragmaticsColor, pragmaticsSize, pragmaticsBold, pragmaticsItalic, pragmaticsUnderline,
            phrasalVerbsFilterActive, phrasalVerbsColor, phrasalVerbsSize, phrasalVerbsBold, phrasalVerbsItalic, phrasalVerbsUnderline,
            nounsFilterOption, nounsColor, nounsSize, nounsBold, nounsItalic, nounsUnderline,
            adjectivesFilterOption, adjectivesColor, adjectivesSize, adjectivesBold, adjectivesItalic, adjectivesUnderline,
            adverbsFilterOption, adverbsColor, adverbsSize, adverbsBold, adverbsItalic, adverbsUnderline,
            verbsFilterOption, verbTenseOptions, verbsColor, verbsSize, verbsBold, verbsItalic, verbsUnderline,
            prepositionsFilterOption, prepositionsColor, prepositionsSize, prepositionsBold, prepositionsItalic, prepositionsUnderline,
            subConjunctionsFilterOption, subConjunctionsColor, subConjunctionsSize, subConjunctionsBold, subConjunctionsItalic, subConjunctionsUnderline,
            determinersFilterOption, determinersColor, determinersSize, determinersBold, determinersItalic, determinersUnderline,
            event.target.value, pronounsColor, pronounsSize, pronounsBold, pronounsItalic, pronounsUnderline,
            conjunctionsFilterOption, conjunctionsColor, conjunctionsSize, conjunctionsBold, conjunctionsItalic, conjunctionsUnderline);
    }

    const handleConjunctionsFilterChange = (event) => {
        setPragmaticsFilterActive(false);
        setPragmaticsFilterOption('all');
        setPragmaticColor('');
        setPragmaticSize('');
        setPragmaticBold(false);
        setPragmaticItalic(false);
        setPragmaticUnderline(false);
        setPhrasalVerbsFilterActive(false);
        //setPhrasalVerbsFilterOption('all');
        setPhrasalVerbsColor('');
        setPhrasalVerbsSize('');
        setPhrasalVerbsBold(false);
        setPhrasalVerbsItalic(false);
        setPhrasalVerbsUnderline(false);
        setConjunctionsColor('');
        setConjunctionsSize('');
        setConjunctionsBold(false);
        setConjunctionsItalic(false);
        setConjunctionsUnderline(false);
        setConjunctionsFilterActive(event.target.checked);
        formatText(props.analyzedText.main,
            false, '', '', '', false, false, false,
            false, '', '', false, false, false,
            nounsFilterOption, nounsColor, nounsSize, nounsBold, nounsItalic, nounsUnderline,
            adjectivesFilterOption, adjectivesColor, adjectivesSize, adjectivesBold, adjectivesItalic, adjectivesUnderline,
            adverbsFilterOption, adverbsColor, adverbsSize, adverbsBold, adverbsItalic, adverbsUnderline,
            verbsFilterOption, verbTenseOptions, verbsColor, verbsSize, verbsBold, verbsItalic, verbsUnderline,
            prepositionsFilterOption, prepositionsColor, prepositionsSize, prepositionsBold, prepositionsItalic, prepositionsUnderline,
            subConjunctionsFilterOption, subConjunctionsColor, subConjunctionsSize, subConjunctionsBold, subConjunctionsItalic, subConjunctionsUnderline,
            determinersFilterOption, determinersColor, determinersSize, determinersBold, determinersItalic, determinersUnderline,
            pronounsFilterOption, pronounsColor, pronounsSize, pronounsBold, pronounsItalic, pronounsUnderline,
            'all', '', '', false, false, false);
    }

    const setColor = (event: any) => {
        registerNlpStart(props.task_id, 'Color', event.target.dataset.element, event.target.dataset.color);
        let newPragmaticColor = pragmaticsColor;
        let newPhrasalVerbsColor = phrasalVerbsColor;
        let newNounsColor = nounsColor;
        let newAdjectivesColor = adjectivesColor;
        let newAdverbsColor = adverbsColor;
        let newVerbsColor = verbsColor;
        let newPrepositionsColor = prepositionsColor;
        let newSubConjunctionsColor = subConjunctionsColor;
        let newDeterminersColor = determinersColor;
        let newPronounsColor = pronounsColor;
        let newConjunctionsColor = conjunctionsColor;
        switch (event.target.dataset.element) {
            case 'pragmatic':
                setPragmaticColor(event.target.dataset.color);
                newPragmaticColor = event.target.dataset.color;
                break;
            case 'phrasalVerbs':
                setPhrasalVerbsColor(event.target.dataset.color);
                newPhrasalVerbsColor = event.target.dataset.color;
                break;    
            case 'noun':
                setNounsColor(event.target.dataset.color);
                newNounsColor = event.target.dataset.color;
                break;
            case 'adjective':
                setAdjectivesColor(event.target.dataset.color);
                newAdjectivesColor = event.target.dataset.color;
                break;
            case 'adverb':
                setAdverbsColor(event.target.dataset.color);
                newAdverbsColor = event.target.dataset.color;
                break;
            case 'verb':
                setVerbsColor(event.target.dataset.color);
                newVerbsColor = event.target.dataset.color;
                break;
            case 'preposition':
                setPrepositionsColor(event.target.dataset.color);
                newPrepositionsColor = event.target.dataset.color;
                break;    
            case 'subConjunction':
                setSubConjunctionsColor(event.target.dataset.color);
                newSubConjunctionsColor = event.target.dataset.color;
                break;    
            case 'determiner':
                setDeterminersColor(event.target.dataset.color);
                newDeterminersColor = event.target.dataset.color;
                break;
            case 'pronoun':
                setPronounsColor(event.target.dataset.color);
                newPronounsColor = event.target.dataset.color;
                break;
            case 'conjunctions':
                setConjunctionsColor(event.target.dataset.color);
                newConjunctionsColor = event.target.dataset.color;
                break;    
        }
        formatText(props.analyzedText.main,
            pragmaticsFilterActive, pragmaticsFilterOption, newPragmaticColor, pragmaticsSize, pragmaticsBold, pragmaticsItalic, pragmaticsUnderline,
            phrasalVerbsFilterActive, newPhrasalVerbsColor, phrasalVerbsSize, phrasalVerbsBold, phrasalVerbsItalic, phrasalVerbsUnderline,
            nounsFilterOption, newNounsColor, nounsSize, nounsBold, nounsItalic, nounsUnderline,
            adjectivesFilterOption, newAdjectivesColor, adjectivesSize, adjectivesBold, adjectivesItalic, adjectivesUnderline,
            adverbsFilterOption, newAdverbsColor, adverbsSize, adverbsBold, adverbsItalic, adverbsUnderline,
            verbsFilterOption, verbTenseOptions, newVerbsColor, verbsSize, verbsBold, verbsItalic, verbsUnderline,
            prepositionsFilterOption, newPrepositionsColor, prepositionsSize, prepositionsBold, prepositionsItalic, prepositionsUnderline,
            subConjunctionsFilterOption, newSubConjunctionsColor, subConjunctionsSize, subConjunctionsBold, subConjunctionsItalic, subConjunctionsUnderline,
            determinersFilterOption, newDeterminersColor, determinersSize, determinersBold, determinersItalic, determinersUnderline,
            pronounsFilterOption, newPronounsColor, pronounsSize, pronounsBold, pronounsItalic, pronounsUnderline,
            conjunctionsFilterOption, newConjunctionsColor, conjunctionsSize, conjunctionsBold, conjunctionsItalic, conjunctionsUnderline);
    }

    const setSize = (event: any) => {
        registerNlpStart(props.task_id, 'Size', event.target.dataset.element, event.target.dataset.size);
        let newPragmaticSize = pragmaticsSize;
        let newPhrasalVerbsSize = phrasalVerbsSize;
        let newNounsSize = nounsSize;
        let newAdjectivesSize = adjectivesSize;
        let newAdverbsSize = adverbsSize;
        let newVerbsSize = verbsSize;
        let newPrepositionsSize = prepositionsSize;
        let newSubConjunctionsSize = subConjunctionsSize;
        let newDeterminersSize = determinersSize;
        let newPronounsSize = pronounsSize;
        let newConjunctionsSize = conjunctionsSize;
        switch (event.target.dataset.element) {
            case 'pragmatic':
                setPragmaticSize(event.target.dataset.size);
                newPragmaticSize = event.target.dataset.size;
                break;
            case 'phrasalVerbs':
                setPhrasalVerbsSize(event.target.dataset.size);
                newPhrasalVerbsSize = event.target.dataset.size;
                break;    
            case 'noun':
                setNounsSize(event.target.dataset.size);
                newNounsSize = event.target.dataset.size;
                break;
            case 'adjective':
                setAdjectivesSize(event.target.dataset.size);
                newAdjectivesSize = event.target.dataset.size;
                break;
            case 'adverb':
                setAdverbsSize(event.target.dataset.size);
                newAdverbsSize = event.target.dataset.size;
                break;
            case 'verb':
                setVerbsSize(event.target.dataset.size);
                newVerbsSize = event.target.dataset.size;
                break;
            case 'preposition':
                setPrepositionsSize(event.target.dataset.size);
                newPrepositionsSize = event.target.dataset.size;
                break;   
            case 'subConjunction':
                setSubConjunctionsSize(event.target.dataset.size);
                newSubConjunctionsSize = event.target.dataset.size;
                break;    
            case 'determiner':
                setDeterminersSize(event.target.dataset.size);
                newDeterminersSize = event.target.dataset.size;
                break;
            case 'pronoun':
                setPronounsSize(event.target.dataset.size);
                newPronounsSize = event.target.dataset.size;
                break;
            case 'conjunctions':
                setConjunctionsSize(event.target.dataset.size);
                newConjunctionsSize = event.target.dataset.size;
                break;   
        }
        formatText(props.analyzedText.main,
            pragmaticsFilterActive, pragmaticsFilterOption, pragmaticsColor, newPragmaticSize, pragmaticsBold, pragmaticsItalic, pragmaticsUnderline,
            phrasalVerbsFilterActive, phrasalVerbsColor, newPhrasalVerbsSize, phrasalVerbsBold, phrasalVerbsItalic, phrasalVerbsUnderline,
            nounsFilterOption, nounsColor, newNounsSize, nounsBold, nounsItalic, nounsUnderline,
            adjectivesFilterOption, adjectivesColor, newAdjectivesSize, adjectivesBold, adjectivesItalic, adjectivesUnderline,
            adverbsFilterOption, adverbsColor, newAdverbsSize, adverbsBold, adverbsItalic, adverbsUnderline,
            verbsFilterOption, verbTenseOptions, verbsColor, newVerbsSize, verbsBold, verbsItalic, verbsUnderline,
            prepositionsFilterOption, prepositionsColor, newPrepositionsSize, prepositionsBold, prepositionsItalic, prepositionsUnderline,
            subConjunctionsFilterOption, subConjunctionsColor, newSubConjunctionsSize, subConjunctionsBold, subConjunctionsItalic, subConjunctionsUnderline,
            determinersFilterOption, determinersColor, newDeterminersSize, determinersBold, determinersItalic, determinersUnderline,
            pronounsFilterOption, pronounsColor, newPronounsSize, pronounsBold, pronounsItalic, pronounsUnderline,
            conjunctionsFilterOption, conjunctionsColor, newConjunctionsSize, conjunctionsBold, conjunctionsItalic, conjunctionsUnderline);
    }

    const setBold = (event: any) => {
        let newPragmaticBold = pragmaticsBold;
        let newPhrasalVerbsBold = phrasalVerbsBold;
        let newNounsBold = nounsBold;
        let newAdjectivesBold = adjectivesBold;
        let newAdverbsBold = adverbsBold;
        let newVerbsBold = verbsBold;
        let newPrepositionsBold = prepositionsBold;
        let newSubConjunctionsBold = subConjunctionsBold;
        let newDeterminersBold = determinersBold;
        let newPronounsBold = pronounsBold;
        let newConjunctionsBold = conjunctionsBold;
        switch (event.target.dataset.element) {
            case 'pragmatic':
                setPragmaticBold(!pragmaticsBold);
                newPragmaticBold = !pragmaticsBold;
                registerNlpStart(props.task_id, 'Bold', event.target.dataset.element, newPragmaticBold ? 'yes' : 'no');
                break;
            case 'phrasalVerbs':
                setPhrasalVerbsBold(!phrasalVerbsBold);
                newPhrasalVerbsBold = !phrasalVerbsBold;
                registerNlpStart(props.task_id, 'Bold', event.target.dataset.element, newPhrasalVerbsBold ? 'yes' : 'no');
                break;
            case 'noun':
                setNounsBold(!nounsBold);
                newNounsBold = !nounsBold;
                registerNlpStart(props.task_id, 'Bold', event.target.dataset.element, newNounsBold ? 'yes' : 'no');
                break;
            case 'adjective':
                setAdjectivesBold(!adjectivesBold);
                newAdjectivesBold = !adjectivesBold;
                registerNlpStart(props.task_id, 'Bold', event.target.dataset.element, newAdjectivesBold ? 'yes' : 'no');
                break;
            case 'adverb':
                setAdverbsBold(!adverbsBold);
                newAdverbsBold = !adverbsBold;
                registerNlpStart(props.task_id, 'Bold', event.target.dataset.element, newAdverbsBold ? 'yes' : 'no');
                break;
            case 'verb':
                setVerbsBold(!verbsBold);
                newVerbsBold = !verbsBold;
                registerNlpStart(props.task_id, 'Bold', event.target.dataset.element, newVerbsBold ? 'yes' : 'no');
                break;
            case 'preposition':
                setPrepositionsBold(!prepositionsBold);
                newPrepositionsBold = !prepositionsBold;
                registerNlpStart(props.task_id, 'Bold', event.target.dataset.element, newPragmaticBold ? 'yes' : 'no');
                break;
            case 'subConjunction':
                setSubConjunctionsBold(!subConjunctionsBold);
                newSubConjunctionsBold = !subConjunctionsBold;
                registerNlpStart(props.task_id, 'Bold', event.target.dataset.element, newSubConjunctionsBold ? 'yes' : 'no');
                break;
            case 'determiner':
                setDeterminersBold(!determinersBold);
                newDeterminersBold = !determinersBold;
                registerNlpStart(props.task_id, 'Bold', event.target.dataset.element, newDeterminersBold ? 'yes' : 'no');
                break;
            case 'pronoun':
                setPronounsBold(!determinersBold);
                newPronounsBold = !determinersBold;
                registerNlpStart(props.task_id, 'Bold', event.target.dataset.element, newPronounsBold ? 'yes' : 'no');
                break;
            case 'conjunctions':
                setConjunctionsBold(!conjunctionsBold);
                newConjunctionsBold = !conjunctionsBold;
                registerNlpStart(props.task_id, 'Bold', event.target.dataset.element, newConjunctionsBold ? 'yes' : 'no');
                break;                
        }

        formatText(props.analyzedText.main,
            pragmaticsFilterActive, pragmaticsFilterOption, pragmaticsColor, pragmaticsSize, newPragmaticBold, pragmaticsItalic, pragmaticsUnderline,
            phrasalVerbsFilterActive, phrasalVerbsColor, phrasalVerbsSize, newPhrasalVerbsBold, phrasalVerbsItalic, phrasalVerbsUnderline,
            nounsFilterOption, nounsColor, nounsSize, newNounsBold, nounsItalic, nounsUnderline,
            adjectivesFilterOption, adjectivesColor, adjectivesSize, newAdjectivesBold, adjectivesItalic, adjectivesUnderline,
            adverbsFilterOption, adverbsColor, adverbsSize, newAdverbsBold, adverbsItalic, adverbsUnderline,
            verbsFilterOption, verbTenseOptions, verbsColor, verbsSize, newVerbsBold, verbsItalic, verbsUnderline,
            prepositionsFilterOption, prepositionsColor, prepositionsSize, newPrepositionsBold, prepositionsItalic, prepositionsUnderline,
            subConjunctionsFilterOption, subConjunctionsColor, subConjunctionsSize, newSubConjunctionsBold, subConjunctionsItalic, subConjunctionsUnderline,
            determinersFilterOption, determinersColor, determinersSize, newDeterminersBold, determinersItalic, determinersUnderline,
            pronounsFilterOption, pronounsColor, pronounsSize, newPronounsBold, pronounsItalic, pronounsUnderline,
            conjunctionsFilterOption, conjunctionsColor, conjunctionsSize, newConjunctionsBold, conjunctionsItalic, conjunctionsUnderline);
    }

    const setItalic = (event: any) => {
        let newPragmaticsItalic = pragmaticsItalic;
        let newPhrasalVerbsItalic = phrasalVerbsItalic;
        let newNounsItalic = nounsItalic;
        let newAdjectivesItalic = adjectivesItalic;
        let newAdverbsItalic = adverbsItalic;
        let newVerbsItalic = verbsItalic;
        let newPrepositionsItalic = prepositionsItalic;
        let newSubConjunctionsItalic = subConjunctionsItalic;
        let newDeterminersItalic = determinersItalic;
        let newPronounsItalic = pronounsItalic;
        let newConjunctionsItalic = conjunctionsItalic;
        switch (event.target.dataset.element) {
            case 'pragmatic':
                setPragmaticItalic(!pragmaticsItalic);
                newPragmaticsItalic = !pragmaticsItalic;
                registerNlpStart(props.task_id, 'Italic', event.target.dataset.element, newPragmaticsItalic ? 'yes' : 'no');
                break;
            case 'phrasalVerbs':
                setPhrasalVerbsItalic(!phrasalVerbsItalic);
                newPhrasalVerbsItalic = !phrasalVerbsItalic;
                registerNlpStart(props.task_id, 'Italic', event.target.dataset.element, newPhrasalVerbsItalic ? 'yes' : 'no');
                break;
            case 'noun':
                setNounsItalic(!nounsItalic);
                newNounsItalic = !nounsItalic;
                registerNlpStart(props.task_id, 'Italic', event.target.dataset.element, newNounsItalic ? 'yes' : 'no');
                break;
            case 'adjective':
                setAdjectivesItalic(!adjectivesItalic);
                newAdjectivesItalic = !adjectivesItalic;
                registerNlpStart(props.task_id, 'Italic', event.target.dataset.element, newAdjectivesItalic ? 'yes' : 'no');
                break;
            case 'adverb':
                setAdverbsItalic(!adverbsItalic);
                newAdverbsItalic = !adverbsItalic;
                registerNlpStart(props.task_id, 'Italic', event.target.dataset.element, newAdverbsItalic ? 'yes' : 'no');
                break;
            case 'verb':
                setVerbsItalic(!verbsItalic);
                newVerbsItalic = !verbsItalic;
                registerNlpStart(props.task_id, 'Italic', event.target.dataset.element, newVerbsItalic ? 'yes' : 'no');
                break;
            case 'preposition':
                setPrepositionsItalic(!prepositionsItalic);
                newPrepositionsItalic = !prepositionsItalic;
                registerNlpStart(props.task_id, 'Italic', event.target.dataset.element, newPrepositionsItalic ? 'yes' : 'no');
                break;
            case 'subConjunction':
                setSubConjunctionsItalic(!subConjunctionsItalic);
                newSubConjunctionsItalic = !subConjunctionsItalic;
                registerNlpStart(props.task_id, 'Italic', event.target.dataset.element, newSubConjunctionsItalic ? 'yes' : 'no');
                break;
            case 'determiner':
                setDeterminersItalic(!determinersItalic);
                newDeterminersItalic = !determinersItalic;
                registerNlpStart(props.task_id, 'Italic', event.target.dataset.element, newDeterminersItalic ? 'yes' : 'no');
                break;
            case 'pronoun':
                setPronounsItalic(!pronounsItalic);
                newPronounsItalic = !pronounsItalic;
                registerNlpStart(props.task_id, 'Italic', event.target.dataset.element, newPronounsItalic ? 'yes' : 'no');
                break;
            case 'conjunctions':
                setConjunctionsItalic(!conjunctionsItalic);
                newConjunctionsItalic = !conjunctionsItalic;
                registerNlpStart(props.task_id, 'Italic', event.target.dataset.element, newConjunctionsItalic ? 'yes' : 'no');
                break;
        }
        formatText(props.analyzedText.main,
            pragmaticsFilterActive, pragmaticsFilterOption, pragmaticsColor, pragmaticsSize, pragmaticsBold, newPragmaticsItalic, pragmaticsUnderline,
            phrasalVerbsFilterActive, phrasalVerbsColor, phrasalVerbsSize, phrasalVerbsBold, newPhrasalVerbsItalic, phrasalVerbsUnderline,
            nounsFilterOption, nounsColor, nounsSize, nounsBold, newNounsItalic, nounsUnderline, 
            adjectivesFilterOption, adjectivesColor, adjectivesSize, adjectivesBold, newAdjectivesItalic, adjectivesUnderline,
            adverbsFilterOption, adverbsColor, adverbsSize, adverbsBold, newAdverbsItalic, adverbsUnderline,
            verbsFilterOption, verbTenseOptions, verbsColor, verbsSize, verbsBold, newVerbsItalic, verbsUnderline,
            prepositionsFilterOption, prepositionsColor, prepositionsSize, prepositionsBold, newPrepositionsItalic, prepositionsUnderline,
            subConjunctionsFilterOption, subConjunctionsColor, subConjunctionsSize, subConjunctionsBold, newSubConjunctionsItalic, subConjunctionsUnderline,
            determinersFilterOption, determinersColor, determinersSize, determinersBold, newDeterminersItalic, adjectivesUnderline,
            pronounsFilterOption, pronounsColor, pronounsSize, pronounsBold, newPronounsItalic, pronounsUnderline,
            conjunctionsFilterOption, conjunctionsColor, conjunctionsSize, conjunctionsBold, newConjunctionsItalic, conjunctionsUnderline);
    }

    const setUnderline = (event: any) => {
        let newPragmaticsUnderline = pragmaticsUnderline;
        let newPhrasalVerbsUnderline = phrasalVerbsUnderline;
        let newNounsUnderline = nounsUnderline;
        let newAdjectivesUnderline = adjectivesUnderline;
        let newAdverbsUnderline = adverbsUnderline;
        let newVerbsUnderline = verbsUnderline;
        let newPrepositionsUnderline = prepositionsUnderline;
        let newSubConjunctionsUnderline = subConjunctionsUnderline;
        let newDeterminersUnderline = determinersUnderline;
        let newPronounsUnderline = pronounsUnderline;
        let newConjunctionsUnderline = conjunctionsUnderline;
        switch (event.target.dataset.element) {
            case 'pragmatic':
                setPragmaticUnderline(!pragmaticsUnderline);
                newPragmaticsUnderline = !pragmaticsUnderline;
                registerNlpStart(props.task_id, 'Underline', event.target.dataset.element, newPragmaticsUnderline ? 'yes' : 'no');
                break;
            case 'phrasalVerbs':
                setPhrasalVerbsUnderline(!phrasalVerbsUnderline);
                newPhrasalVerbsUnderline = !phrasalVerbsUnderline;
                registerNlpStart(props.task_id, 'Underline', event.target.dataset.element, newPhrasalVerbsUnderline ? 'yes' : 'no');
                break;
            case 'noun':
                setNounsUnderline(!nounsUnderline);
                newNounsUnderline = !nounsUnderline;
                registerNlpStart(props.task_id, 'Underline', event.target.dataset.element, newNounsUnderline ? 'yes' : 'no');
                break;
            case 'adjective':
                setAdjectivesUnderline(!adjectivesUnderline);
                newAdjectivesUnderline = !adjectivesUnderline;
                registerNlpStart(props.task_id, 'Underline', event.target.dataset.element, newAdjectivesUnderline ? 'yes' : 'no');
                break;
            case 'adverb':
                setAdverbsUnderline(!adverbsUnderline);
                newAdverbsUnderline = !adverbsUnderline;
                registerNlpStart(props.task_id, 'Underline', event.target.dataset.element, newAdverbsUnderline ? 'yes' : 'no');
                break;
            case 'verb':
                setVerbsUnderline(!verbsUnderline);
                newVerbsUnderline = !verbsUnderline;
                registerNlpStart(props.task_id, 'Underline', event.target.dataset.element, newVerbsUnderline ? 'yes' : 'no');
                break;
            case 'preposition':
                setPrepositionsUnderline(!prepositionsUnderline);
                newPrepositionsUnderline = !prepositionsUnderline;
                registerNlpStart(props.task_id, 'Underline', event.target.dataset.element, newPrepositionsUnderline ? 'yes' : 'no');
                break;
            case 'subConjunction':
                setSubConjunctionsUnderline(!subConjunctionsUnderline);
                newSubConjunctionsUnderline = !subConjunctionsUnderline;
                registerNlpStart(props.task_id, 'Underline', event.target.dataset.element, newSubConjunctionsUnderline ? 'yes' : 'no');
                break;
            case 'determiner':
                setDeterminersUnderline(!determinersUnderline);
                newDeterminersUnderline = !determinersUnderline;
                registerNlpStart(props.task_id, 'Underline', event.target.dataset.element, newDeterminersUnderline ? 'yes' : 'no');
                break;
            case 'pronoun':
                setPronounsUnderline(!pronounsUnderline);
                newPronounsUnderline = !pronounsUnderline;
                registerNlpStart(props.task_id, 'Underline', event.target.dataset.element, newPronounsUnderline ? 'yes' : 'no');
                break;
            case 'conjunctions':
                setConjunctionsUnderline(!conjunctionsUnderline);
                newConjunctionsUnderline = !conjunctionsUnderline;
                registerNlpStart(props.task_id, 'Underline', event.target.dataset.element, newConjunctionsUnderline ? 'yes' : 'no');
                break;
        }
        formatText(props.analyzedText.main,
            pragmaticsFilterActive, pragmaticsFilterOption, pragmaticsColor, pragmaticsSize, pragmaticsBold, pragmaticsItalic, newPragmaticsUnderline,
            phrasalVerbsFilterActive, phrasalVerbsColor, phrasalVerbsSize, phrasalVerbsBold, phrasalVerbsItalic, newPhrasalVerbsUnderline,
            nounsFilterOption, nounsColor, nounsSize, nounsBold, nounsItalic, newNounsUnderline, 
            adjectivesFilterOption, adjectivesColor, adjectivesSize, adjectivesBold, adjectivesItalic, newAdjectivesUnderline,
            adverbsFilterOption, adverbsColor, adverbsSize, adverbsBold, adverbsItalic, newAdverbsUnderline,
            verbsFilterOption, verbTenseOptions, verbsColor, verbsSize, verbsBold, verbsItalic, newVerbsUnderline,
            prepositionsFilterOption, prepositionsColor, prepositionsSize, prepositionsBold, prepositionsItalic, newPrepositionsUnderline,
            subConjunctionsFilterOption, subConjunctionsColor, subConjunctionsSize, subConjunctionsBold, subConjunctionsItalic, newSubConjunctionsUnderline,
            determinersFilterOption, determinersColor, determinersSize, determinersBold, determinersItalic, newDeterminersUnderline,
            pronounsFilterOption, pronounsColor, pronounsSize, pronounsBold, pronounsItalic, newPronounsUnderline,
            conjunctionsFilterOption, conjunctionsColor, conjunctionsSize, conjunctionsBold, conjunctionsItalic, newConjunctionsUnderline);
    }

    const colorOptions = (element: string, activeitem: string) => {
        return  <div className="color-selector">
            <span className={ 'color-item blue-violet' + (activeitem == 'blue-violet' ? ' active' : '') } 
                data-element={ element } data-color="blue-violet" onClick={ setColor }></span>
            <span className={ 'color-item brown' + (activeitem == 'brown' ? ' active' : '') } 
                data-element={ element } data-color="brown" onClick={ setColor }></span>
            <span className={ 'color-item coral' + (activeitem == 'coral' ? ' active' : '') } 
                data-element={ element } data-color="coral" onClick={ setColor }></span>
            <span className={ 'color-item dark-blue' + (activeitem == 'dark-blue' ? ' active' : '') }
                data-element={ element } data-color="dark-blue" onClick={ setColor }></span>
            <span className={ 'color-item dark-cyan' + (activeitem == 'dark-cyan' ? ' active' : '') }
                data-element={ element } data-color="dark-cyan" onClick={ setColor }></span>
            <span className={ 'color-item crimson' + (activeitem == 'crimson' ? ' active' : '') }
                data-element={ element } data-color="crimson" onClick={ setColor }></span>
            <span className={ 'color-item dark-green' + (activeitem == 'dark-green' ? ' active' : '') }
                data-element={ element } data-color="dark-green" onClick={ setColor }></span>
            <span className={ 'color-item dark-salmon' + (activeitem == 'dark-salmon' ? ' active' : '') }
                data-element={ element } data-color="dark-salmon" onClick={ setColor }></span>
            <span className={ 'color-item dark-orange' + (activeitem == 'dark-orange' ? ' active' : '') }
                data-element={ element } data-color="dark-orange" onClick={ setColor }></span>
            <span className={ 'color-item cadet-blue' + (activeitem == 'cadet-blue' ? ' active' : '') }
                data-element={ element } data-color="cadet-blue" onClick={ setColor }></span>
            <span className={ 'color-item chocolate' + (activeitem == 'chocolate' ? ' active' : '') }
                data-element={ element } data-color="chocolate" onClick={ setColor }></span>
            <span className={ 'color-item dark-magenta' + (activeitem == 'dark-magenta' ? ' active' : '') }
                data-element={ element } data-color="dark-magenta" onClick={ setColor }></span>
        </div>;
    }

    const sizeOptions = (element: string, activeitem: string) => {
        return  <div className="size-selector">
                <span className={ 'size-item normal' + (activeitem == 'normal' ? ' active' : '') }
                    data-element={ element } data-size="normal" onClick={ setSize }>{ 'A' }</span>
                <span className={ 'size-item big' + (activeitem == 'big' ? ' active' : '') }
                    data-element={ element } data-size="big" onClick={ setSize }>{ 'A' }</span>
                <span className={ 'size-item huge' + (activeitem == 'huge' ? ' active' : '') }
                    data-element={ element } data-size="huge" onClick={ setSize }>{ 'A' }</span>
            </div>;
    }

    function decodeColor(colorcode: string) {
        switch (colorcode) {
            case 'blue-violet':
                return 'blueviolet';
                break;
            case 'brown':
                return 'brown';
                break;
            case 'coral':
                return 'coral';
                break;
            case 'dark-blue':
                return 'darkblue';
                break;
            case 'dark-cyan':
                return 'darkcyan';
                break;
            case 'crimson':
                return 'crimson';
                break;
            case 'dark-green':
                return 'darkgreen';
                break;
            case 'dark-salmon':
                return 'darksalmon';
                break;  
            case 'dark-orange':
                return 'darkorange';
                break;
            case 'cadet-blue':
                return 'cadetblue';
                break; 
            case 'chocolate':
                return 'chocolate';
                break;     
            case 'dark-magenta':
                return 'darkmagenta';
                break; 
            default:
                return '';
        }
    }

    function decodeSize(sizecode: string) {
        switch (sizecode) {
            case 'big':
                return '1.4em';
                break;
            case 'huge':
                return '1.8em';
                break;
            default:
                return '';
        }
    }

    const formatText = (json: any,
        tmpPragmaticsFilterActive: boolean, tmpPragmaticsFilterOption: string, tmpPragmaticsColor: string, tmpPragmaticsSize: string, tmpPragmaticsBold: boolean, tmpPragmaticsItalic: boolean, tmpPragmaticsUnderline: boolean,
        tmpPhrasalVerbsFilterActive: boolean, tmpPhrasalVerbsColor: string, tmpPhrasalVerbsSize: string, tmpPhrasalVerbsBold: boolean, tmpPhrasalVerbsItalic: boolean, tmpPhrasalVerbsUnderline: boolean,
        tmpNounsFilterOption: string, tmpNounsColor: string, tmpNounsSize: string, tmpNounsBold: boolean, tmpNounsItalic: boolean, tmpNounsUnderline: boolean,
        tmpAdjectivesFilterOption: string, tmpAdjectivesColor: string, tmpAdjectivesSize: string, tmpAdjectivesBold: boolean, tmpAdjectivesItalic: boolean, tmpAdjectivesUnderline: boolean,
        tmpAdverbsFilterOption: string, tmpAdverbsColor: string, tmpAdverbsSize: string, tmpAdverbsBold: boolean, tmpAdverbsItalic: boolean, tmpAdverbsUnderline: boolean,
        tmpVerbsFilterOption: string, tmpVerbTenseOptions: any, tmpVerbsColor: string, tmpVerbsSize: string, tmpVerbsBold: boolean, tmpVerbsItalic: boolean, tmpVerbsUnderline: boolean,
        tmpPrepositionsFilterOption: string, tmpPrepositionsColor: string, tmpPrepositionsSize: string, tmpPrepositionsBold: boolean, tmpPrepositionsItalic: boolean, tmpPrepositionsUnderline: boolean,
        tmpSubConjunctionsFilterOption: string, tmpSubConjunctionsColor: string, tmpSubConjunctionsSize: string, tmpSubConjunctionsBold: boolean, tmpSubConjunctionsItalic: boolean, tmpSubConjunctionsUnderline: boolean,
        tmpDeterminersFilterOption: string, tmpDeterminersColor: string, tmpDeterminersSize: string, tmpDeterminersBold: boolean, tmpDeterminersItalic: boolean, tmpDeterminersUnderline: boolean,
        tmpPronounsFilterOption: string, tmpPronounsColor: string, tmpPronounsSize: string, tmpPronounsBold: boolean, tmpPronounsItalic: boolean, tmpPronounsUnderline: boolean,
        tmpConjunctionsFilterOption: string, tmpConjunctionsColor: string, tmpConjunctionsSize: string, tmpConjunctionsBold: boolean, tmpConjunctionsItalic: boolean, tmpConjunctionsUnderline: boolean) => {
        let resultAllText = '';
        if (json.length > 0) {
            let applyFormatToPos = false;
            let paragraphNum = json[0].paragraph;
            let sentenceNum = json[0].sentence;

            resultAllText = '<p>';
            let prevEndChar = 0;
            for (let i = 0; i < json.length; i++) {
                if (parseInt(json[i].sentence) != parseInt(sentenceNum)) {
                    resultAllText += ' ';
                    sentenceNum = json[i].sentence;
                }
                if (parseInt(json[i].paragraph) != parseInt(paragraphNum)) {
                    resultAllText += '</p><p>';
                    paragraphNum = json[i].paragraph;
                }
                if (prevEndChar != 0 && (prevEndChar < parseInt(json[i].start_char))) {
                    resultAllText += ' ';
                }
                let style = '';
                let classProps = '';
                applyFormatToPos = false;
                if (tmpPragmaticsFilterActive) {
                    if (json[i].pragmatics != '-') {
                        switch (tmpPragmaticsFilterOption) {
                            case 'suggesting':
                                applyFormatToPos = (json[i].pragmatics == 'Suggestion_Direct' ||
                                    json[i].pragmatics == 'Suggestion_Conventionalised' ||
                                    json[i].pragmatics == 'Suggestion_Indirect');
                                break;
                            case 'thanking':
                                applyFormatToPos = (json[i].pragmatics == 'Thanking_Informal' ||
                                    json[i].pragmatics == 'Thanking_Conventionalised' ||
                                    json[i].pragmatics == 'Thanking_Formal');
                                break;
                            case 'requesting':
                                applyFormatToPos = (json[i].pragmatics == 'Request_Direct' ||
                                    json[i].pragmatics == 'Request_Conventionalised' ||
                                    json[i].pragmatics == 'Request_Indirect');
                                break;
                            case 'agreeing-disagreeing':
                                applyFormatToPos = (json[i].pragmatics == 'Agreeing/Disagreeing_Informal' ||
                                    json[i].pragmatics == 'Agreeing/Disagreeing_Formal');
                                break;
                            case 'showing-interest':
                                applyFormatToPos = (json[i].pragmatics == 'Showing_Interest_Informal' ||
                                    json[i].pragmatics == 'Showing_Interest_Formal');
                                break;
                            case 'apologising':
                                applyFormatToPos = (json[i].pragmatics == 'Apologising_Conventionalised' ||
                                    json[i].pragmatics == 'Apologising_Informal' ||
                                    json[i].pragmatics == 'Apologising_Formal');
                                break;
                            case 'giving-an-opinion':
                                applyFormatToPos = (json[i].pragmatics == 'Giving_Opinion_Conventionalised' ||
                                    json[i].pragmatics == 'Giving_Opinion_Formal');
                                break;
                            case 'greeting':
                                applyFormatToPos = (json[i].pragmatics == 'Greeting_Informal' ||
                                    json[i].pragmatics == 'Greeting_Conventionalised' ||
                                    json[i].pragmatics == 'Greeting_Formal');
                                break;
                            default:
                                applyFormatToPos = true;
                                break;
                        }
                        if (applyFormatToPos) {
                            if (tmpPragmaticsColor) {
                                style += 'color:' + decodeColor(tmpPragmaticsColor) + ';';
                            }
                            if (tmpPragmaticsSize) {
                                style += 'font-size:' + decodeSize(tmpPragmaticsSize) + ';';
                                classProps = 'text-' + tmpPragmaticsSize;
                            }
                            if (tmpPragmaticsBold) {
                                style += 'font-weight: bold;';
                            }
                            if (tmpPragmaticsItalic) {
                                style += 'font-style: italic;';
                            }
                            if (tmpPragmaticsUnderline) {
                                style += 'text-decoration: underline;';
                            }
                        }
                        resultAllText += '<span style="' + style + '">' + json[i].word + '</span>';
                    } else {
                        resultAllText += json[i].word;
                    }
                } else if (tmpPhrasalVerbsFilterActive) {
                    if (json[i].phrasalverb == 'PhrV') {
                        applyFormatToPos = true;
                        if (applyFormatToPos) {
                            if (tmpPhrasalVerbsColor) {
                                style += 'color:' + decodeColor(tmpPhrasalVerbsColor) + ';';
                            }
                            if (tmpPhrasalVerbsSize) {
                                style += 'font-size:' + decodeSize(tmpPhrasalVerbsSize) + ';';
                                classProps = 'text-' + tmpPhrasalVerbsSize;
                            }
                            if (tmpPhrasalVerbsBold) {
                                style += 'font-weight: bold;';
                            }
                            if (tmpPhrasalVerbsItalic) {
                                style += 'font-style: italic;';
                            }
                            if (tmpPhrasalVerbsUnderline) {
                                style += 'text-decoration: underline;';
                            }
                        }
                        resultAllText += '<span style="' + style + '">' + json[i].word + '</span>';
                    } else {
                        resultAllText += json[i].word;
                    }
                } else {
                    switch (json[i].xpos) {
                        case 'NN':
                        case 'NNP':
                        case 'NNS':
                        case 'NNPS':
                        case 'IrrPl':
                            if (tmpNounsFilterOption == 'all') {
                                applyFormatToPos = true;
                            } else {
                                if (json[i].irregularform == 'IrrPl') {
                                    applyFormatToPos = true;
                                }
                            }
                            if (applyFormatToPos) {
                                if (tmpNounsColor) {
                                    style += 'color:' + decodeColor(tmpNounsColor) + ';';
                                }
                                if (tmpNounsSize) {
                                    style += 'font-size:' + decodeSize(tmpNounsSize) + ';';
                                    classProps = 'text-' + tmpNounsSize;
                                }
                                if (tmpNounsBold) {
                                    style += 'font-weight: bold;';
                                }
                                if (tmpNounsItalic) {
                                    style += 'font-style: italic;';
                                }
                                if (tmpNounsUnderline) {
                                    style += 'text-decoration: underline;';
                                }
                                resultAllText += '<span class="' + classProps + '" style="' + style + '">' + json[i].word + '</span>';
                            } else {
                                resultAllText += json[i].word;
                            }
                            break;
                        case 'JJ':
                        case 'JJR':
                        case 'JJS':
                        case 'JJR':
                        case 'JJS':
                            switch (tmpAdjectivesFilterOption) {
                                case 'adjectives-superlative':
                                    if (json[i].xpos == 'JJS') {
                                        applyFormatToPos = true;
                                    }
                                    break;
                                case 'adjectives-comparative':
                                    if (json[i].xpos == 'JJR') {
                                        applyFormatToPos = true;
                                    }
                                    break;
                                case 'all':
                                    applyFormatToPos = true;
                                    break;

                            }
                            if (applyFormatToPos) {
                                if (tmpAdjectivesColor) {
                                    style += 'color:' + decodeColor(tmpAdjectivesColor) + ';';
                                }
                                if (tmpAdjectivesSize) {
                                    style += 'font-size:' + decodeSize(tmpAdjectivesSize) + ';';
                                    classProps = 'text-' + tmpAdjectivesSize;
                                }
                                if (tmpAdjectivesBold) {
                                    style += 'font-weight: bold;';
                                }
                                if (tmpAdjectivesItalic) {
                                    style += 'font-style: italic;';
                                }
                                if (tmpAdjectivesUnderline) {
                                    style += 'text-decoration: underline;';
                                }
                                resultAllText += '<span class="' + classProps + '" style="' + style + '">' + json[i].word + '</span>';
                            } else {
                                resultAllText += json[i].word;
                            }
                            break;
                        case 'RB':
                        case 'RBR':
                        case 'RBS':
                        case 'WRB':
                            switch (tmpAdverbsFilterOption) {
                                case 'adverbs-comparative':
                                    if (json[i].xpos == 'RBR') {
                                        applyFormatToPos = true;
                                    }
                                    break;
                                case 'adverbs-superlative':
                                    if (json[i].xpos == 'RBS') {
                                        applyFormatToPos = true;
                                    }
                                    break;
                                case 'adverbs-wh':
                                    if (json[i].xpos == 'WRB') {
                                        applyFormatToPos = true;
                                    }
                                    break;
                                case 'all':
                                    applyFormatToPos = true;
                                    break;

                            }
                            if (applyFormatToPos) {
                                if (tmpAdverbsColor) {
                                    style += 'color:' + decodeColor(tmpAdverbsColor) + ';';
                                }
                                if (tmpAdverbsSize) {
                                    style += 'font-size:' + decodeSize(tmpAdverbsSize) + ';';
                                    classProps = 'text-' + tmpAdverbsSize;
                                }
                                if (tmpAdverbsBold) {
                                    style += 'font-weight: bold;';
                                }
                                if (tmpAdverbsItalic) {
                                    style += 'font-style: italic;';
                                }
                                if (tmpAdverbsUnderline) {
                                    style += 'text-decoration: underline;';
                                }
                                resultAllText += '<span class="' + classProps + '" style="' + style + '">' + json[i].word + '</span>';
                            } else {
                                resultAllText += json[i].word;
                            }
                            break;
                        case 'VB':
                        case 'VBZ':
                        case 'VBD':
                        case 'VBN':
                        case 'MD':
                        case 'VBG':
                        case 'VBP':
                            switch (tmpVerbsFilterOption) {
                                case 'verbs-irregular':
                                    if (json[i].irregularform == 'IrrV') {
                                        applyFormatToPos = true;
                                    }
                                    break;
                                case 'verbs-tense':
                                    if (['Present_Simple', 'Present_Continuous', 'Present_Perfect',
                                        'Present_Perfect_Continuous', 'Past_Simple', 'Past_Continuous',
                                        'Past_Perfect', 'Past_Perfect_Continuous', 'Future_Be_Going_To',
                                        'Future_Simple', 'Future_Perfect', 'Future_Perfect_Continuous',
                                        'Present_Simple_Passive', 'Present_Continuous_Passive', 'Present_Perfect_Passive',
                                        'Present_Perfect_Continuous_Passive', 'Past_Simple_Passive', 'Past_Continuous_Passive',
                                        'Past_Perfect_Passive', 'Past_Perfect_Continuous_Passive', 'Future_Simple_Passive',
                                        'Future_Perfect_Passive'].includes(json[i].tense)) {
                                        // applyFormatToPos = true;
                                        applyFormatToPos = (json[i].tense == 'Present_Simple' && tmpVerbTenseOptions.Present_Simple) ||
                                            (json[i].tense == 'Present_Continuous' && tmpVerbTenseOptions.Present_Continuous) ||
                                            (json[i].tense == 'Present_Perfect' && tmpVerbTenseOptions.Present_Perfect) ||
                                            (json[i].tense == 'Present_Perfect_Continuous' && tmpVerbTenseOptions.Present_Perfect_Continuous) ||
                                            (json[i].tense == 'Past_Simple' && tmpVerbTenseOptions.Past_Simple) ||
                                            (json[i].tense == 'Past_Continuous' && tmpVerbTenseOptions.Past_Continuous) ||
                                            (json[i].tense == 'Past_Perfect' && tmpVerbTenseOptions.Past_Perfect) ||
                                            (json[i].tense == 'Past_Perfect_Continuous' && tmpVerbTenseOptions.Past_Perfect_Continuous) ||
                                            (json[i].tense == 'Future_Be_Going_To' && tmpVerbTenseOptions.Future_Be_Going_To) ||
                                            (json[i].tense == 'Future_Simple' && tmpVerbTenseOptions.Future_Simple) ||
                                            (json[i].tense == 'Future_Perfect' && tmpVerbTenseOptions.Future_Perfect) ||
                                            (json[i].tense == 'Future_Perfect_Continuous' && tmpVerbTenseOptions.Future_Perfect_Continuous) ||
                                            (json[i].tense == 'Present_Simple_Passive' && tmpVerbTenseOptions.Present_Simple_Passive) ||
                                            (json[i].tense == 'Present_Continuous_Passive' && tmpVerbTenseOptions.Present_Continuous_Passive) ||
                                            (json[i].tense == 'Present_Perfect_Passive' && tmpVerbTenseOptions.Present_Perfect_Passive) ||
                                            (json[i].tense == 'Present_Perfect_Continuous_Passive' && tmpVerbTenseOptions.Present_Perfect_Continuous_Passive) ||
                                            (json[i].tense == 'Past_Simple_Passive' && tmpVerbTenseOptions.Past_Simple_Passive) ||
                                            (json[i].tense == 'Past_Continuous_Passive' && tmpVerbTenseOptions.Past_Continuous_Passive) ||
                                            (json[i].tense == 'Past_Perfect_Passive' && tmpVerbTenseOptions.Past_Perfect_Passive) ||
                                            (json[i].tense == 'Past_Perfect_Continuous_Passive' && tmpVerbTenseOptions.Past_Perfect_Continuous_Passive) ||
                                            (json[i].tense == 'Future_Simple_Passive' && tmpVerbTenseOptions.Future_Simple_Passive) ||
                                            (json[i].tense == 'Future_Perfect_Passive' && tmpVerbTenseOptions.Future_Perfect_Passive);
                                    }
                                    break;
                                case 'verbs-modal':
                                    if (['Modal_Verb', 'Modal_Perfect', 'Modal_Passive', 'Modal_Perfect_Passive'].includes(json[i].tense)) {
                                        applyFormatToPos = true;
                                    }
                                    break;
                                case 'verbs-imperative':
                                    if (json[i].tense == 'Imperative') {
                                        applyFormatToPos = true;
                                    }
                                    break;
                                case 'verbs-phrasal':
                                    if (json[i].phrasalverb == 'PhrV') {
                                        applyFormatToPos = true;
                                    }
                                    break;
                                case 'all':
                                    applyFormatToPos = true;
                                    break;

                            }
                            if (applyFormatToPos) {
                                if (tmpVerbsColor) {
                                    style += 'color:' + decodeColor(tmpVerbsColor) + ';';
                                }
                                if (tmpVerbsSize) {
                                    style += 'font-size:' + decodeSize(tmpVerbsSize) + ';';
                                    classProps = 'text-' + tmpVerbsSize;
                                }
                                if (tmpVerbsBold) {
                                    style += 'font-weight: bold;';
                                }
                                if (tmpVerbsItalic) {
                                    style += 'font-style: italic;';
                                }
                                if (tmpVerbsUnderline) {
                                    style += 'text-decoration: underline;';
                                }
                                resultAllText += '<span class="' + classProps + '" style="' + style + '">' + json[i].word + '</span>';
                            } else {
                                resultAllText += json[i].word;
                            }
                            break;
                        case 'IN':
                            if (json[i].upos == 'ADP') {
                                applyFormatToPos = true;
                                if (applyFormatToPos) {
                                    if (tmpPrepositionsColor) {
                                        style += 'color:' + decodeColor(tmpPrepositionsColor) + ';';
                                    }
                                    if (tmpPrepositionsSize) {
                                        style += 'font-size:' + decodeSize(tmpPrepositionsSize) + ';';
                                        classProps = 'text-' + tmpPrepositionsSize;
                                    }
                                    if (tmpPrepositionsBold) {
                                        style += 'font-weight: bold;';
                                    }
                                    if (tmpPrepositionsItalic) {
                                        style += 'font-style: italic;';
                                    }
                                    if (tmpPrepositionsUnderline) {
                                        style += 'text-decoration: underline;';
                                    }
                                    resultAllText += '<span class="' + classProps + '" style="' + style + '">' + json[i].word + '</span>';
                                } else {
                                    resultAllText += json[i].word;
                                }
                            }
                            if (json[i].upos == 'SCONJ') {
                                applyFormatToPos = true;
                                if (applyFormatToPos) {
                                    if (tmpSubConjunctionsColor) {
                                        style += 'color:' + decodeColor(tmpSubConjunctionsColor) + ';';
                                    }
                                    if (tmpSubConjunctionsSize) {
                                        style += 'font-size:' + decodeSize(tmpSubConjunctionsSize) + ';';
                                        classProps = 'text-' + tmpSubConjunctionsSize;
                                    }
                                    if (tmpSubConjunctionsBold) {
                                        style += 'font-weight: bold;';
                                    }
                                    if (tmpSubConjunctionsItalic) {
                                        style += 'font-style: italic;';
                                    }
                                    if (tmpSubConjunctionsUnderline) {
                                        style += 'text-decoration: underline;';
                                    }
                                    resultAllText += '<span class="' + classProps + '" style="' + style + '">' + json[i].word + '</span>';
                                } else {
                                    resultAllText += json[i].word;
                                }
                            }
                            break;     
                        case 'DT':
                        case 'PDT':
                        case 'WDT':
                            switch (tmpDeterminersFilterOption) {
                                case 'determiners-determiner':
                                    if (['DT', 'PDT'].includes(json[i].xpos)) {
                                        applyFormatToPos = true;
                                    }
                                    break;
                                case 'determiners-wh':
                                    if (json[i].xpos == 'WDT') {
                                        applyFormatToPos = true;
                                    }
                                    break;
                                case 'all':
                                    applyFormatToPos = true;
                                    break;

                            }
                            if (applyFormatToPos) {
                                if (tmpDeterminersColor) {
                                    style += 'color:' + decodeColor(tmpDeterminersColor) + ';';
                                }
                                if (tmpDeterminersSize) {
                                    style += 'font-size:' + decodeSize(tmpDeterminersSize) + ';';
                                    classProps = 'text-' + tmpDeterminersSize;
                                }
                                if (tmpDeterminersBold) {
                                    style += 'font-weight: bold;';
                                }
                                if (tmpDeterminersItalic) {
                                    style += 'font-style: italic;';
                                }
                                if (tmpDeterminersUnderline) {
                                    style += 'text-decoration: underline;';
                                }
                                resultAllText += '<span class="' + classProps + '" style="' + style + '">' + json[i].word + '</span>';
                            } else {
                                resultAllText += json[i].word;
                            }
                            break; 
                        case 'PRP':
                        case 'PRP$':
                        case 'WP$':
                        case 'WP':
                            switch (tmpPronounsFilterOption) {
                                case 'pronouns-personal':
                                    if (json[i].xpos == 'PRP') {
                                        applyFormatToPos = true;
                                    }
                                    break;
                                case 'pronouns-possessive':
                                    if (json[i].xpos == 'PRP$') {
                                        applyFormatToPos = true;
                                    }
                                    break;
                                case 'pronouns-possessive-wh':
                                    if (json[i].xpos == 'WP$') {
                                        applyFormatToPos = true;
                                    }
                                    break;
                                case 'pronouns-wh':
                                    if (json[i].xpos == 'WP') {
                                        applyFormatToPos = true;
                                    }
                                    break;
                                case 'all':
                                    applyFormatToPos = true;
                                    break;

                            }
                            if (applyFormatToPos) {
                                if (tmpPronounsColor) {
                                    style += 'color:' + decodeColor(tmpPronounsColor) + ';';
                                }
                                if (tmpPronounsSize) {
                                    style += 'font-size:' + decodeSize(tmpPronounsSize) + ';';
                                    classProps = 'text-' + tmpPronounsSize;
                                }
                                if (tmpPronounsBold) {
                                    style += 'font-weight: bold;';
                                }
                                if (tmpPronounsItalic) {
                                    style += 'font-style: italic;';
                                }
                                if (tmpPronounsUnderline) {
                                    style += 'text-decoration: underline;';
                                }
                                resultAllText += '<span class="' + classProps + '" style="' + style + '">' + json[i].word + '</span>';
                            } else {
                                resultAllText += json[i].word;
                            }
                            break;
                        case 'CC':
                            applyFormatToPos = true;
                            if (applyFormatToPos) {
                                if (tmpConjunctionsColor) {
                                    style += 'color:' + decodeColor(tmpConjunctionsColor) + ';';
                                }
                                if (tmpConjunctionsSize) {
                                    style += 'font-size:' + decodeSize(tmpConjunctionsSize) + ';';
                                    classProps = 'text-' + tmpConjunctionsSize;
                                }
                                if (tmpConjunctionsBold) {
                                    style += 'font-weight: bold;';
                                }
                                if (tmpConjunctionsItalic) {
                                    style += 'font-style: italic;';
                                }
                                if (tmpConjunctionsUnderline) {
                                    style += 'text-decoration: underline;';
                                }
                                resultAllText += '<span class="' + classProps + '" style="' + style + '">' + json[i].word + '</span>';
                            } else {
                                resultAllText += json[i].word;
                            }
                            break;       
                        default:
                            resultAllText += json[i].word;
                    }
                }
                prevEndChar = json[i].end_char;
            }
            resultAllText += '</p>';
        }
        setAllText(resultAllText);
        props.saveFormattedTextCallback(resultAllText);
    }

    const getTenseFrequency = (tenses: any, t: string) => {
        let value = 0;
        const index = tenses.findIndex((tense: any) => tense.tense == t);
        if (index !== -1) {
            value = tenses[index].frequency;
        }

        return value;
    }

    const getPragmaticFrequency = (pragmatics: any, p: string) => {
        let value = 0;
        const index = pragmatics.findIndex((pragmatic: any) => pragmatic.pragmatics == p);
        if (index !== -1) {
            value = pragmatics[index].frequency;
        }

        return value;
    }

    const getPhrasalFrequency = (phrasals: any, f: string) => {
        let value = 0;
        const index = phrasals.findIndex((phrasal: any) => phrasal.phrasalverbs == f);
        if (index !== -1) {
            value = phrasals[index].frequency;
        }

        return value;
    }

    useEffect(() => {
        if (props.analyzedText.main && props.analyzedText.main.length == 0 && 
            props.analyzedText.countPhrasals && props.analyzedText.countPhrasals.length == 0 && 
            props.analyzedText.countTense && props.analyzedText.countTense.length == 0 && 
            props.analyzedText.countPragmatics && props.analyzedText.countPragmatics.length == 0) {
            setProcessingEmpty(true);
        } else {
            setProcessingEmpty(false);
            const newNlpCategories = {
                pragmaticsAll: getPragmaticFrequency(props.analyzedText.countPragmatics, 'Suggestion_Direct') + 
                    getPragmaticFrequency(props.analyzedText.countPragmatics, 'Suggestion_Conventionalised') + 
                    getPragmaticFrequency(props.analyzedText.countPragmatics, 'Suggestion_Indirect') +
                    getPragmaticFrequency(props.analyzedText.countPragmatics, 'Thanking_Informal') + 
                    getPragmaticFrequency(props.analyzedText.countPragmatics, 'Thanking_Conventionalised') + 
                    getPragmaticFrequency(props.analyzedText.countPragmatics, 'Thanking_Formal') +
                    getPragmaticFrequency(props.analyzedText.countPragmatics, 'Request_Direct') +
                    getPragmaticFrequency(props.analyzedText.countPragmatics, 'Request_Conventionalised') +
                    getPragmaticFrequency(props.analyzedText.countPragmatics, 'Request_Indirect') +
                    getPragmaticFrequency(props.analyzedText.countPragmatics, 'Agreeing/Disagreeing_Informal') +
                    getPragmaticFrequency(props.analyzedText.countPragmatics, 'Agreeing/Disagreeing_Formal') +
                    getPragmaticFrequency(props.analyzedText.countPragmatics, 'Showing_Interest_Informal') +
                    getPragmaticFrequency(props.analyzedText.countPragmatics, 'Showing_Interest_Formal') +
                    getPragmaticFrequency(props.analyzedText.countPragmatics, 'Apologising_Conventionalised') + 
                    getPragmaticFrequency(props.analyzedText.countPragmatics, 'Apologising_Informal') + 
                    getPragmaticFrequency(props.analyzedText.countPragmatics, 'Apologising_Formal') +
                    getPragmaticFrequency(props.analyzedText.countPragmatics, 'Giving_Opinion_Conventionalised') + 
                    getPragmaticFrequency(props.analyzedText.countPragmatics, 'Giving_Opinion_Formal') +
                    getPragmaticFrequency(props.analyzedText.countPragmatics, 'Greeting_Informal') +
                    getPragmaticFrequency(props.analyzedText.countPragmatics, 'Greeting_Conventionalised') + 
                    getPragmaticFrequency(props.analyzedText.countPragmatics, 'Greeting_Formal'),
                pragmaticsSuggesting: getPragmaticFrequency(props.analyzedText.countPragmatics, 'Suggestion_Direct') + getPragmaticFrequency(props.analyzedText.countPragmatics, 'Suggestion_Conventionalised') + getPragmaticFrequency(props.analyzedText.countPragmatics, 'Suggestion_Indirect'),
                pragmaticsThanking: getPragmaticFrequency(props.analyzedText.countPragmatics, 'Thanking_Informal') + getPragmaticFrequency(props.analyzedText.countPragmatics, 'Thanking_Conventionalised') + getPragmaticFrequency(props.analyzedText.countPragmatics, 'Thanking_Formal'),
                pragmaticsRequesting: getPragmaticFrequency(props.analyzedText.countPragmatics, 'Request_Direct') + getPragmaticFrequency(props.analyzedText.countPragmatics, 'Request_Conventionalised') + getPragmaticFrequency(props.analyzedText.countPragmatics, 'Request_Indirect'),
                pragmaticsAgreeingDisagreeing: getPragmaticFrequency(props.analyzedText.countPragmatics, 'Agreeing/Disagreeing_Informal') + getPragmaticFrequency(props.analyzedText.countPragmatics, 'Agreeing/Disagreeing_Formal'),
                pragmaticsShowingInterest: getPragmaticFrequency(props.analyzedText.countPragmatics, 'Showing_Interest_Informal') + getPragmaticFrequency(props.analyzedText.countPragmatics, 'Showing_Interest_Formal'),
                pragmaticsApologising: getPragmaticFrequency(props.analyzedText.countPragmatics, 'Apologising_Conventionalised') + getPragmaticFrequency(props.analyzedText.countPragmatics, 'Apologising_Informal') + getPragmaticFrequency(props.analyzedText.countPragmatics, 'Apologising_Formal'),
                pragmaticsGivingAnOpinion: getPragmaticFrequency(props.analyzedText.countPragmatics, 'Giving_Opinion_Conventionalised') + getPragmaticFrequency(props.analyzedText.countPragmatics, 'Giving_Opinion_Formal'),
                pragmaticsGreeting: getPragmaticFrequency(props.analyzedText.countPragmatics, 'Greeting_Informal') + getPragmaticFrequency(props.analyzedText.countPragmatics, 'Greeting_Conventionalised') + getPragmaticFrequency(props.analyzedText.countPragmatics, 'Greeting_Formal'),
                phrasalVerbs: getPhrasalFrequency(props.analyzedText.countPhrasals, 'PhrV'),
                nounsAll: props.analyzedText.main.filter((c: any) => ['NN', 'NNP', 'NNS', 'NNPS', 'IrrPl'].includes(c.xpos)).length,
                nounsIrregular: props.analyzedText.main.filter((c: any) => 'IrrPl' == c.xpos).length,
                adjectivesAll: props.analyzedText.main.filter((c: any) => ['JJ', 'JJR', 'JJS', 'JJR', 'JJS'].includes(c.xpos)).length,
                adjectivesSuperlative: props.analyzedText.main.filter((c: any) => 'JJS' == c.xpos).length,
                adjectivesComparative: props.analyzedText.main.filter((c: any) => 'JJR' == c.xpos).length,
                adverbsAll: props.analyzedText.main.filter((c: any) => ['RB', 'RBR', 'RBS', 'WRB'].includes(c.xpos)).length,
                adverbsComparative: props.analyzedText.main.filter((c: any) => 'JJR' == c.xpos).length,
                adverbsSuperlative: props.analyzedText.main.filter((c: any) => 'RBR' == c.xpos).length,
                adverbsWh: props.analyzedText.main.filter((c: any) => 'WRB' == c.xpos).length,
                verbsAll: props.analyzedText.main.filter((c: any) => ['VB', 'VBZ', 'VBD', 'VBN', 'MD', 'VBG', 'VBP'].includes(c.xpos)).length,
                verbsIrregular: props.analyzedText.main.filter((c: any) => ['VB', 'VBZ', 'VBD', 'VBN', 'MD', 'VBG', 'VPB'].includes(c.xpos) && c.irregularform == 'IrrV').length,
                verbsTenseAll: getTenseFrequency(props.analyzedText.countTense, 'Present Simple') +
                    getTenseFrequency(props.analyzedText.countTense, 'Present Continuous') +
                    getTenseFrequency(props.analyzedText.countTense, 'Present Perfect') +
                    getTenseFrequency(props.analyzedText.countTense, 'Present Perfect Continuous') +
                    getTenseFrequency(props.analyzedText.countTense, 'Past Simple') +
                    getTenseFrequency(props.analyzedText.countTense, 'Past Continuous') +
                    getTenseFrequency(props.analyzedText.countTense, 'Past Perfect') +
                    getTenseFrequency(props.analyzedText.countTense, 'Past Perfect Continuous') +
                    getTenseFrequency(props.analyzedText.countTense, 'Future Be Going To') +
                    getTenseFrequency(props.analyzedText.countTense, 'Future Simple') +
                    getTenseFrequency(props.analyzedText.countTense, 'Future Perfect') +
                    getTenseFrequency(props.analyzedText.countTense, 'Future Perfect Continuous') +
                    getTenseFrequency(props.analyzedText.countTense, 'Present Simple Passive') +
                    getTenseFrequency(props.analyzedText.countTense, 'Present Continuous Passive') +
                    getTenseFrequency(props.analyzedText.countTense, 'Present Perfect Passive') +
                    getTenseFrequency(props.analyzedText.countTense, 'Present Perfect Continuous Passive') +
                    getTenseFrequency(props.analyzedText.countTense, 'Past Simple Passive') +
                    getTenseFrequency(props.analyzedText.countTense, 'Past Continuous Passive') +
                    getTenseFrequency(props.analyzedText.countTense, 'Past Perfect Passive') +
                    getTenseFrequency(props.analyzedText.countTense, 'Past Perfect Continuous Passive') +
                    getTenseFrequency(props.analyzedText.countTense, 'Future Simple Passive') +
                    getTenseFrequency(props.analyzedText.countTense, 'Future Perfect Passive') +
                    getTenseFrequency(props.analyzedText.countTense, 'Modal Verb') +
                    getTenseFrequency(props.analyzedText.countTense, 'Modal Perfect') +
                    getTenseFrequency(props.analyzedText.countTense, 'Modal Passive') + 
                    getTenseFrequency(props.analyzedText.countTense, 'Modal Perfect Passive') +
                    getTenseFrequency(props.analyzedText.countTense, 'Imperative'),
                verbsTensePresentSimple: getTenseFrequency(props.analyzedText.countTense, 'Present Simple'),
                verbsTensePresentContinuous: getTenseFrequency(props.analyzedText.countTense, 'Present Continuous'),
                verbsTensePresentPerfect: getTenseFrequency(props.analyzedText.countTense, 'Present Perfect'),
                verbsTensePresentPerfectContinuous: getTenseFrequency(props.analyzedText.countTense, 'Present Perfect Continuous'),
                verbsTensePastSimple: getTenseFrequency(props.analyzedText.countTense, 'Past Simple'),
                verbsTensePastContinuous: getTenseFrequency(props.analyzedText.countTense, 'Past Continuous'),
                verbsTensePastPerfect: getTenseFrequency(props.analyzedText.countTense, 'Past Perfect'),
                verbsTensePastPerfectContinuous: getTenseFrequency(props.analyzedText.countTense, 'Past Perfect Continuous'),
                verbsTenseFutureBeGoingTo: getTenseFrequency(props.analyzedText.countTense, 'Future Be Going To'),
                verbsTenseFutureSimple: getTenseFrequency(props.analyzedText.countTense, 'Future Simple'),
                verbsTenseFuturePerfect: getTenseFrequency(props.analyzedText.countTense, 'Future Perfect'),
                verbsTenseFuturePerfectContinuous: getTenseFrequency(props.analyzedText.countTense, 'Future Perfect Continuous'),
                verbsTensePresentSimplePassive: getTenseFrequency(props.analyzedText.countTense, 'Present Simple Passive'),
                verbsTensePresentContinuousPassive: getTenseFrequency(props.analyzedText.countTense, 'Present Continuous Passive'),
                verbsTensePresentPerfectPassive: getTenseFrequency(props.analyzedText.countTense, 'Present Perfect Passive'),
                verbsTensePresentPerfectContinuousPassive: getTenseFrequency(props.analyzedText.countTense, 'Present Perfect Continuous Passive'),
                verbsTensePastSimplePassive: getTenseFrequency(props.analyzedText.countTense, 'Past Simple Passive'),
                verbsTensePastContinuousPassive: getTenseFrequency(props.analyzedText.countTense, 'Past Continuous Passive'),
                verbsTensePastPerfectPassive: getTenseFrequency(props.analyzedText.countTense, 'Past Perfect Passive'),
                verbsTensePastPerfectContinuousPassive: getTenseFrequency(props.analyzedText.countTense, 'Past Perfect Continuous Passive'),
                verbsTenseFutureSimplePassive: getTenseFrequency(props.analyzedText.countTense, 'Future Simple Passive'),
                verbsTenseFuturePerfectPassive: getTenseFrequency(props.analyzedText.countTense, 'Future Perfect Passive'),
                verbsTenseModal: getTenseFrequency(props.analyzedText.countTense, 'Modal Verb') + getTenseFrequency(props.analyzedText.countTense, 'Modal Perfect') + getTenseFrequency(props.analyzedText.countTense, 'Modal Passive') + getTenseFrequency(props.analyzedText.countTense, 'Modal Perfect Passive'),
                verbsTenseImperative: getTenseFrequency(props.analyzedText.countTense, 'Imperative'),
                prepositionsAll: props.analyzedText.main.filter((c: any) => 'IN' == c.xpos && c.upos == 'ADP').length,
                subConjunctionsAll: props.analyzedText.main.filter((c: any) => 'IN' == c.xpos && c.upos == 'SCONJ').length,
                determinersAll: props.analyzedText.main.filter((c: any) => ['DT', 'PDT', 'WDT'].includes(c.xpos)).length,
                determinersDeterminer: props.analyzedText.main.filter((c: any) => ['DT', 'PDT'].includes(c.xpos)).length,
                determinersWh: props.analyzedText.main.filter((c: any) => 'WDT' == c.xpos).length,
                pronounsAll: props.analyzedText.main.filter((c: any) => ['PRP', 'PRP$', 'WP$', 'WP'].includes(c.xpos)).length,
                pronounsPersonal: props.analyzedText.main.filter((c: any) => 'PRP' == c.xpos).length,
                pronounsPossessive: props.analyzedText.main.filter((c: any) => 'PRP$' == c.xpos).length,
                pronounsPossessiveWh: props.analyzedText.main.filter((c: any) => 'WP$' == c.xpos).length,
                pronounsWh: props.analyzedText.main.filter((c: any) => 'WP' == c.xpos).length,
                conjunctionsAll: props.analyzedText.main.filter((c: any) => 'CC' == c.xpos).length,
            };
            setNlpCategories(newNlpCategories);

            formatText(props.analyzedText.main,
                pragmaticsFilterActive, pragmaticsFilterOption, pragmaticsColor, pragmaticsSize, pragmaticsBold, pragmaticsItalic, pragmaticsUnderline,
                phrasalVerbsFilterActive, phrasalVerbsColor, phrasalVerbsSize, phrasalVerbsBold, phrasalVerbsItalic, phrasalVerbsUnderline,
                nounsFilterOption, nounsColor, nounsSize, nounsBold, nounsItalic, nounsUnderline,
                adjectivesFilterOption, adjectivesColor, adjectivesSize, adjectivesBold, adjectivesItalic, adjectivesUnderline,
                adverbsFilterOption, adverbsColor, adverbsSize, adverbsBold, adverbsItalic, adverbsUnderline,
                verbsFilterOption, verbTenseOptions, verbsColor, verbsSize, verbsBold, verbsItalic, verbsUnderline,
                prepositionsFilterOption, prepositionsColor, prepositionsSize, prepositionsBold, prepositionsItalic, prepositionsUnderline,
                subConjunctionsFilterOption, subConjunctionsColor, subConjunctionsSize, subConjunctionsBold, subConjunctionsItalic, subConjunctionsUnderline,
                determinersFilterOption, determinersColor, determinersSize, determinersBold, determinersItalic, determinersUnderline,
                pronounsFilterOption, pronounsColor, pronounsSize, pronounsBold, pronounsItalic, pronounsUnderline,
                conjunctionsFilterOption, conjunctionsColor, conjunctionsSize, conjunctionsBold, conjunctionsItalic, conjunctionsUnderline);
        }
    }, []);

    return (
        <div className="processed-text-container">
            { processingEmpty && 
                <div className="empty-processed-text">
                    <Alert key={ 'warning' } variant={ 'warning' }>
                        { 'Sorry! The result of the text analysis is either empty or some errors were found. Revise and change your text and try it again.'}
                    </Alert>
                </div>
            }
            { !processingEmpty &&
                <div className="text-filters">
                    <Accordion>
                        <Accordion.Item eventKey="0">
                            <Accordion.Header>{ 'Pragmatic Expressions' }</Accordion.Header>
                            <Accordion.Body>
                                <div className="word-category">
                                    <div className={ 'word-category-switch' + (pragmaticsFilterActive ? ' active' : '')}>
                                        <FormCheck name="pragmaticExpressions" type="checkbox" label={ 'Pragmatic Expressions' + ' (' + nlpCategories.pragmaticsAll + ')'}
                                            onChange={ handleChangePragmaticExpressions } checked={ pragmaticsFilterActive } />
                                    </div>
                                    { pragmaticsFilterActive && 
                                        <div className="options">
                                            <div className="format-options">
                                                { colorOptions('pragmatic', pragmaticsColor) }
                                                { sizeOptions('pragmatic', pragmaticsSize) }
                                                <div className="other-formats">
                                                    <span className={ 'bold' + (pragmaticsBold ? ' active' : '') }
                                                        data-element="pragmatic" onClick={ setBold }></span>
                                                    <span className={ 'italic' + (pragmaticsItalic ? ' active' : '') }
                                                        data-element="pragmatic" onClick={ setItalic }></span>
                                                    <span className={ 'underline' + (pragmaticsUnderline ? ' active' : '') }
                                                        data-element="pragmatic" onClick={ setUnderline }></span>
                                                </div>
                                            </div>
                                            <div className="option-item">
                                                <Form.Check type={ 'radio' } id={ 'pragmatics-all' } name={ 'pragmatics' } 
                                                    label={ 'All pragmatic expressions' + ' (' + nlpCategories.pragmaticsAll + ')' } value={ 'all' } defaultChecked={ true }
                                                    onChange={ handlePragmaticsFilterOptionChange} />
                                            </div>
                                            <div className="option-item">
                                                <Form.Check type={ 'radio' } id={ 'pragmatics-suggesting' } name={ 'pragmatics' }
                                                    label={ 'Suggesting' + ' (' + nlpCategories.pragmaticsSuggesting + ')' } value={ 'suggesting' }
                                                    onChange={ handlePragmaticsFilterOptionChange} />
                                            </div>
                                            <div className="option-item">
                                                <Form.Check type={ 'radio' } id={ 'pragmatics-thanking' } name={ 'pragmatics' }
                                                    label={ 'Thanking' + ' (' + nlpCategories.pragmaticsThanking + ')' } value={ 'thanking' }
                                                    onChange={ handlePragmaticsFilterOptionChange} />
                                            </div>
                                            <div className="option-item">
                                                <Form.Check type={ 'radio' } id={ 'pragmatics-requesting' } name={ 'pragmatics' }
                                                    label={ 'Requesting' + ' (' + nlpCategories.pragmaticsRequesting + ')' } value={ 'requesting' }
                                                    onChange={ handlePragmaticsFilterOptionChange} />
                                            </div>
                                            <div className="option-item">
                                                <Form.Check type={ 'radio' } id={ 'pragmatics-agreeing-disagreeing' } name={ 'pragmatics' }
                                                    label={ 'Agreeing/Disagreeing' + ' (' + nlpCategories.pragmaticsAgreeingDisagreeing + ')' } value={ 'agreeing-disagreeing' }
                                                    onChange={ handlePragmaticsFilterOptionChange} />
                                            </div>
                                            <div className="option-item">
                                                <Form.Check type={ 'radio' } id={ 'pragmatics-showing-interest' } name={ 'pragmatics' }
                                                    label={ 'Showing interest' + ' (' + nlpCategories.pragmaticsShowingInterest + ')' } value={ 'showing-interest' }
                                                    onChange={ handlePragmaticsFilterOptionChange} />
                                            </div>
                                            <div className="option-item">
                                                <Form.Check type={ 'radio' } id={ 'pragmatics-apologising' } name={ 'pragmatics' }
                                                    label={ 'Apologising' + ' (' + nlpCategories.pragmaticsApologising + ')' } value={ 'apologising' }
                                                    onChange={ handlePragmaticsFilterOptionChange} />
                                            </div>
                                            <div className="option-item">
                                                <Form.Check type={ 'radio' } id={ 'pragmatics-giving-an-opinion' } name={ 'pragmatics' }
                                                    label={ 'Giving an opinion' + ' (' + nlpCategories.pragmaticsGivingAnOpinion + ')' } value={ 'giving-an-opinion' }
                                                    onChange={ handlePragmaticsFilterOptionChange} />
                                            </div>
                                            <div className="option-item">
                                                <Form.Check type={ 'radio' } id={ 'pragmatics-greeting' } name={ 'pragmatics' }
                                                    label={ 'Greeting' + ' (' + nlpCategories.pragmaticsGreeting + ')' } value={ 'greeting' }
                                                    onChange={ handlePragmaticsFilterOptionChange} />
                                            </div>
                                        </div>
                                    }
                                </div>
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="1">
                            <Accordion.Header>{ 'Phrasal verbs' }</Accordion.Header>
                            <Accordion.Body>
                                <div className="word-category">
                                    <div className={ 'word-category-switch' + (phrasalVerbsFilterActive ? ' active' : '')}>
                                        <FormCheck name="phrasalVerbs" type="checkbox" label={ 'Phrasal verbs' + ' (' + nlpCategories.phrasalVerbs + ')'}
                                            onChange={ handleChangePhrasalVerbs } checked={ phrasalVerbsFilterActive } />
                                    </div>
                                    { phrasalVerbsFilterActive && 
                                        <div className="options">
                                            <div className="format-options">
                                                { colorOptions('phrasalVerbs', phrasalVerbsColor) }
                                                { sizeOptions('phrasalVerbs', phrasalVerbsSize) }
                                                <div className="other-formats">
                                                    <span className={ 'bold' + (pragmaticsBold ? ' active' : '') }
                                                        data-element="phrasalVerbs" onClick={ setBold }></span>
                                                    <span className={ 'italic' + (pragmaticsItalic ? ' active' : '') }
                                                        data-element="phrasalVerbs" onClick={ setItalic }></span>
                                                    <span className={ 'underline' + (pragmaticsUnderline ? ' active' : '') }
                                                        data-element="phrasalVerbs" onClick={ setUnderline }></span>
                                                </div>
                                            </div>
                                        </div>
                                    }
                                </div>
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="2">
                            <Accordion.Header>{ 'Word Categories' }</Accordion.Header>
                            <Accordion.Body>
                                <div className="word-category">
                                    <div className={ 'word-category-switch' + (nounsFilterActive ? ' active' : '')}>
                                        <Form.Check type={ 'checkbox' } id={ 'nouns' } label={ 'Nouns' + ' (' + nlpCategories.nounsAll + ')' }
                                            onChange={ handleNounsFilterChange } checked={ nounsFilterActive } />
                                    </div>
                                    { nounsFilterActive && 
                                        <div className="options">
                                            <div className="format-options">
                                                { colorOptions('noun', nounsColor) }
                                                { sizeOptions('noun', nounsSize) }
                                                <div className="other-formats">
                                                    <span className={ 'bold' + (nounsBold ? ' active' : '') }
                                                        data-element="noun" onClick={ setBold }></span>
                                                    <span className={ 'italic' + (nounsItalic ? ' active' : '') }
                                                        data-element="noun" onClick={ setItalic }></span>
                                                    <span className={ 'underline' + (nounsUnderline ? ' active' : '') }
                                                        data-element="noun" onClick={ setUnderline }></span>
                                                </div>
                                            </div>
                                            <div className="option-item">
                                                <Form.Check type={ 'radio' } id={ 'nouns-all' } name={ 'nouns' } 
                                                    label={ 'All nouns' + ' (' + nlpCategories.nounsAll + ')' } value={ 'all' } defaultChecked={ true }
                                                    onChange={ handleNounsFilterOptionChange} />
                                            </div>
                                            <div className="option-item">
                                                <Form.Check type={ 'radio' } id={ 'nouns-irregular' } name={ 'nouns' }
                                                    label={ 'Irregular plurals' + ' (' + nlpCategories.nounsIrregular + ')' } value={ 'irregular' }
                                                    onChange={ handleNounsFilterOptionChange} />
                                            </div>
                                        </div>
                                    }
                                </div>
                                <div className="word-category">
                                    <div className={ 'word-category-switch' + (adjectivesFilterActive ? ' active' : '')}>
                                        <Form.Check type={ 'checkbox' } id={ 'adjectives' } label={ 'Adjectives' + ' (' + nlpCategories.adjectivesAll + ')' }
                                            onChange={ handleAdjectivesFilterChange } checked={ adjectivesFilterActive }/>
                                    </div>
                                    { adjectivesFilterActive && 
                                        <div className="options">
                                            <div className="format-options">
                                                { colorOptions('adjective', adjectivesColor) }
                                                { sizeOptions('adjective', adjectivesSize) }
                                                <div className="other-formats">
                                                    <span className={ 'bold' + (adjectivesBold ? ' active' : '') }
                                                        data-element="adjective" onClick={ setBold }></span>
                                                    <span className={ 'italic' + (adjectivesItalic ? ' active' : '') }
                                                        data-element="adjective" onClick={ setItalic }></span>
                                                    <span className={ 'underline' + (adjectivesUnderline ? ' active' : '') }
                                                        data-element="adjective" onClick={ setUnderline }></span>
                                                </div>
                                            </div>
                                            <div className="option-item">
                                                <div className="option-switch">
                                                    <Form.Check type={ 'radio' } id={ 'adjectives-all' } name={ 'adjectives' }
                                                        label={ 'All adjectives' + ' (' + nlpCategories.adjectivesAll + ')' } value={ 'all' } defaultChecked={ true }
                                                        onChange={ handleAdjectivesFilterOptionChange } />
                                                </div>
                                            </div>
                                            <div className="option-item">
                                                <div className="option-switch">
                                                    <Form.Check type={ 'radio' } id={ 'adjectives-comparative' } name={ 'adjectives' }
                                                        label={ 'Comparative adjectives' + ' (' + nlpCategories.adjectivesComparative + ')' } value={ 'adjectives-comparative' }
                                                        onChange={ handleAdjectivesFilterOptionChange } />
                                                </div>
                                            </div>
                                            <div className="option-item">
                                                <div className="option-switch">
                                                    <Form.Check type={ 'radio' } id={ 'adjectives-superlative' } name={ 'adjectives' }
                                                        label={ 'Superlative adjectives' + ' (' + nlpCategories.adjectivesSuperlative + ')' } value={ 'adjectives-superlative' }
                                                        onChange={ handleAdjectivesFilterOptionChange } />
                                                </div>
                                            </div>
                                        </div>
                                    }
                                </div>
                                <div className="word-category">
                                    <div className={ 'word-category-switch' + (adverbsFilterActive ? ' active' : '')}>
                                        <Form.Check type={ 'checkbox' } id={ 'adverbs' } label={ 'Adverbs' + ' (' + nlpCategories.adverbsAll + ')' }
                                            onChange={ handleAdverbsFilterChange } checked={ adverbsFilterActive }/>
                                    </div>
                                    { adverbsFilterActive && 
                                        <div className="options">
                                            <div className="format-options">
                                                { colorOptions('adverb', adverbsColor) }
                                                { sizeOptions('adverb', adverbsSize) }
                                                <div className="other-formats">
                                                    <span className={ 'bold' + (adverbsBold ? ' active' : '') }
                                                        data-element="adverb" onClick={ setBold }></span>
                                                    <span className={ 'italic' + (adverbsItalic ? ' active' : '') }
                                                        data-element="adverb" onClick={ setItalic }></span>
                                                    <span className={ 'underline' + (adverbsUnderline ? ' active' : '') }
                                                        data-element="adverb" onClick={ setUnderline }></span>
                                                </div>
                                            </div>
                                            <div className="option-item">
                                                <Form.Check type={ 'radio' } id={ 'adverbs-all' } name={ 'adverbs' }
                                                    label={ 'All adverbs' + ' (' + nlpCategories.adverbsAll + ')' } value={ 'all' } defaultChecked={ true }
                                                    onChange={ handleAdverbsFilterOptionChange } />  
                                            </div>
                                            <div className="option-item">
                                                <Form.Check type={ 'radio' } id={ 'adverbs-comparative' } name={ 'adverbs' } 
                                                    label={ 'Comparative adverbs' + ' (' + nlpCategories.adverbsComparative + ')' } value={ 'adverbs-comparative' }
                                                    onChange={ handleAdverbsFilterOptionChange } />
                                            </div>
                                            <div className="option-item">
                                                <Form.Check type={ 'radio' } id={ 'adverbs-superlative' } name={ 'adverbs' }
                                                    label={ 'Superlative adverbs' + ' (' + nlpCategories.adverbsSuperlative + ')' } value={ 'adverbs-superlative' }
                                                    onChange={ handleAdverbsFilterOptionChange } />
                                            </div>
                                            <div className="option-item">
                                                <Form.Check type={ 'radio' } id={ 'adverbs-wh' } name={ 'adverbs' }
                                                    label={ 'Wh-adverb' + ' (' + nlpCategories.adverbsWh + ')' } value={ 'adverbs-wh' }
                                                    onChange={ handleAdverbsFilterOptionChange } />
                                            </div>
                                        </div>
                                    }
                                </div>
                                <div className="word-category">
                                    <div className="option-item">
                                        <div className={ 'word-category-switch' + (verbsFilterActive ? ' active' : '')}>
                                            <Form.Check type={ 'checkbox' } id={ 'verbs' } label={ 'Verbs' + ' (' + nlpCategories.verbsAll + ')' }
                                                onChange={ handleVerbsFilterChange } checked={ verbsFilterActive }/>
                                        </div>
                                        { verbsFilterActive && 
                                            <div className="options">
                                                <div className="format-options">
                                                    { colorOptions('verb', verbsColor) }
                                                    { sizeOptions('verb', verbsSize) }
                                                    <div className="other-formats">
                                                        <span className={ 'bold' + (verbsBold ? ' active' : '') }
                                                            data-element="verb" onClick={ setBold }></span>
                                                        <span className={ 'italic' + (verbsItalic ? ' active' : '') }
                                                            data-element="verb" onClick={ setItalic }></span>
                                                        <span className={ 'underline' + (verbsUnderline ? ' active' : '') }
                                                            data-element="verb" onClick={ setUnderline }></span>
                                                    </div>
                                                </div>
                                                <div className="option-item">
                                                    <Form.Check type={ 'radio' } id={ 'verbs-all' } name={ 'verbs' } 
                                                        label={ 'All verbs' + ' (' + nlpCategories.verbsAll + ')' } value={ 'all' } defaultChecked={ true }
                                                        onChange={ handleVerbsFilterOptionChange } />
                                                </div>
                                                <div className="option-item">
                                                    <Form.Check type={ 'radio' } id={ 'verbs-irregular' } name={ 'verbs' } 
                                                        label={ 'Irregular verbs' + ' (' + nlpCategories.verbsIrregular + ')' } value={ 'verbs-irregular' }
                                                        onChange={ handleVerbsFilterOptionChange } />
                                                </div>
                                                <div className="option-item">
                                                    <Form.Check type={ 'radio' } id={ 'verbs-tense' } name={ 'verbs' }
                                                        label={ 'Verb Tense' } value={ 'verbs-tense' }
                                                        onChange={ handleVerbsFilterOptionChange } />
                                                    { verbsFilterActive && verbsFilterOption == 'verbs-tense' &&
                                                        <div className="suboptions">
                                                            <div className="suboption-title">
                                                                { 'Active'}
                                                            </div>
                                                            <div className="suboption-item">
                                                                <Form.Check type={ 'checkbox' } id={ 'verbs-tense-Present_Simple' } name={ 'verbs-tens' }
                                                                    label={ 'Present Simple' + ' (' + nlpCategories.verbsTensePresentSimple + ')' } value={ 'verbs-tense-Present_Simple' }
                                                                    onChange={ handleVerbsTensOptionChange } />
                                                            </div>
                                                            <div className="suboption-item">
                                                                <Form.Check type={ 'checkbox' } id={ 'verbs-tense-Present_Continuous' } name={ 'verbs-tens' }
                                                                    label={ 'Present Continuous' + ' (' + nlpCategories.verbsTensePresentContinuous + ')' } value={ 'verbs-tense-Present_Continuous' }
                                                                    onChange={ handleVerbsTensOptionChange } />
                                                            </div>
                                                            <div className="suboption-item">
                                                                <Form.Check type={ 'checkbox' } id={ 'verbs-tense-Present_Perfect' } name={ 'verbs-tens' }
                                                                    label={ 'Present Perfect' + ' (' + nlpCategories.verbsTensePresentPerfect + ')' } value={ 'verbs-tense-Present_Perfect' }
                                                                    onChange={ handleVerbsTensOptionChange } />
                                                            </div>
                                                            <div className="suboption-item">
                                                                <Form.Check type={ 'checkbox' } id={ 'verbs-tense-Present_Perfect_Continuous' } name={ 'verbs-tens' }
                                                                    label={ 'Present Perfect Continuous' + ' (' + nlpCategories.verbsTensePresentPerfectContinuous + ')' } value={ 'verbs-tense-Present_Perfect_Continuous' }
                                                                    onChange={ handleVerbsTensOptionChange } />
                                                            </div>
                                                            <div className="suboption-item">
                                                                <Form.Check type={ 'checkbox' } id={ 'verbs-tense-Past_Simple' } name={ 'verbs-tens' }
                                                                    label={ 'Past Simple' + ' (' + nlpCategories.verbsTensePastSimple + ')' } value={ 'verbs-tense-Past_Simple' }
                                                                    onChange={ handleVerbsTensOptionChange } />
                                                            </div>
                                                            <div className="suboption-item">
                                                                <Form.Check type={ 'checkbox' } id={ 'verbs-tense-Past_Continuous' } name={ 'verbs-tens' }
                                                                    label={ 'Past Continuous' + ' (' + nlpCategories.verbsTensePastContinuous + ')' } value={ 'verbs-tense-Past_Continuous' }
                                                                    onChange={ handleVerbsTensOptionChange } />
                                                            </div>
                                                            <div className="suboption-item">
                                                                <Form.Check type={ 'checkbox' } id={ 'verbs-tense-Past_Perfect' } name={ 'verbs-tens' }
                                                                    label={ 'Past Perfect' + ' (' + nlpCategories.verbsTensePastPerfect + ')' } value={ 'verbs-tense-Past_Perfect' }
                                                                    onChange={ handleVerbsTensOptionChange } />
                                                            </div>
                                                            <div className="suboption-item">
                                                                <Form.Check type={ 'checkbox' } id={ 'verbs-tense-Past_Perfect_Continuous' } name={ 'verbs-tens' }
                                                                    label={ 'Past Perfect Continuous' + ' (' + nlpCategories.verbsTensePastPerfectContinuous + ')' } value={ 'verbs-tense-Past_Perfect_Continuous' }
                                                                    onChange={ handleVerbsTensOptionChange } />
                                                            </div>
                                                            <div className="suboption-item">
                                                                <Form.Check type={ 'checkbox' } id={ 'verbs-tense-Future_Be_Going_To' } name={ 'verbs-tens' }
                                                                    label={ 'Future ‘going to’' + ' (' + nlpCategories.verbsTenseFutureBeGoingTo + ')' } value={ 'verbs-tense-Future_Be_Going_To' }
                                                                    onChange={ handleVerbsTensOptionChange } />
                                                            </div>
                                                            <div className="suboption-item">
                                                                <Form.Check type={ 'checkbox' } id={ 'verbs-tense-Future_Simple' } name={ 'verbs-tens' }
                                                                    label={ 'Future Simple' + ' (' + nlpCategories.verbsTenseFutureSimple + ')' } value={ 'verbs-tense-Future_Simple' }
                                                                    onChange={ handleVerbsTensOptionChange } />
                                                            </div>
                                                            <div className="suboption-item">
                                                                <Form.Check type={ 'checkbox' } id={ 'verbs-tense-Future_Perfect' } name={ 'verbs-tens' }
                                                                    label={ 'Future Perfect' + ' (' + nlpCategories.verbsTenseFuturePerfect + ')' } value={ 'verbs-tense-Future_Perfect' }
                                                                    onChange={ handleVerbsTensOptionChange } />
                                                            </div>
                                                            <div className="suboption-item">
                                                                <Form.Check type={ 'checkbox' } id={ 'verbs-tense-Future_Perfect_Continuous' } name={ 'verbs-tens' }
                                                                    label={ 'Future Perfect Continuous' + ' (' + nlpCategories.verbsTenseFuturePerfectContinuous + ')' } value={ 'verbs-tense-Future_Perfect_Continuous' }
                                                                    onChange={ handleVerbsTensOptionChange } />
                                                            </div>
                                                            <div className="suboption-title">
                                                                { 'Passive'}
                                                            </div>
                                                            <div className="suboption-item">
                                                                <Form.Check type={ 'checkbox' } id={ 'verbs-tense-Present_Simple_Passive' } name={ 'verbs-tens' }
                                                                    label={ 'Present Simple Passive' + ' (' + nlpCategories.verbsTensePresentSimplePassive + ')' } value={ 'verbs-tense-Present_Simple_Passive' }
                                                                    onChange={ handleVerbsTensOptionChange } />
                                                            </div>
                                                            <div className="suboption-item">
                                                                <Form.Check type={ 'checkbox' } id={ 'verbs-tense-Present_Continuous_Passive' } name={ 'verbs-tens' }
                                                                    label={ 'Present Continuous Passive' + ' (' + nlpCategories.verbsTensePresentContinuousPassive + ')' } value={ 'verbs-tense-Present_Continuous_Passive' }
                                                                    onChange={ handleVerbsTensOptionChange } />
                                                            </div>
                                                            <div className="suboption-item">
                                                                <Form.Check type={ 'checkbox' } id={ 'verbs-tense-Present_Perfect_Passive' } name={ 'verbs-tens' }
                                                                    label={ 'Present Perfect Passive' + ' (' + nlpCategories.verbsTensePresentPerfectPassive + ')' } value={ 'verbs-tense-Present_Perfect_Passive' }
                                                                    onChange={ handleVerbsTensOptionChange } />
                                                            </div>
                                                            <div className="suboption-item">
                                                                <Form.Check type={ 'checkbox' } id={ 'verbs-tense-Present_Perfect_Continuous_Passive' } name={ 'verbs-tens' }
                                                                    label={ 'Present Perfect Continuous Passive' + ' (' + nlpCategories.verbsTensePresentPerfectContinuousPassive + ')' } value={ 'verbs-tense-Present_Perfect_Continuous_Passive' }
                                                                    onChange={ handleVerbsTensOptionChange } />
                                                            </div>
                                                            <div className="suboption-item">
                                                                <Form.Check type={ 'checkbox' } id={ 'verbs-tense-Past_Simple_Passive' } name={ 'verbs-tens' }
                                                                    label={ 'Past Simple Passive' + ' (' + nlpCategories.verbsTensePastSimplePassive + ')' } value={ 'verbs-tense-Past_Simple_Passive' }
                                                                    onChange={ handleVerbsTensOptionChange } />
                                                            </div>
                                                            <div className="suboption-item">
                                                                <Form.Check type={ 'checkbox' } id={ 'verbs-tense-Past_Continuous_Passive' } name={ 'verbs-tens' }
                                                                    label={ 'Past Continuous Passive' + ' (' + nlpCategories.verbsTensePastContinuousPassive + ')' } value={ 'verbs-tense-Past_Continuous_Passive' }
                                                                    onChange={ handleVerbsTensOptionChange } />
                                                            </div>
                                                            <div className="suboption-item">
                                                                <Form.Check type={ 'checkbox' } id={ 'verbs-tense-Past_Perfect_Passive' } name={ 'verbs-tens' }
                                                                    label={ 'Past Perfect Passive' + ' (' + nlpCategories.verbsTensePastPerfectPassive + ')' } value={ 'verbs-tense-Past_Perfect_Passive' }
                                                                    onChange={ handleVerbsTensOptionChange } />
                                                            </div>
                                                            <div className="suboption-item">
                                                                <Form.Check type={ 'checkbox' } id={ 'verbs-tense-Past_Perfect_Continuous_Passive' } name={ 'verbs-tens' }
                                                                    label={ 'Past Perfect Continuous Passive' + ' (' + nlpCategories.verbsTensePastPerfectContinuousPassive + ')' } value={ 'verbs-tense-Past_Perfect_Continuous_Passive' }
                                                                    onChange={ handleVerbsTensOptionChange } />
                                                            </div>
                                                            <div className="suboption-item">
                                                                <Form.Check type={ 'checkbox' } id={ 'verbs-tense-Future_Simple_Passive' } name={ 'verbs-tens' }
                                                                    label={ 'Future Simple Passive' + ' (' + nlpCategories.verbsTenseFutureSimplePassive + ')' } value={ 'verbs-tense-Future_Simple_Passive' }
                                                                    onChange={ handleVerbsTensOptionChange } />
                                                            </div>
                                                            <div className="suboption-item">
                                                                <Form.Check type={ 'checkbox' } id={ 'verbs-tense-Future_Perfect_Passive' } name={ 'verbs-tens' }
                                                                    label={ 'Future Perfect Passive' + ' (' + nlpCategories.verbsTenseFuturePerfectPassive + ')' } value={ 'verbs-tense-Future_Perfect_Passive' }
                                                                    onChange={ handleVerbsTensOptionChange } />
                                                            </div>
                                                        </div>
                                                    }
                                                </div>
                                                <div className="option-item">
                                                    <Form.Check type={ 'radio' } id={ 'verbs-modal' } name={ 'verbs' }
                                                        label={ 'Modal Verbs' + ' (' + nlpCategories.verbsTenseModal + ')' } value={ 'verbs-modal' }
                                                        onChange={ handleVerbsFilterOptionChange } />
                                                </div>
                                                <div className="option-item">
                                                    <Form.Check type={ 'radio' } id={ 'verbs-imperative' } name={ 'verbs' } 
                                                        label={ 'Imperative' + ' (' + nlpCategories.verbsTenseImperative + ')' } value={ 'verbs-imperative' }
                                                        onChange={ handleVerbsFilterOptionChange } />
                                                </div>
                                            </div>
                                        }
                                    </div>
                                </div>
                                <div className="word-category">
                                    <div className={ 'word-category-switch' + (prepositionsFilterActive ? ' active' : '')}>
                                        <Form.Check type={ 'checkbox' } id={ 'prepositions' } label={ 'Prepositions' + ' (' + nlpCategories.prepositionsAll + ')' }
                                            onChange={ handlePrepositionsFilterChange } checked={ prepositionsFilterActive }/>
                                    </div>
                                    { prepositionsFilterActive && 
                                        <div className="options">
                                            <div className="format-options">
                                                { colorOptions('preposition', prepositionsColor) }
                                                { sizeOptions('preposition', prepositionsSize) }
                                                <div className="other-formats">
                                                    <span className={ 'bold' + (prepositionsBold ? ' active' : '') }
                                                        data-element="preposition" onClick={ setBold }></span>
                                                    <span className={ 'italic' + (prepositionsItalic ? ' active' : '') }
                                                        data-element="preposition" onClick={ setItalic }></span>
                                                    <span className={ 'underline' + (prepositionsUnderline ? ' active' : '') }
                                                        data-element="preposition" onClick={ setUnderline }></span>
                                                </div>
                                            </div>
                                        </div>
                                    }
                                </div>
                                <div className="word-category">
                                    <div className={ 'word-category-switch' + (subConjunctionsFilterActive ? ' active' : '')}>
                                        <Form.Check type={ 'checkbox' } id={ 'subConjunctions' } label={ 'Subordinating conjunctions' + ' (' + nlpCategories.subConjunctionsAll + ')' }
                                            onChange={ handleSubConjunctionsFilterChange } checked={ subConjunctionsFilterActive }/>
                                    </div>
                                    { subConjunctionsFilterActive && 
                                        <div className="options">
                                            <div className="format-options">
                                                { colorOptions('subConjunction', subConjunctionsColor) }
                                                { sizeOptions('subConjunction', subConjunctionsSize) }
                                                <div className="other-formats">
                                                    <span className={ 'bold' + (subConjunctionsBold ? ' active' : '') }
                                                        data-element="subConjunction" onClick={ setBold }></span>
                                                    <span className={ 'italic' + (subConjunctionsItalic ? ' active' : '') }
                                                        data-element="subConjunction" onClick={ setItalic }></span>
                                                    <span className={ 'underline' + (subConjunctionsUnderline ? ' active' : '') }
                                                        data-element="subConjunction" onClick={ setUnderline }></span>
                                                </div>
                                            </div>
                                        </div>
                                    }
                                </div>
                                <div className="word-category">
                                    <div className="option-item">
                                        <div className={ 'word-category-switch' + (determinersFilterActive ? ' active' : '')}>
                                            <Form.Check type={ 'checkbox' } id={ 'determiners' } label={ 'Determiners' + ' (' + nlpCategories.determinersAll + ')' }
                                                onChange={ handleDeterminersFilterChange } checked={ determinersFilterActive } />
                                        </div>
                                        { determinersFilterActive && 
                                            <div className="options">
                                                <div className="format-options">
                                                    { colorOptions('determiner', determinersColor) }
                                                    { sizeOptions('determiner', determinersSize) }
                                                    <div className="other-formats">
                                                        <span className={ 'bold' + (nounsBold ? ' active' : '') }
                                                            data-element="determiner" onClick={ setBold }></span>
                                                        <span className={ 'italic' + (nounsItalic ? ' active' : '') }
                                                            data-element="determiner" onClick={ setItalic }></span>
                                                        <span className={ 'underline' + (nounsUnderline ? ' active' : '') }
                                                            data-element="determiner" onClick={ setUnderline }></span>
                                                    </div>
                                                </div>
                                                <div className="option-item">
                                                    <Form.Check type={ 'radio' } id={ 'determiners-all' } name={ 'determiners' } 
                                                        label={ 'All determiners' } value={ 'all' } defaultChecked={ true }
                                                        onChange={ handleDeterminersFilterOptionChange } />
                                                </div>
                                                <div className="option-item">
                                                    <Form.Check type={ 'radio' } id={ 'determiners-determiner' } name={ 'determiners' } 
                                                        label={ 'Determiner' + ' (' + nlpCategories.determinersAll + ')' } value={ 'determiners-determiner' }
                                                        onChange={ handleDeterminersFilterOptionChange } />
                                                </div>
                                                <div className="option-item">
                                                    <Form.Check type={ 'radio' } id={ 'determiners-wh' } name={ 'determiners' } 
                                                        label={ 'Wh-Determiner' + ' (' + nlpCategories.determinersWh + ')' } value={ 'determiners-wh' }
                                                        onChange={ handleDeterminersFilterOptionChange } />
                                                </div>
                                            </div>
                                        }
                                    </div>
                                </div>
                                <div className="word-category">
                                    <div className="option-item">
                                        <div className={ 'word-category-switch' + (pronounsFilterActive ? ' active' : '')}>
                                            <Form.Check type={ 'checkbox' } id={ 'pronouns' } label={ 'Pronouns' + ' (' + nlpCategories.pronounsAll + ')' }
                                                onChange={ handlePronounsFilterChange } checked={ pronounsFilterActive } />
                                        </div>
                                        { pronounsFilterActive && 
                                            <div className="options">
                                                <div className="format-options">
                                                    { colorOptions('pronoun', pronounsColor) }
                                                    { sizeOptions('pronoun', pronounsSize) }
                                                    <div className="other-formats">
                                                        <span className={ 'bold' + (nounsBold ? ' active' : '') }
                                                            data-element="pronoun" onClick={ setBold }></span>
                                                        <span className={ 'italic' + (nounsItalic ? ' active' : '') }
                                                            data-element="pronoun" onClick={ setItalic }></span>
                                                        <span className={ 'underline' + (nounsUnderline ? ' active' : '') }
                                                            data-element="pronoun" onClick={ setUnderline }></span>
                                                    </div>
                                                </div>
                                                <div className="option-item">
                                                    <Form.Check type={ 'radio' } id={ 'pronouns-all' } name={ 'pronouns' } 
                                                        label={ 'All pronouns' + ' (' + nlpCategories.pronounsAll + ')' } value={ 'all' } defaultChecked={ true }
                                                        onChange={ handlePronounsFilterOptionChange } />
                                                </div>
                                                <div className="option-item">
                                                    <Form.Check type={ 'radio' } id={ 'pronouns-personal' } name={ 'pronouns' } 
                                                        label={ 'Personal Pronouns' + ' (' + nlpCategories.pronounsPersonal + ')' } value={ 'pronouns-personal' }
                                                        onChange={ handlePronounsFilterOptionChange } />
                                                </div>
                                                <div className="option-item">
                                                    <Form.Check type={ 'radio' } id={ 'pronouns-possessive' } name={ 'pronouns' } 
                                                        label={ 'Possessive Pronouns' + ' (' + nlpCategories.pronounsPossessive + ')' } value={ 'pronouns-possessive' }
                                                        onChange={ handlePronounsFilterOptionChange } />
                                                </div>
                                                <div className="option-item">
                                                    <Form.Check type={ 'radio' } id={ 'pronouns-possessive-wh' } name={ 'pronouns' } 
                                                        label={ 'Possessive wh-pronoun' + ' (' + nlpCategories.pronounsPossessiveWh + ')' } value={ 'pronouns-possessive-wh' }
                                                        onChange={ handlePronounsFilterOptionChange } />
                                                </div>
                                                <div className="option-item">
                                                    <Form.Check type={ 'radio' } id={ 'pronouns-wh' } name={ 'pronouns' } 
                                                        label={ 'Wh-pronouns' + ' (' + nlpCategories.pronounsWh + ')' } value={ 'pronouns-wh' }
                                                        onChange={ handlePronounsFilterOptionChange } />
                                                </div>
                                            </div>
                                        }
                                    </div>
                                </div>
                                <div className="word-category">
                                    <div className={ 'word-category-switch' + (conjunctionsFilterActive ? ' active' : '')}>
                                        <Form.Check type={ 'checkbox' } id={ 'conjunctions' } label={ 'Coordinating Conjunctions' + ' (' + nlpCategories.conjunctionsAll + ')' }
                                            onChange={ handleConjunctionsFilterChange } checked={ conjunctionsFilterActive }/>
                                    </div>
                                    { conjunctionsFilterActive && 
                                        <div className="options">
                                            <div className="format-options">
                                                { colorOptions('conjunctions', conjunctionsColor) }
                                                { sizeOptions('conjunctions', conjunctionsSize) }
                                                <div className="other-formats">
                                                    <span className={ 'bold' + (conjunctionsBold ? ' active' : '') }
                                                        data-element="conjunctions" onClick={ setBold }></span>
                                                    <span className={ 'italic' + (conjunctionsItalic ? ' active' : '') }
                                                        data-element="conjunctions" onClick={ setItalic }></span>
                                                    <span className={ 'underline' + (conjunctionsUnderline ? ' active' : '') }
                                                        data-element="conjunctions" onClick={ setUnderline }></span>
                                                </div>
                                            </div>
                                        </div>
                                    }
                                </div>
                            </Accordion.Body>
                        </Accordion.Item>
                    </Accordion>
                </div>
            }
            <div className="text-presentation">
                <div className="text-formatted">
                    { parse(allText)}
                </div>
            </div>
        </div>
    );
}