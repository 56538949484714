import { Component } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import logo from '../../logo.svg';
import UserTeaser from '../user/UserTeaser';
import { Link } from "react-router-dom";
import { Nav, Navbar, NavDropdown } from "react-bootstrap";

export default class PrimaryHeader extends Component{
    role = localStorage.getItem('userRole');

    render() {
        return(
            <div className="primary-header">
                <Container>
                    <Row>
                        <Col md={2}>
                            <Link className="nav-link" to="/dashboard"><img className="logo" alt="Task-Gen" src= {logo}/></Link>
                        </Col>
                        <Col md={7}>
                            <Navbar expand="lg">
                                <Navbar.Toggle aria-controls="primary-header-menu" />
                                <Navbar.Collapse id="basic-navbar-nav">
                                    <Nav className="me-auto">
                                        <Nav.Link href="/#/dashboard">{ 'Dashboard' }</Nav.Link>
                                        <Nav.Link href="/#/users-groups">{ 'Users Groups' }</Nav.Link>
                                        <Nav.Link href="/#/help">{ 'Help' }</Nav.Link>
                                        { this.role && (this.role == 'admin' || this.role?.includes('admin')) &&
                                            <NavDropdown title="Admin" id="primary-header-menu-admin">
                                                <NavDropdown.Item href="/#/admin/orgs">{ 'Organizations' }</NavDropdown.Item>
                                                <NavDropdown.Item href="/#/admin/users">{ 'Users' }</NavDropdown.Item>
                                                <NavDropdown.Item href="/#/admin/taskexamples">{ 'Task examples' }</NavDropdown.Item>
                                                <NavDropdown.Item href="/#/admin/videos">{ 'Videos' }</NavDropdown.Item>
                                                <NavDropdown.Item href="/#/admin/help">{ 'Help' }</NavDropdown.Item>
                                                <NavDropdown.Item href="/#/admin/faqs">{ 'FAQs' }</NavDropdown.Item>
                                                <NavDropdown.Item href="/#/admin/feedbacks">{ 'Feedbacks' }</NavDropdown.Item>
                                            </NavDropdown>
                                        }
                                    </Nav>
                                </Navbar.Collapse>
                            </Navbar>
                        </Col>
                        <Col md={3}>
                            <UserTeaser />
                        </Col>
                    </Row>
                </Container>
                
            </div>
        )
    }
}
