import { useEffect, useState } from "react";
import { emptyUser, User } from "../interfaces/User";
import { Form } from "react-bootstrap";
import parse from 'html-react-parser';
import { useNavigate } from "react-router-dom";
import { obscureEmail } from "../../utils/utils";

interface UserSelectorProps {
    users: any[];
    usersCallback: CallableFunction;
}

export default function UserSelector(props: UserSelectorProps) {
    const navigate = useNavigate();

    const [user, setUser] = useState<User>(emptyUser);
    const [org, setOrg] = useState(false);
    const [usersFound, setUsersFound] = useState<any[]>([]);
    const [usersSelected, setUsersSelected] = useState<any[]>(props.users.map((u: any) => {
        return Object.assign({}, {
            id: u._id,
            email: u.email,
            firstname: u.profile ? u.profile.firstname : u.firstname,
            lastname: u.profile ? u.profile.lastname : u.lastname
        });
    }));
        
    useEffect(() => {
        const userId = localStorage.getItem('userId') ?? null;

        const requestOptionsPost = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            }
        };
        fetch('https://srv.taskgen.eu/v1/user/' + userId, requestOptionsPost)
            .then(response => {
                if (response.status !== 200) {
                    navigate('/');
                }
                return response.json();
            })
            .then(data => {
                setUser(data);
            });
    });

    const handleAddUsersFromOrgChange = (event: any) => {
        setOrg(event.target.checked);
        setUsersFound([]);
    }

    const handleSelectUser = (event: any) => {
        const id = event.target.id.replace('userFound-', '');
        const selectedUser = usersFound.find((u: any) => u.id == id);
        const newUsersSelected = [
            ...usersSelected,
            selectedUser
        ];
        setUsersSelected(newUsersSelected);
        props.usersCallback(newUsersSelected);
    }

    const handleUnselectUser = (event: any) => {
        const id = event.target.id.replace('userSelected-', '');
        const newUsersSelected = usersSelected.filter((u: any) => u.id != id);
        setUsersSelected(newUsersSelected);
        props.usersCallback(newUsersSelected);
    }

    const handleUsersSearch = (event: any) => {
        if (event.target.value.length < 3) {
            setUsersFound([]);
        } else {
            let reqUrl = 'https://srv.taskgen.eu/v1/users/';
            let queryParams: string[] = [];
            if (org) {
                queryParams.push('org=' + user.org._id);
            }
            queryParams.push('search=' + event.target.value);
            const requestOptionsPost = {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + localStorage.getItem('token')
                }
            };
            reqUrl += '?' + queryParams.join('&');
            fetch(reqUrl, requestOptionsPost)
                .then(response => {
                    if (response.status !== 200) {
                        navigate('/');
                    }
                    return response.json();
                })
                .then(data => {
                    const newUsersFound = data.map((u: any) => {
                        return {
                            id: u._id,
                            email: u.email,
                            firstname: u.profile.firstname,
                            lastname: u.profile.lastname
                        }
                    });
                    setUsersFound(newUsersFound);
                });
        }
    }

    return (
        <div className="users-selector-container">
            { user.org != null &&
                <Form.Check
                    type="switch"
                    id="share-with-org"
                    label={ 'Shared with someone at ' + user.org.name }
                    onChange={ handleAddUsersFromOrgChange }
                    defaultChecked = { false }
                />
            }
            <div className="users-selector">
                <div className="search">
                    <Form.Control type="text" placeholder="Search users..." onChange={ handleUsersSearch } />
                </div>
                { usersFound.length > 0 && 
                    <div className="users">
                        <div className="found">
                            <div className="label">
                                <strong>{ parse('Click on the plus icon of each user you want to select:') }</strong>
                            </div>
                            <ul>
                                { usersFound.filter((f: any) => usersSelected.findIndex((s: any) => s.id == f.id) === -1).map((u: any) => {
                                    let nameParts: string[] = [];
                                    if (u.firstname) {
                                        nameParts.push(u.firstname);
                                    }
                                    if (u.lastname) {
                                        nameParts.push(u.lastname);
                                    }
                                    const label = obscureEmail(u.email) + (nameParts.length > 0 ? ' (' + nameParts.join(' ') +  ')' : '');
                                    return <li key={ `userFound-${u.id}` }>
                                        <span>
                                            { label } <a className="select-user" onClick={ handleSelectUser } id={ `userFound-${u.id}`}></a>
                                        </span>
                                    </li>;
                                })}
                            </ul>
                        </div>
                    </div>
                }
                { usersSelected.length > 0 &&
                    <div className="selected">
                        <div className="label">
                            { parse('Users selected:') }
                        </div>
                        <ul>
                            { usersSelected.map((u: any) => {
                                let nameParts: string[] = [];
                                if (u.firstname) {
                                    nameParts.push(u.firstname);
                                }
                                if (u.lastname) {
                                    nameParts.push(u.lastname);
                                }
                                const label = obscureEmail(u.email) + (nameParts.length > 0 ? ' (' + nameParts.join(' ') +  ')' : '');
                                return <li key={ `userSelected-${u.id}` }>
                                    <span>
                                        { label } <a className="unselect-user" onClick={ handleUnselectUser } id={ `userSelected-${u.id}`}></a>
                                    </span>
                                </li>; 
                            })}
                        </ul>
                    </div>
            }
            </div>
        </div>
    );
}