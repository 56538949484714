import { useState } from "react";
import { Form } from "react-bootstrap";
import { SubTask, SubTaskPrompt } from "../../interfaces/Task";
import { Media } from "../../interfaces/Media";
import Uploader from "../../elements/Uploader";
import CustomEditor from "../../elements/CustomEditor";
import AITools from "../../elements/AITools";

export interface SubTaskPromptEditProps {
    task_id: string;
    subTask: SubTask;
    prompt: SubTaskPrompt;
    save: CallableFunction;
    position: number;
}

export interface TextFragment {
    text: string;
    type: string;
    verbTens: string;
    verbPhrasal: boolean;
    verbIrregular: boolean;
}

const slickRolesSettings = {
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1
};

export default function SubTaskPromptEdit(props: SubTaskPromptEditProps) {
    const userId = localStorage.getItem('userId');
    
    const [prompt, setPrompt] = useState<SubTaskPrompt>(props.prompt);
    const [instructions, setInstructions] = useState(props.prompt.instructions);
    const [teacher_notes, setTeacherNotes] = useState('');
    const [roles, setRoles] = useState<number[]>(props.prompt.roles);
    const [media, setMedia] = useState<Media[]>(props.prompt.media);
    const [text, setText] = useState(props.prompt.text);
    const [formattedText, setFormattedText] = useState(props.prompt.formatted_text);

    const handleMediaUpload = (media: Media[]) => {
        const newMedia = media.map((m: Media) => {
            return m;
        });
        setMedia(newMedia);
    }

    const save = (event: any) => {
        event.preventDefault();
        const newPrompt = Object.assign({}, prompt);
        newPrompt.name = event.target.name.value;
        newPrompt.instructions = instructions;
        newPrompt.text = text;
        newPrompt.formatted_text = formattedText;
        newPrompt.media = media.map((m: Media) => { return m; });
        newPrompt.roles = roles;
        newPrompt.teacher_notes = teacher_notes;
        setPrompt(newPrompt)
        props.save(newPrompt, props.position);
    }

    const handleIntroductionChange = (data: string) => {
        setInstructions(data);
    }

    const handleTeacherNotesChange = (data: string) => {
        setTeacherNotes(data);
    }

    const handleTextChange = (data: string) => {
        setText(data);
    }

    const handleFormattedTextChange = (text: string) => {
        setFormattedText(text);
    }

    const doNothing = () => {
        
    }

    return(
        <div className="edit-sub-task-prompt-container">
            <div className="inner">
                <h2>{ 'Edit Prompt' }</h2>
                <AITools />
                <Form className="sub-task-edit-form" onSubmit={ save }>
                    <Form.Group className="field field-title">
                        <Form.Label>{ 'Title of the Prompt' }</Form.Label>
                        <Form.Control type="text" name="name" required defaultValue={ props.prompt.name } />
                    </Form.Group>
                    <div className="field field-instructions">
                        <CustomEditor 
                            change={ handleIntroductionChange }
                            changeFormatted={ doNothing }
                            data={ props.prompt.instructions }
                            formatted= { '' }
                            id="instructions"
                            label={ 'Aim and instructions'}
                            npl={ false }
                            task_id={ props.task_id }
                        />
                    </div>
                    <div className="field field-teachernotes">
                        <CustomEditor
                            change={ handleTeacherNotesChange }
                            changeFormatted={ doNothing }
                            data={ props.prompt.teacher_notes }
                            formatted= { '' }
                            id="teachernotes"
                            label={ 'Teacher notes '}
                            npl={ false }
                            task_id={ props.task_id }
                        />
                    </div>
                    <Uploader media={ media } save={ handleMediaUpload } />
                    <div className="field field-text">
                        <CustomEditor 
                            change={ handleTextChange }
                            changeFormatted={ handleFormattedTextChange }
                            data={ props.prompt.text }
                            formatted={ props.prompt.formatted_text }
                            id="text"
                            label={ 'Text '}
                            npl={ true }
                            task_id={ props.task_id }
                        />
                    </div>
                    <div className="actions">
                        <button className="btn btn-primary" type="submit">
                            { 'Save' }
                        </button>
                    </div>
                </Form>
            </div>
        </div>
    );
}