import { useEffect, useState } from "react";
import PrimaryHeader from "../../header/PrimaryHeader";
import SecondaryHeader from "../../header/SecondaryHeader";
import { Button, Table } from "react-bootstrap";
import { Faq, emptyFaq } from "../../interfaces/Faq";
import FaqDelete from "./FaqDelete";
import FaqEdit from "./FaqEdit";
import FaqAdd from "./FaqAdd";
import { useNavigate } from "react-router-dom";

export default function Faqs() {
    const navigate = useNavigate();
    const isAdmin = localStorage.getItem('isAdmin');
    
    const [faqs, setFaqs] = useState<Faq[]>([]);
    const [selectedFaq, setSelectedFaq] = useState<Faq>(emptyFaq);
    const [showAddForm, setShowAddForm] = useState(false);
    const [showDeleteForm, setShowDeleteForm] = useState(false);
    const [showEditForm, setShowEditForm] = useState(false);

    useEffect(() => {
        const requestOptionsPost = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            }
        };
        fetch('https://srv.taskgen.eu/v1/faq', requestOptionsPost)
            .then(response => {
                if (response.status !== 200) {
                    navigate('/');
                }
                return response.json();
            })
            .then(data => {
                setFaqs(data);
            });
    }, []);

    const handleShowAddForm = () => {
        setShowAddForm(true);
    }

    const handleHideAddForm = () => {
        setShowAddForm(false);
    }

    const handleSumbitAddForm = (f: Faq) => {
        const newFaqs = faqs.map((faq: Faq) => { return faq; })
        newFaqs.push(f);
        setFaqs(newFaqs);
        setShowAddForm(false);
    }
    
    const handleShowDeleteForm = (event: any, id: string) => {
        event.preventDefault()
        const newSelectedFaq: Faq = faqs.find((f: Faq) => f._id == id) ?? emptyFaq;
        if (newSelectedFaq._id != '') {
            setSelectedFaq(newSelectedFaq);
            setShowDeleteForm(true);
        } else {
            alert('No FAQ selected.');
        }
    }
    
    const handleHideDeleteForm = () => {
        setShowDeleteForm(false);
    }

    const handleSumbitDeleteForm = (id: string) => {
        const newFaqs = faqs.filter((f: Faq) => f._id != id);
        setFaqs(newFaqs);
        setShowDeleteForm(false);
    }
    
    const handleShowEditForm = (event: any, id: string) => {
        event.preventDefault()
        const newSelectedFaq: Faq = faqs.find((f: Faq) => f._id == id) ?? emptyFaq;
        if (newSelectedFaq._id != '') {
            setSelectedFaq(newSelectedFaq);
            setShowEditForm(true);
        } else {
            alert('No FAQ selected.');
        }
    }
    
    const handleHideEditForm = () => {
        setShowEditForm(false);
    }

    const handleSumbitEditForm = (faq: Faq) => {
        const newFaqs = faqs.map((f: Faq) => {
            if (f._id == faq._id) {
                return faq;
            } else {
                return f;
            }
        });
        setFaqs(newFaqs);
        setShowEditForm(false);
    }

    return (
        <div className="page">
            <SecondaryHeader />
            <div className="page-content">
                <PrimaryHeader />
                <div className="page-content-inner">
                    <div className="container-fluid">
                        <div className="row">
                            { isAdmin &&
                                <div className="admin-faqs-container">
                                    <h1>FAQ management</h1>
                                    <div className="page-actions">
                                        <Button onClick={ handleShowAddForm }>{ 'New FAQ' }</Button>
                                    </div>
                                    <div className="admin-faqs-table-container">
                                        <Table striped bordered hover>
                                            <thead>
                                                <tr>
                                                    <th>Id</th>
                                                    <th>Title</th>
                                                    <th>Weight</th>
                                                    <th>Actions</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                { faqs.map((f: Faq) => {
                                                    return(
                                                        <tr key={ 'faq-' + f._id }>
                                                            <td>{ f._id }</td>
                                                            <td>{ f.title }</td>
                                                            <td>{ f.weight }</td>
                                                            <td className="actions">
                                                                <a className="edit" title={ 'Edit FAQ' }
                                                                    href="#" onClick={ (event) => handleShowEditForm(event, f._id) }></a>
                                                                <a className="delete" title={ 'Delete FAQ' }
                                                                    href="#" onClick={ (event) => handleShowDeleteForm(event, f._id) }></a>
                                                            </td>
                                                        </tr>
                                                    );
                                                })}
                                            </tbody>
                                            </Table>
                                    </div>
                                </div>
                            }
                            { !isAdmin && 
                                <div className="admin-faqs-container">
                                    { 'You are not allowed to access this secion.' }
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </div>
            { showAddForm &&
                <FaqAdd submitCallback={ handleSumbitAddForm } closeCallback={ handleHideAddForm } />
            }
            { showDeleteForm &&
                <FaqDelete faq={ selectedFaq } submitCallback={ handleSumbitDeleteForm } closeCallback={ handleHideDeleteForm } />
            }
            { showEditForm &&
                <FaqEdit faq={ selectedFaq } submitCallback={ handleSumbitEditForm } closeCallback={ handleHideEditForm } />
            }
        </div>);
}