import { useEffect, useState } from "react";
import PrimaryHeader from "../../header/PrimaryHeader";
import SecondaryHeader from "../../header/SecondaryHeader";
import { Button, Table } from "react-bootstrap";
import { Org, emptyOrg } from "../../interfaces/Org";
import OrgAdd from "./OrgAdd";
import OrgDelete from "./OrgDelete";
import OrgEdit from "./OrgEdit";
import { useNavigate } from "react-router-dom";

export default function Orgs() {
    const navigate = useNavigate();
    const isAdmin = localStorage.getItem('isAdmin');
    
    const [orgs, setOrgs] = useState<Org[]>([]);
    const [selectedOrg, setSelectedOrg] = useState<Org>(emptyOrg);
    const [showAddForm, setShowAddForm] = useState(false);
    const [showDeleteForm, setShowDeleteForm] = useState(false);
    const [showEditForm, setShowEditForm] = useState(false);

    useEffect(() => {
        const requestOptionsPost = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            }
        };
        fetch('https://srv.taskgen.eu/v1/org', requestOptionsPost)
            .then(response => {
                if (response.status !== 200) {
                    navigate('/');
                }
                return response.json();
            })
            .then(data => {
                setOrgs(data);
            });
    }, []);

    const handleShowAddForm = () => {
        setShowAddForm(true);
    }

    const handleHideAddForm = () => {
        setShowAddForm(false);
    }

    const handleSumbitAddForm = (f: Org) => {
        const newOrgs = orgs.map((org: Org) => { return org; })
        newOrgs.push(f);
        setOrgs(newOrgs);
        setShowAddForm(false);
    }
    
    const handleShowDeleteForm = (event: any, id: string) => {
        event.preventDefault()
        const newSelectedOrg: Org = orgs.find((o: Org) => o._id == id) ?? emptyOrg;
        if (newSelectedOrg._id != '') {
            setSelectedOrg(newSelectedOrg);
            setShowDeleteForm(true);
        } else {
            alert('No organization selected.');
        }
    }
    
    const handleHideDeleteForm = () => {
        setShowDeleteForm(false);
    }

    const handleSumbitDeleteForm = (id: string) => {
        const newOrgs = orgs.filter((o: Org) => o._id != id);
        setOrgs(newOrgs);
        setShowDeleteForm(false);
    }
    
    const handleShowEditForm = (event: any, id: string) => {
        event.preventDefault()
        const newSelectedOrg: Org = orgs.find((o: Org) => o._id == id) ?? emptyOrg;
        if (newSelectedOrg._id != '') {
            setSelectedOrg(newSelectedOrg);
            setShowEditForm(true);
        } else {
            alert('No organization selected.');
        }
    }
    
    const handleHideEditForm = () => {
        setShowEditForm(false);
    }

    const handleSumbitEditForm = (org: Org) => {
        const newOrgs = orgs.map((o: Org) => {
            if (o._id == org._id) {
                return org;
            } else {
                return o;
            }
        });
        setOrgs(newOrgs);
        setShowEditForm(false);
    }

    return (
        <div className="page">
            <SecondaryHeader />
            <div className="page-content">
                <PrimaryHeader />
                <div className="page-content-inner">
                    <div className="container-fluid">
                        <div className="row">
                            { isAdmin &&
                                <div className="admin-orgs-container">
                                    <h1>Organizations management</h1>
                                    <div className="page-actions">
                                        <Button onClick={ handleShowAddForm }>{ 'New Organization' }</Button>
                                    </div>
                                    <div className="admin-orgs-table-container">
                                        <Table striped bordered hover>
                                            <thead>
                                                <tr>
                                                    <th>Id</th>
                                                    <th>Title</th>
                                                    <th>Actions</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                { orgs.map((o: Org) => {
                                                    return(
                                                        <tr key={ 'org-' + o._id }>
                                                            <td>{ o._id }</td>
                                                            <td>{ o.name }</td>
                                                            <td className="actions">
                                                                <a className="edit" title={ 'Edit organizataion' }
                                                                    href="#" onClick={ (event) => handleShowEditForm(event, o._id) }></a>
                                                                <a className="delete" title={ 'Delete organization' }
                                                                    href="#" onClick={ (event) => handleShowDeleteForm(event, o._id) }></a>
                                                            </td>
                                                        </tr>
                                                    );
                                                })}
                                            </tbody>
                                            </Table>
                                    </div>
                                </div>
                            }
                            { !isAdmin && 
                                <div className="admin-orgs-container">
                                    { 'You are not allowed to access this secion.' }
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </div>
            { showAddForm &&
                <OrgAdd submitCallback={ handleSumbitAddForm } closeCallback={ handleHideAddForm } />
            }
            { showDeleteForm &&
                <OrgDelete org={ selectedOrg } submitCallback={ handleSumbitDeleteForm } closeCallback={ handleHideDeleteForm } />
            }
            { showEditForm &&
                <OrgEdit org={ selectedOrg } submitCallback={ handleSumbitEditForm } closeCallback={ handleHideEditForm } />
            }
        </div>);
}