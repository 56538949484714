import { useEffect, useState } from "react";
import PrimaryHeader from "../../header/PrimaryHeader";
import SecondaryHeader from "../../header/SecondaryHeader";
import { Button, Table } from "react-bootstrap";
import HelpAdd from "./HelpAdd";
import { Help, emptyHelp } from "../../interfaces/Help";
import HelpDelete from "./HelpDelete";
import HelpEdit from "./HelpEdit";
import { useNavigate } from "react-router-dom";

export default function Helps() {
    const navigate = useNavigate();
    const isAdmin = localStorage.getItem('isAdmin');
    
    const [helps, setHelps] = useState<Help[]>([]);
    const [selectedHelp, setSelectedHelp] = useState<Help>(emptyHelp);
    const [showAddForm, setShowAddForm] = useState(false);
    const [showDeleteForm, setShowDeleteForm] = useState(false);
    const [showEditForm, setShowEditForm] = useState(false);

    useEffect(() => {
        const requestOptionsPost = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            }
        };
        fetch('https://srv.taskgen.eu/v1/help', requestOptionsPost)
            .then(response => {
                if (response.status !== 200) {
                    navigate('/');
                }
                return response.json();
            })
            .then(data => {
                setHelps(data);
            });
    }, []);

    const handleShowAddForm = () => {
        setShowAddForm(true);
    }

    const handleHideAddForm = () => {
        setShowAddForm(false);
    }

    const handleSumbitAddForm = (h: Help) => {
        const newHelps = helps.map((help: Help) => { return help; })
        newHelps.push(h);
        setHelps(newHelps);
        setShowAddForm(false);
    }
    
    const handleShowDeleteForm = (event: any, id: string) => {
        event.preventDefault()
        const newSelectedHelp: Help = helps.find((h: Help) => h._id == id) ?? emptyHelp;
        if (newSelectedHelp._id != '') {
            setSelectedHelp(newSelectedHelp);
            setShowDeleteForm(true);
        } else {
            alert('No help selected.');
        }
    }
    
    const handleHideDeleteForm = () => {
        setShowDeleteForm(false);
    }

    const handleSumbitDeleteForm = (id: string) => {
        const newHelps = helps.filter((h: Help) => h._id != id);
        setHelps(newHelps);
        setShowDeleteForm(false);
    }
    
    const handleShowEditForm = (event: any, id: string) => {
        event.preventDefault()
        const newSelectedHelp: Help = helps.find((h: Help) => h._id == id) ?? emptyHelp;
        if (newSelectedHelp._id != '') {
            setSelectedHelp(newSelectedHelp);
            setShowEditForm(true);
        } else {
            alert('No help selected.');
        }
    }
    
    const handleHideEditForm = () => {
        setShowEditForm(false);
    }

    const handleSumbitEditForm = (help: Help) => {
        const newHelps = helps.map((h: Help) => {
            if (h._id == help._id) {
                return help;
            } else {
                return h;
            }
        });
        setHelps(newHelps);
        setShowEditForm(false);
    }

    return (
        <div className="page">
            <SecondaryHeader />
            <div className="page-content">
                <PrimaryHeader />
                <div className="page-content-inner">
                    <div className="container-fluid">
                        <div className="row">
                            { isAdmin &&
                                <div className="admin-helps-container">
                                    <h1>Help management</h1>
                                    <div className="page-actions">
                                        <Button onClick={ handleShowAddForm }>{ 'New help' }</Button>
                                    </div>
                                    <div className="admin-helps-table-container">
                                        <Table striped bordered hover>
                                            <thead>
                                                <tr>
                                                    <th>Id</th>
                                                    <th>Name</th>
                                                    <th>Weight</th>
                                                    <th>Help items</th>
                                                    <th>Actions</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                { helps.map((h: Help) => {
                                                    return(
                                                        <tr key={ 'help-' + h._id }>
                                                            <td>{ h._id }</td>
                                                            <td>{ h.name }</td>
                                                            <td>{ h.weight }</td>
                                                            <td>{ h.items.length }</td>
                                                            <td className="actions">
                                                                <a className="edit" title={ 'Edit help' }
                                                                    href="#" onClick={ (event) => handleShowEditForm(event, h._id) }></a>
                                                                <a className="delete" title={ 'Delete help' }
                                                                    href="#" onClick={ (event) => handleShowDeleteForm(event, h._id) }></a>
                                                            </td>
                                                        </tr>
                                                    );
                                                })}
                                            </tbody>
                                            </Table>
                                    </div>
                                </div>
                            }
                            { !isAdmin && 
                                <div className="admin-helps-container">
                                    { 'You are not allowed to access this secion.' }
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </div>
            { showAddForm &&
                <HelpAdd submitCallback={ handleSumbitAddForm } closeCallback={ handleHideAddForm } />
            }
            { showDeleteForm &&
                <HelpDelete help={ selectedHelp } submitCallback={ handleSumbitDeleteForm } closeCallback={ handleHideDeleteForm } />
            }
            { showEditForm &&
                <HelpEdit help={ selectedHelp } submitCallback={ handleSumbitEditForm } closeCallback={ handleHideEditForm } />
            }
        </div>);
}