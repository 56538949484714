export const emptyUsersGroup = {
    _id: '',
    name: '',
    description: '',
    users: []
}

export interface GroupMember {
    _id: string,
    name: string,
    firstname: string,
    lastname: string
}

export interface UsersGroup {
    _id: string,
    name: string,
    description: string,
    users: GroupMember[]
}
