import { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import { PosTask } from "../../interfaces/Task";
import { Media } from "../../interfaces/Media";
import Uploader from "../../elements/Uploader";
import CustomEditor from "../../elements/CustomEditor";
import { registerEventEnd, registerEventStart } from "../../../utils/utils";
import AITools from "../../elements/AITools";

const emptyPosTask: any = {
    _id: '',
    name: '',
    type: 'teacherfeedback',
    instructions: '',
    media: [],
    text: '',
    updated: new Date()
}

export interface NewPosTaskTeacherFeedbackProps {
    saveForm: CallableFunction;
    closeForm: CallableFunction;
}

export default function NewPosTaskTeacherFeedback(props: NewPosTaskTeacherFeedbackProps) {
    const [eventId, setEventId] = useState('');
    const [posTask, setPosTask] = useState<PosTask>(emptyPosTask);
    const [instructions, setInstructions] = useState('');
    const [text, setText] = useState('');
    const [media, setMedia] = useState<Media[]>([]);

    const handleMediaUpload = (media: Media[]) => {
        const newMedia = media.map((m: Media) => {
            return m;
        });
        setMedia(newMedia);
    }
    
    const handleInstructionsChange = (data: string) => {
        setInstructions(data);
    }

    const handleTextChange = (data: string) => {
        setText(data);
    }

    const handleClose = () => {
        registerEventEnd(eventId);
        props.closeForm();
    }

    const handleSave = (e: any) => {
        e.preventDefault();
        registerEventEnd(eventId);
        posTask.name = e.target.name.value;
        posTask.instructions = instructions;
        posTask.media = media.map((m: Media) => { return m; });
        posTask.text = text;
        props.saveForm(posTask);
    }

    useEffect(() => {
        registerEventStart('new postask feedback', '').then(data => { 
            setEventId(data);
        });

        return () => {
            registerEventEnd(eventId);
        };
    }, []);

    const doNothing = () => {
        
    }

    return(
        <div className="overlay">
            <div className="inner">
                <div className="overlay-content">
                    <a className="close" onClick={ handleClose }></a>
                    <div className="new-task-container">
                        <div className="inner">
                            <h2>{ 'New Post-Task: Teacher Feedback' }</h2>
                            <AITools />
                            <Form className="new-post-task-form" onSubmit={ handleSave }>
                                <div className="fields-container">
                                    <Form.Group className="field field-title">
                                        <Form.Label>{ 'Title of the Post-Task' }</Form.Label>
                                        <Form.Control type="text" name="name" required />
                                    </Form.Group>
                                    <div className="field field-instructions">
                                        <CustomEditor
                                            change={ handleInstructionsChange }
                                            changeFormatted={ doNothing }
                                            data={ '' }
                                            formatted= { '' }
                                            id="instructions"
                                            label={ 'Aim and instructions'}
                                            npl={ false }
                                            task_id={ '' }
                                        />
                                    </div>
                                    <Uploader media={ media } save={ handleMediaUpload } />
                                    <div className="field field-text">
                                        <CustomEditor
                                            change={ handleTextChange }
                                            changeFormatted={ doNothing }
                                            data={ '' }
                                            formatted= { '' }
                                            id="text"
                                            label={ 'Text '}
                                            npl={ false }
                                            task_id={ '' }
                                        />
                                    </div>
                                </div>
                                <div className="actions">
                                    <button className="btn btn-primary" type="submit">
                                        { 'Save' }
                                    </button>
                                </div>
                            </Form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}