import { useState, useEffect } from "react";
import PrimaryHeader from "../header/PrimaryHeader";
import FeedbackBlock from "../blocks/FeedbackBlock";
import { Help, HelpItem } from "../interfaces/Help";
import parse from 'html-react-parser';
import { HashLink as Link } from "react-router-hash-link";
import { useNavigate } from "react-router-dom";
import { Video } from "../interfaces/Video";
import { replaceVideoShortcodes } from "../../utils/utils";
import SecondaryHeader from "../header/SecondaryHeader";

export default function HelpIndex() {
    const navigate = useNavigate();
    const [helps, setHelps] = useState<Help[]>();
    const [videos, setVideos] = useState<Video[]>([]);

    useEffect(() => {
        const requestOptionsGet = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            }
        };
        
        fetch('https://srv.taskgen.eu/v1/help', requestOptionsGet)
            .then(response => {
                if (response.status !== 200) {
                    navigate('/');
                }
                return response.json();
            })
            .then(data => {
                setHelps(data);
                
            });
        
            const requestOptionsPost = {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + localStorage.getItem('token')
                }
            };
        fetch('https://srv.taskgen.eu/v1/video-tutorials', requestOptionsPost)
            .then(response => {
                if (response.status !== 200) {
                    navigate('/');
                }
                return response.json();
            })
            .then(data => {
                setVideos(data);
            });
    }, []);

    return (
        <div className="page">       
            <SecondaryHeader />
            <div className="page-content">
                <PrimaryHeader />
                { helps &&
                    <div className="page-content-inner">
                        <div className="container-fluid">
                            <div className="row">
                                <h2>Help index</h2>
                            </div>
                            <div className="help-page">
                                <div className="help-index">
                                    <ol className="help-list-index">
                                        { helps && 
                                        helps.map((i: Help, index) => {
                                            return(
                                                <li className="help" 
                                                    key={ 'help-' + i._id + '-' + index}>
                                                    <Link to={"/help#"+i._id}>{ i.name && <p>{ i.name }</p>}</Link>
                                                    <ol>
                                                        {i.items.map((item: HelpItem, index) => {
                                                            if(item.title != "" && item.title != i.name){
                                                                return (
                                                                    <li className="help-item"
                                                                    key={ 'help-item-' + i._id + '-' + index}>
                                                                    <Link to={"/help#"+i._id}>{ item.title && <p>{ item.title }</p>}</Link>
                                                                    </li>
                                                                    
                                                                )
                                                            }
                                                        })}
                                                    </ol>
                                                </li>
                                            )
                                        })}
                                    </ol>
                                </div>
                                <div className="help-text">
                                    <ol className="help-text-index">
                                    { helps && 
                                        helps.map((i: Help, index) => {
                                            return(
                                                <li className="help" 
                                                    key={ 'help-' + i._id + '-' + index}>
                                                    { i.name && <h4>{ i.name }</h4>}
                                                    <ol>
                                                        {i.items.map((item: HelpItem, index) => {
                                                            const newTeaser: string = replaceVideoShortcodes(item.teaser, videos);
                                                            const newBody: string = replaceVideoShortcodes(item.body, videos);
                                                            return (
                                                                <li className="help-item" id={''+i._id}
                                                                key={ 'help-item-' + i._id + '-' + index}>
                                                                {item.title && <h5 className="help-title">{ item.title }</h5>}
                                                                {item.teaser && <div className="help-teaser">{parse(newTeaser)}</div>}
                                                                {item.body && <div className="help-body">{parse(newBody)}</div>}
                                                                {item.examples && <div className="help-examples">{parse(item.examples)}</div>}
                                                                </li>
                                                            )
                                                        })}
                                                    </ol>
                                                </li>
                                            )
                                        })}
                
                                    </ol>
                                </div>
                            </div>
                        </div>
                    </div>
                }
            </div>
            <FeedbackBlock/>
        </div>);
}
