import { useEffect, useState } from "react";
import PrimaryHeader from "../../header/PrimaryHeader";
import SecondaryHeader from "../../header/SecondaryHeader";
import { User, emptyUser } from "../../interfaces/User";
import { Button, Table } from "react-bootstrap";
import UserAdd from "./UserAdd";
import UserDelete from "./UserDelete";
import UserEdit from "./UserEdit";
import { useNavigate } from "react-router-dom";

export default function Users() {
    const navigate = useNavigate();
    const isAdmin = localStorage.getItem('isAdmin');

    const [users, setUsers] = useState<User[]>([]);
    const [selectedUser, setSelectedUser] = useState<User>(emptyUser);
    const [showAddForm, setShowAddForm] = useState(false);
    const [showDeleteForm, setShowDeleteForm] = useState(false);
    const [showEditForm, setShowEditForm] = useState(false);

    useEffect(() => {
        const requestOptionsPost = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            }
        };
        fetch('https://srv.taskgen.eu/v1/users', requestOptionsPost)
            .then(response => {
                if (response.status !== 200) {
                    navigate('/');
                }
                return response.json();
            })
            .then(data => {
                setUsers(data);
            });
    }, []);

    const handleShowAddForm = () => {
        setShowAddForm(true);
    }

    const handleHideAddForm = () => {
        setShowAddForm(false);
    }

    const handleSumbitAddForm = (u: User) => {
        const newUsers = users.map((user: User) => { return user; })
        newUsers.push(u);
        setUsers(newUsers);
        setShowAddForm(false);
    }
    
    const handleShowDeleteForm = (event: any, id: string) => {
        event.preventDefault()
        const newSelectedUser: User = users.find((u: User) => u._id == id) ?? emptyUser;
        if (newSelectedUser._id != '') {
            setSelectedUser(newSelectedUser);
            setShowDeleteForm(true);
        } else {
            alert('No user selected.');
        }
    }
    
    const handleHideDeleteForm = () => {
        setShowDeleteForm(false);
    }

    const handleSumbitDeleteForm = (id: string) => {
        const newUsers = users.filter((u: User) => u._id != id);
        setUsers(newUsers);
        setShowDeleteForm(false);
    }
    
    const handleShowEditForm = (event: any, id: string) => {
        event.preventDefault()
        const newSelectedUser: User = users.find((u: User) => u._id == id) ?? emptyUser;
        if (newSelectedUser._id != '') {
            setSelectedUser(newSelectedUser);
            setShowEditForm(true);
        } else {
            alert('No user selected.');
        }
    }
    
    const handleHideEditForm = () => {
        setShowEditForm(false);
    }

    const handleSumbitEditForm = (user: User) => {
        const newUsers = users.map((u: User) => {
            if (u._id == user._id) {
                return user;
            } else {
                return u;
            }
        });
        setUsers(newUsers);
        setShowEditForm(false);
    }

    return (
        <div className="page">
            <SecondaryHeader />
            <div className="page-content">
                <PrimaryHeader />
                <div className="page-content-inner">
                    <div className="container-fluid">
                        <div className="row">
                            { isAdmin &&
                                <div className="admin-users-container">
                                    <h1>Users management</h1>
                                    <div className="page-actions">
                                        <Button onClick={ handleShowAddForm }>{ 'New user' }</Button>
                                    </div>
                                    <div className="admin-users-table-container">
                                        <Table striped bordered hover>
                                            <thead>
                                                <tr>
                                                    <th>Mail</th>
                                                    <th>Login</th>
                                                    <th>Organization</th>
                                                    <th>Role</th>
                                                    <th>Name</th>
                                                    <th>Lastname</th>
                                                    <th>Actions</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                { users.map((u: User) => {
                                                    return(
                                                        <tr key={ 'user-' + u._id }>
                                                            <td>{ u.email }</td>
                                                            <td>{ u.username }</td>
                                                            <td>{ (u.org === undefined || u.org == null) ? '' : u.org.name }</td>
                                                            <td>{ u.role.join(', ') }</td>
                                                            <td>{ u.profile.firstname }</td>
                                                            <td>{ u.profile.lastname }</td>
                                                            <td className="actions">
                                                                <a className="edit" title={ 'Edit user' }
                                                                    href="#" onClick={ (event) => handleShowEditForm(event, u._id) }></a>
                                                                <a className="delete" title={ 'Delete user' }
                                                                    href="#" onClick={ (event) => handleShowDeleteForm(event, u._id) }></a>
                                                            </td>
                                                        </tr>
                                                    );
                                                })}
                                            </tbody>
                                            </Table>
                                    </div>
                                </div>
                            }
                            { !isAdmin && 
                                <div className="admin-users-container">
                                    { 'You are not allowed to access this secion.' }
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </div>
            { showAddForm &&
                <UserAdd submitCallback={ handleSumbitAddForm } closeCallback={ handleHideAddForm } />
            }
            { showDeleteForm &&
                <UserDelete user={ selectedUser } submitCallback={ handleSumbitDeleteForm } closeCallback={ handleHideDeleteForm } />
            }
            { showEditForm &&
                <UserEdit user={ selectedUser } submitCallback={ handleSumbitEditForm } closeCallback={ handleHideEditForm } />
            }
        </div>);
}