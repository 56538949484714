import { useEffect, useState } from "react";
import PrimaryHeader from "../../header/PrimaryHeader";
import { Button } from "react-bootstrap";
import { Video, emptyVideo } from "../../interfaces/Video";
import { useNavigate } from "react-router-dom";
import VideoEdit from "./VideoEdit";
import VideoDelete from "./VideoDelete";
import VideoAdd from "./VideoAdd";
import SecondaryHeader from "../../header/SecondaryHeader";

export default function Videos() {
    const navigate = useNavigate();
    const isAdmin = localStorage.getItem('isAdmin');
    
    const [videos, setVideos] = useState<Video[]>([]);
    const [selectedVideo, setSelectedVideo] = useState<Video>(emptyVideo);
    const [showAddForm, setShowAddForm] = useState(false);
    const [showDeleteForm, setShowDeleteForm] = useState(false);
    const [showEditForm, setShowEditForm] = useState(false);

    useEffect(() => {
        const requestOptionsPost = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            }
        };
        fetch('https://srv.taskgen.eu/v1/video-tutorials', requestOptionsPost)
            .then(response => {
                if (response.status !== 200) {
                    navigate('/');
                }
                return response.json();
            })
            .then(data => {
                setVideos(data);
            });
    }, []);

    const handleShowAddForm = () => {
        setShowAddForm(true);
    }

    const handleHideAddForm = () => {
        setShowAddForm(false);
    }

    const handleSumbitAddForm = (f: Video) => {
        const newVideos = videos.map((video: Video) => { return video; })
        newVideos.push(f);
        setVideos(newVideos);
        setShowAddForm(false);
    }
    
    const handleShowDeleteForm = (event: any, id: string) => {
        event.preventDefault()
        const newSelectedVideo: Video = videos.find((f: Video) => f._id == id) ?? emptyVideo;
        if (newSelectedVideo._id != '') {
            setSelectedVideo(newSelectedVideo);
            setShowDeleteForm(true);
        } else {
            alert('No Video selected.');
        }
    }
    
    const handleHideDeleteForm = () => {
        setShowDeleteForm(false);
    }

    const handleSumbitDeleteForm = (id: string) => {
        const newVideos = videos.filter((f: Video) => f._id != id);
        setVideos(newVideos);
        setShowDeleteForm(false);
    }
    
    const handleShowEditForm = (event: any, id: string) => {
        event.preventDefault()
        const newSelectedVideo: Video = videos.find((f: Video) => f._id == id) ?? emptyVideo;
        if (newSelectedVideo._id != '') {
            setSelectedVideo(newSelectedVideo);
            setShowEditForm(true);
        } else {
            alert('No Video selected.');
        }
    }
    
    const handleHideEditForm = () => {
        setShowEditForm(false);
    }

    const handleSumbitEditForm = (video: Video) => {
        const newVideos = videos.map((f: Video) => {
            if (f._id == video._id) {
                return video;
            } else {
                return f;
            }
        });
        setVideos(newVideos);
        setShowEditForm(false);
    }

    return (
        <div className="page">
            <SecondaryHeader />
            <div className="page-content">
                <PrimaryHeader />
                <div className="page-content-inner">
                    <div className="container-fluid">
                        <div className="row">
                            { isAdmin &&
                                <div className="admin-videos-container">
                                    <h1>{ 'Video management' }</h1>
                                    <div className="page-actions">
                                        <Button onClick={ handleShowAddForm }>{ 'New Video' }</Button>
                                    </div>
                                    <div className="admin-videos-table-container">
                                        <div className="inner">
                                            { videos.map((f: Video) => {
                                                return(
                                                    <div className="video-item" key={ 'video-' + f._id }>
                                                        { f.media.length > 0 &&
                                                            <div className="video media-item">
                                                                <video controls>
                                                                    <source src={ 'https://srv.taskgen.eu/' + f.media[0].path } />
                                                                </video>
                                                            </div>
                                                        }
                                                        <div className="title">
                                                            { f.title }
                                                        </div>
                                                        <div className="meta">
                                                            <div className="code">
                                                                { '[video="' + f._id + '"]' }
                                                            </div>
                                                            <div className="actions">
                                                                <a className="edit" title={ 'Edit Video' }
                                                                    href="#" onClick={ (event) => handleShowEditForm(event, f._id) }></a>
                                                                <a className="delete" title={ 'Delete Video' }
                                                                    href="#" onClick={ (event) => handleShowDeleteForm(event, f._id) }></a>
                                                            </div>
                                                        </div>                                                    
                                                    </div>
                                                );
                                            })}
                                        </div>
                                    </div>
                                </div>
                            }
                            { !isAdmin && 
                                <div className="admin-videos-container">
                                    { 'You are not allowed to access this secion.' }
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </div>
            { showAddForm &&
                <VideoAdd submitCallback={ handleSumbitAddForm } closeCallback={ handleHideAddForm } />
            }
            { showDeleteForm &&
                <VideoDelete video={ selectedVideo } submitCallback={ handleSumbitDeleteForm } closeCallback={ handleHideDeleteForm } />
            }
            { showEditForm &&
                <VideoEdit video={ selectedVideo } submitCallback={ handleSumbitEditForm } closeCallback={ handleHideEditForm } />
            }
        </div>);
}