import { useEffect, useState } from "react";
import { Button, Table } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { emptyUsersGroup, UsersGroup } from "../interfaces/UsersGroup";
import PrimaryHeader from "../header/PrimaryHeader";
import UsersGroupAdd from "./UsersGroupAdd";
import UsersGroupEdit from "./UsersGroupEdit";
import UsersGroupDelete from "./UsersGroupDelete";
import SecondaryHeader from "../header/SecondaryHeader";

export default function UsersGroups() {
    const navigate = useNavigate();

    const isAdmin = localStorage.getItem('isAdmin');
    const userId = localStorage.getItem('userId');
    
    const [usersGroups, setUsersGroups] = useState<UsersGroup[]>([]);
    const [selectedUsersGroup, setSelectedUsersGroup] = useState<UsersGroup>(emptyUsersGroup);
    const [showAddForm, setShowAddForm] = useState(false);
    const [showDeleteForm, setShowDeleteForm] = useState(false);
    const [showEditForm, setShowEditForm] = useState(false);

    useEffect(() => {
        const requestOptionsPost = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            }
        };
        fetch('https://srv.taskgen.eu/v1/users-groups/' + userId, requestOptionsPost)
            .then(response => {
                if (response.status !== 200) {
                    navigate('/');
                }
                return response.json();
            })
            .then(data => {
                setUsersGroups(data);
            });
    }, []);

    const handleShowAddForm = () => {
        setShowAddForm(true);
    }

    const handleHideAddForm = () => {
        setShowAddForm(false);
    }

    const handleSumbitAddForm = (f: UsersGroup) => {
        const newUsersGroups = usersGroups.map((org: UsersGroup) => { return org; })
        newUsersGroups.push(f);
        setUsersGroups(newUsersGroups);
        setShowAddForm(false);
    }
    
    const handleShowDeleteForm = (event: any, id: string) => {
        event.preventDefault()
        const newSelectedUsersGroup: UsersGroup = usersGroups.find((o: UsersGroup) => o._id == id) ?? emptyUsersGroup;
        if (newSelectedUsersGroup._id != '') {
            setSelectedUsersGroup(newSelectedUsersGroup);
            setShowDeleteForm(true);
        } else {
            alert('No organization selected.');
        }
    }
    
    const handleHideDeleteForm = () => {
        setShowDeleteForm(false);
    }

    const handleSumbitDeleteForm = (id: string) => {
        const newUsersGroups = usersGroups.filter((o: UsersGroup) => o._id != id);
        setUsersGroups(newUsersGroups);
        setShowDeleteForm(false);
    }
    
    const handleShowEditForm = (event: any, id: string) => {
        event.preventDefault()
        const newSelectedUsersGroup: UsersGroup = usersGroups.find((o: UsersGroup) => o._id == id) ?? emptyUsersGroup;
        if (newSelectedUsersGroup._id != '') {
            setSelectedUsersGroup(newSelectedUsersGroup);
            setShowEditForm(true);
        } else {
            alert('No organization selected.');
        }
    }
    
    const handleHideEditForm = () => {
        setShowEditForm(false);
    }

    const handleSumbitEditForm = (usersGroup: UsersGroup) => {
        const newUsersGroups = usersGroups.map((ug: UsersGroup) => {
            if (ug._id == usersGroup._id) {
                return usersGroup;
            } else {
                return ug;
            }
        });
        setUsersGroups(newUsersGroups);
        setShowEditForm(false);
    }

    return (
        <div className="page">
            <SecondaryHeader />
            <div className="page-content">
                <PrimaryHeader />
                <div className="page-content-inner">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="usersgroup-container">
                                <h1>{ 'Users groups' }</h1>
                                <div className="page-actions">
                                    <Button onClick={ handleShowAddForm }>{ 'New group' }</Button>
                                </div>
                                <div className="usersgroup-table-container">
                                    <Table striped bordered hover>
                                        <thead>
                                            <tr>
                                                <th>{ 'Id' }</th>
                                                <th>{ 'Name' }</th>
                                                <th>{ 'Users' }</th>
                                                <th>{ 'Actions' }</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            { usersGroups.map((u: UsersGroup) => {
                                                return(
                                                    <tr key={ 'org-' + u._id }>
                                                        <td>{ u._id }</td>
                                                        <td>{ u.name }</td>
                                                        <td>
                                                            { u.users.length > 0 &&
                                                                <div>
                                                                    { u.users.length == 1 ? '1 user' : u.users.length + ' users' }
                                                                </div>
                                                            }
                                                            { u.users.length == 0 && 
                                                                <div>{ 'No users assigned' }</div>
                                                            }
                                                        </td>
                                                        <td className="actions">
                                                            <a className="edit" title={ 'Edit group' }
                                                                href="#" onClick={ (event) => handleShowEditForm(event, u._id) }></a>
                                                            <a className="delete" title={ 'Delete group' }
                                                                href="#" onClick={ (event) => handleShowDeleteForm(event, u._id) }></a>
                                                        </td>
                                                    </tr>
                                                );
                                            })}
                                        </tbody>
                                    </Table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            { showAddForm &&
                <UsersGroupAdd submitCallback={ handleSumbitAddForm } closeCallback={ handleHideAddForm } />
            }
            { showDeleteForm &&
                <UsersGroupDelete usersGroup={ selectedUsersGroup } submitCallback={ handleSumbitDeleteForm } closeCallback={ handleHideDeleteForm } />
            }
            { showEditForm &&
                <UsersGroupEdit usersGroup={ selectedUsersGroup } submitCallback={ handleSumbitEditForm } closeCallback={ handleHideEditForm } />
            }
        </div>);
}