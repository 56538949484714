import { useState } from "react";
import { Form } from "react-bootstrap";
import { PosTask } from "../../interfaces/Task";
import { Media } from "../../interfaces/Media";
import Uploader from "../../elements/Uploader";
import CustomEditor from "../../elements/CustomEditor";
import Help from "../../elements/HelpContent";
import AITools from "../../elements/AITools";

const emptyPosTask: any = {
    _id: '',
    name: '',
    type: 'audio',
    instructions: '',
    media: [],
    text: '',
    updated: new Date()
}

export interface NewPosTaskAudioProps {
    saveForm: CallableFunction;
    closeForm: CallableFunction;
}

export default function NewPosTaskAudio(props: NewPosTaskAudioProps) {
    const [postTask, setPosTask] = useState<PosTask>(emptyPosTask);
    const [instructions, setInstructions] = useState('');
    const [text, setText] = useState('');
    const [media, setMedia] = useState<Media[]>([]);
    const [analyzedText, setAnalyzedText] = useState();

    const handleMediaUpload = (media: Media[]) => {
        const newMedia = media.map((m: Media) => {
            return m;
        });
        setMedia(newMedia);
    }
    
    const handleInstructionsChange = (data: string) => {
        setInstructions(data);
    }

    const handleTextChange = (data: string) => {
        setText(data);
    }

    const close = () => {
        props.closeForm();
    }

    const save = (e: any) => {
        e.preventDefault();
        postTask.name = e.target.name.value;
        postTask.instructions = instructions;
        postTask.media = media.map((m: Media) => { return m; });
        postTask.text = text;
        props.saveForm(postTask);
    }

    const handleTextAnalyzed = (data: any) => {
        setAnalyzedText(data);
    }

    const doNothing = () => {
        
    }

    return(
        <div className="overlay">
            <div className="inner">
                <div className="overlay-content">
                    <a className="close" onClick={ close }></a>
                    <div className="new-task-container audio">
                        <div className="inner">
                            <h2>
                                { 'New Post-Task: Audio' }
                                <Help id={ '652079f51e5cf99991b4e5ff' } />
                            </h2>
                            <AITools />
                            <Form className="new-post-task-form" onSubmit={ save }>
                                <div className="fields-container">
                                    <Form.Group className="field field-title">
                                        <Form.Label>{ 'Title of the Post-Task' }</Form.Label>
                                        <Form.Control type="text" name="name" required />
                                    </Form.Group>
                                    <div className="field field-instructions">
                                        <CustomEditor
                                            change={ handleInstructionsChange }
                                            changeFormatted={ doNothing }
                                            data={ '' }
                                            formatted= { '' }
                                            id="instructions"
                                            label={ 'Aim and instructions'}
                                            npl={ false }
                                            task_id={ '' }
                                        />
                                    </div>
                                    <Uploader media={ media } save={ handleMediaUpload } />
                                    <div className="field field-text">
                                        <CustomEditor
                                            change={ handleTextChange }
                                            changeFormatted={ doNothing }
                                            formatted= { '' }
                                            data={ '' }
                                            id="text"
                                            label={ 'Text '}
                                            npl={ false }
                                            task_id={ '' }
                                        />
                                    </div>
                                </div>
                                <div className="actions">
                                    <button className="btn btn-primary" type="submit">
                                        { 'Save' }
                                    </button>
                                </div>
                            </Form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}