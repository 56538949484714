import Container from "react-bootstrap/Container";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import dashboard_icon from '../../assets/img/dashboard.svg';
import help_icon from '../../assets/img/help-white.svg';
import groups_icon from '../../assets/img/user-groups.svg';
import logout_icon from '../../assets/img/logout.svg';
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import FeedbackBlock from "../blocks/FeedbackBlock";

export default function Navigation() {
    const navigate =  useNavigate();

    const handleLogout = (event: any) => {
        event.preventDefault();
        localStorage.setItem('userId', '');
        localStorage.setItem('userRole', '');
        localStorage.setItem('isAdmin', '');
        navigate('/');
    }

    return (
        <Navbar collapseOnSelect expand="lg">
            <Container>
                <div className="navbar-container">
                    <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                    <Navbar.Collapse id="navigation">
                        <Nav className="me-auto">
                            <div className="primary-group">
                                <Link className="nav-link active" to="/dashboard"><img src={ dashboard_icon } alt="icon"/>
                                    Dashboard
                                </Link>
                                <Link className="nav-link user-groups" to="/users-groups"><img src={ groups_icon } alt="icon"/>
                                    User groups
                                </Link>
                                <Link className="nav-link" to="/help"><img src={ help_icon } alt="icon"/>
                                    Help
                                </Link>
                            </div>
                            <div className="logout-group">
                                <a href="#" onClick={ handleLogout } className="nav-link logout"><img src={ logout_icon } alt="icon"/>Log Out</a>
                            </div>
                        </Nav>
                    </Navbar.Collapse>
                </div>
            </Container>
            <FeedbackBlock />
        </Navbar>
    );
}