import { Button, Form, FormControl, FormGroup, FormLabel } from "react-bootstrap";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { emptyVideo, Video } from "../../interfaces/Video";
import Uploader from "../../elements/Uploader";
import { Media } from "../../interfaces/Media";

export interface VideoAddProps {
    submitCallback: CallableFunction;
    closeCallback: CallableFunction;
}

export default function VideoAdd(props: VideoAddProps) {
    const navigate = useNavigate();
    const [media, setMedia] = useState<Media[]>([]);
    
    const handleClose = () => {
        props.closeCallback();
    }

    const handleSubmit = (event: any) => {
        event.preventDefault();
        let error = false;
        let error_messages: string[] = [];
        
        if (error) {
            alert(error_messages.join('\n'));
        } else {
            const newVideo: Video = Object.assign({}, emptyVideo);
            newVideo.title = event.target.title.value;
            newVideo.description = event.target.description.value;
            newVideo.media = media.map((m: Media) => { return m; });
            const requestOptionsPost = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + localStorage.getItem('token')
                },
                body: JSON.stringify(newVideo)
            };
            fetch('https://srv.taskgen.eu/v1/video-tutorials', requestOptionsPost)
                .then(response => {
                    if (response.status !== 200) {
                        navigate('/');
                    }
                    return response.json();
                })
                .then(data => {
                    props.submitCallback(data);                    
                });
        }
    }

    const handleMediaUpload = (media: Media[]) => {
        const newMedia: Media[] = [];
        if (media.length > 0) {
            newMedia.push(media.slice(-1)[0])
            const hasVideos = newMedia.filter((m: Media) => m.mimetype == 'video/mp4').length > 0;
            if (!hasVideos) {
                alert('Only MP4 videos are allowed.');
                return;
            }
            setMedia(newMedia);
        }
    }

    return(
        <div className="overlay">
            <div className="inner">
                <div className="overlay-content">
                    <a className="close" onClick={ handleClose }></a>
                    <div className="admin-add-video-container">
                        <h4>{ 'Add new Video' }</h4>
                        <div className="video-form-container">
                            <Form onSubmit={ handleSubmit }>
                                <FormGroup className="title" controlId="title">
                                    <Form.Label aria-required={ true }>{ 'Title' }</Form.Label>
                                    <Form.Control type="text" required={ true } name="title"
                                        placeholder={ 'Enter a title' } />
                                </FormGroup>
                                <Form.Group className="field field-description">
                                    <FormLabel htmlFor="description">{ 'Description' }</FormLabel>
                                    <FormControl as="textarea" name="description" />
                                </Form.Group>
                                <Uploader media={ media } save={ handleMediaUpload } />
                                <div className="actions">
                                    <Button type="submit" variant="primary">{ 'Save' }</Button>
                                </div>
                            </Form>
                        </div>
                    </div>
                </div>  
            </div>
        </div>
    );
}